import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BillService } from '../../../../services/bill.service';
import { GeneralService } from '../../../../services/general.service';
import { HistoryService } from '../../../../services/history.service';
import { AuthService } from '../../../../services/auth.service';
import { NitValidator } from '../../../../validators/nit'

@Component({
  selector: 'app-create-bill',
  templateUrl: './create-bill.component.html',
  styleUrls: ['./create-bill.component.css']
})
export class CreateBillComponent implements OnInit {

  loading: boolean = false;
  myForm;
  resolutions;
  /**  <!-- 
    key, name, nit, total, amount, exchange, detalle, address, status, type
   --> */
  constructor(
    public formBuilder: FormBuilder,
    public generalService: GeneralService,
    public historyService: HistoryService,
    public authService: AuthService,
    public billService: BillService,
    public dialogRef: MatDialogRef<CreateBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.resolutions = this.billService.getResolutions();
    this.myForm = this.formBuilder.group({
      'name': new FormControl('', [
        Validators.required,
      ]),
      'nit': new FormControl('', [
        Validators.required,
        NitValidator.invalidNit,
      ]),
      'address': new FormControl('', [
        Validators.required,
      ]),
      'bill_type': new FormControl('', [
        Validators.required,
      ]),
      'detail': new FormControl('', [
        Validators.required,
      ]),
      'total': new FormControl('', [
        Validators.required,
      ]),
    });
  }

  close(created: boolean) {
    this.dialogRef.close(created);
  }

  ngOnInit() {

  }

  async createBill() {

    // req.body.userID, req.body.businessID, req.body.name, req.body.nit, req.body.total, req.body.detail, req.body.address, req.body.email, req.body.type

    let user = await this.authService.getCurrentUser();

    var params = {
      type: this.myForm.get('bill_type').value,
      name: this.myForm.get('name').value,
      nit: this.myForm.get('nit').value,
      address: this.myForm.get('address').value,
      amount: Math.round(this.myForm.get('total').value * 100) / 100,
      detail_line: this.myForm.get('detail').value,
      email: user.email,
    };

    try {
      this.loading = true;
      let response = await this.billService.createCustomInvoice(params).then(res => res);
      console.log(response);

      if (response.status == 200) {
        this.generalService.printPdf('factura_traeguate.pdf', response.invoice);
        this.close(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }



  }

  // add() {
  //   console.log(params);

  //   //console.log(key, bill);
  //   this.loading = true;


  //   this.billService.createNewBill(params).subscribe((response) => {
  //     console.log(response);

  //     if (response.status == 200) {
  //       this.generalService.printPdf('factura_traeguate.pdf', response['_body']);
  //     }
  //     this.loading = false;
  //   });
  // }


}
