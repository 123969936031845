import { AbstractControl } from '@angular/forms';

export class NitValidator {

  static invalidNit(form: AbstractControl) {

    var nit = (form.value || '').toUpperCase();

    if (nit == "CF" || nit == "C.F." || nit == "" || nit == " ") {
      return null;
    }
    var pos = nit.indexOf("-");
    if (pos == nit.length - 1) {
      return { invalidNit: true };
    }
    if (pos == -1 && nit.length > 3) {
      nit = nit.slice(0, -1) + "-" + nit.slice(-1);
      pos = nit.indexOf("-");
    }

    var correlativo = nit.substring(0, pos);
    var digitoVerificador = nit.substring(pos + 1, nit.length);
    var factor = correlativo.length + 1;
    var valor = 0;
    for (let i = 0; i < pos; i++) {
      valor += parseInt(correlativo[i]) * factor;
      factor -= 1;
    }
    var residuo = valor % 11;
    var resultado = 11 - residuo;

    if (resultado >= 11) {
      residuo = resultado % 11;
      resultado = residuo;
    }

    if (resultado == 10) {
      if (digitoVerificador.toUpperCase() == "K") {
        console.log("El NIT es valido");
        return null;
      }

    } else if (digitoVerificador == resultado) {
      return null;
    }
    return { invalidNit: true };
  }
}