import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { InvitationService } from '../../../services/invitation/invitation.service';
import { ActivatedRoute } from '@angular/router';
import { BusinessService } from 'app/services/business/business.service';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { AuthService } from 'app/services/auth.service';
import { Select } from '@ngxs/store';
import { BusinessAccountModel } from 'app/models/business-account.model';
import { Observable } from 'rxjs';
var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.css']
})
export class InviteUsersComponent implements OnInit {
  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  businessName;
  businessTypes;
  loading: boolean = false;
  partnerID;
  accounts;
  email: FormControl;
  dataSourceUsers = new MatTableDataSource<{}>();
  displayedColumnsUsers: string[] = ['email', 'displayName', 'phone', 'rol', 'options'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private invitationService: InvitationService,
    private businessService: BusinessService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEXP)
    ]);

    this.loadData();
  }

  async loadData() {

    this.dataSourceUsers.paginator = this.paginator;
    this.dataSourceUsers.sort = this.sort;

/*     const lastSelectedPartner = this.route.snapshot.paramMap.get('partnerID') || await this.authService.getSelectedPartner();

    this.loadPartnerData(lastSelectedPartner);
    this.authService.onPartnerSelectedChange.subscribe(async (businessID) => {
      this.loadPartnerData(this.route.snapshot.paramMap.get('partnerID') || businessID);

    }); */

    this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.loadPartnerData(document.lastPartnerSelected);
      }
    });
  }

  async loadPartnerData(partnerID) {
    this.loading = true;

    this.partnerID = partnerID;

    this.accounts = await this.businessService.getUsersByBusiness(this.partnerID).then();
    const data = (await this.businessService.getBusinessData(this.partnerID).then((data: any) => data));

    this.businessName = data.name;

    this.businessTypes = ([{ 'name': 'Super Administrador', 'key': 'superadmin' }]).concat(
      Object.keys(data.rol || []).map((id: any) => ({ ...data.rol[id], key: id }))
    );


    this.dataSourceUsers.data = this.accounts;

    this.loading = false;

  }

  async invite() {
    this.loading = true;

    this.email.setValue(this.email.value.toLowerCase());

    await this.invitationService.sendInvitation(this.email.value, this.partnerID, 'superadmin').then();
    await this.sendEmail(this.email.value);

    this.email.setValue(null);

    await this.loadData();

  }

  async changeRol(rolID, email) {
    console.log(rolID, email);
    this.loading = true;
    await this.invitationService.changeInvitationRol(email, this.partnerID, rolID);
    this.loading = false;
  }

  async sendEmail(email) {
    this.loading = true; 
    await this.invitationService.sendEmail(
      {
        to: email,
        subject: 'Bienvenido a Traeguate!',
        title: this.businessName,
        action_link: "https://traeguate.gt/partner",
        action_title: "INGRESAR",
        description: 'Hola!, la empresa ' + this.businessName + ' te ha dado acceso a la plataforma Traeguate Business!.'
      }
    ).then(() => {
    });
    this.loading = false;
  }

  async showDeleteModal(email) {
    await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Revocar Acceso",
        body: "¿Está seguro que desea revocar el acceso de este usuario?",
        ok: "Aceptar",
        action: (async () => {
          this.loading = true;

          await this.invitationService.removeInvitation(email, this.partnerID);

          await this.loadData();
        }),
        cancel: "Cancelar",
      }
    });
  }
  /* 
    openDialog(): void {
      let dialogRef = this.dialog.open(NewPartnerComponent, { width: '80%', data: { partner: this.business } });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    } */

}
