import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { PasswordValidator } from '../../../validators/password'
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  myForm;
  loading: boolean = false;
  errorMessage;

  constructor(
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { 
    this.myForm = this.formBuilder.group({
      'email': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl('', [
        Validators.required,
      ]),
      'confirmPassword': new FormControl('', [
        Validators.required,
      ])
    },
      {
        validator: PasswordValidator.MatchPassword
      });
  }

  ngOnInit() {

  }

  register() {
    this.loading = true;
    this.errorMessage = undefined;
    this.authService.createUser(
      this.myForm.get('email').value,
      this.myForm.get('password').value,
      (userData) => {

        /*         if (userData['businessList'] && userData['businessList'].length > 0) {
                  localStorage.setItem('bussiness_active', userData['businessList'][0]);
                }
                localStorage.setItem('logued_partner_data', JSON.stringify(userData)); */
        this.loading = false;
        this.router.navigateByUrl('/partner');

      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.errorMessage = error;
      });
  }

}
