import { Component, OnInit, Inject, ViewChild, ElementRef  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-detail-assist',
  templateUrl: './detail-assist.component.html',
  styleUrls: ['./detail-assist.component.css']
})

export class DetailAssistComponent implements OnInit {
  user: any;
  assistItem: any;
  assistType: any;
  trip: any;
  google;

  nodes: any;
  
  @ViewChild('map') mapElement: ElementRef;
  map: any;
  marker: any;
  directionsService = new this.google.maps.DirectionsService;
  directionsDisplay = new this.google.maps.DirectionsRenderer;

  constructor(public dialogRef: MatDialogRef<DetailAssistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    
    this.user = data.user;
    this.assistItem = data.assistItem;
    this.assistType = data.assistType;
    this.trip = data.trip;
  }

  ngOnInit() {
    this.initMap();
  }

  
  initMap() {

    let origin = {lat: 14.6407200, lng:  -90.5132700 };
    let destination = {lat: 12.6407200, lng:  -85.5132700 };
    
    this.map = new this.google.maps.Map(this.mapElement.nativeElement, {
      zoom: 10,
      center: {lat: 14.6407200, lng:  -90.5132700 }
    });
    this.nodes = [];
    this.nodes.push({
      location: {lat: 14.6407200, lng:  -90.5132700 },
    });
    this.directionsDisplay.setMap(this.map);
    
    this.directionsService.route({ 
      origin: origin,
      destination:  destination,
      waypoints: this.nodes,
      travelMode: 'DRIVING',
    },(response, status) => {
      console.log(response);
      if (status === 'OK') {
        this.directionsDisplay.setDirections(response);        
        //console.log(this.directionsDisplay.directions.request);
      } else {
        //console.log(status);
      }
    });
  }

}
