import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, map } from 'rxjs/operators';

@Injectable()
export class NodeService {


  ref: any;

  constructor(public db: AngularFireDatabase) { }

  getList(callback) {
    if (!this.ref) {
      console.log('ejecutando provider');
      this.db.object('/nodes').snapshotChanges().pipe(take(1)).pipe(
        map(c =>
          ({ key: c.payload.key, ...c.payload.val() }))
      ).subscribe(snapshot => {
        this.ref = snapshot;
        callback(snapshot);
      });
    } else {
      callback(this.ref);
    }
  }


  getArrayList(callback) {
    console.log('ejecutando provider');
    this.db.list('/nodes').snapshotChanges().pipe(take(1)).pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(snapshot => {
      callback(snapshot);
    });
  }
}
