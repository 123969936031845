import { Component, OnInit } from '@angular/core';
import { OffersService } from 'app/services/offers.service';
import { ActivatedRoute } from '@angular/router';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.css']
})
export class OfferDetailComponent implements OnInit {

  offer: any;
  offerID: string;
  settingStatus: boolean = false;

  constructor(
    private offersService: OffersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  async ngOnInit() {
    this.offerID = this.route.snapshot.paramMap.get('offerID');
    this.offer = await this.loadOffer(this.offerID);
  }

  loadOffer(key){
    return this.offersService.getOfferData(key);
  }

  async toggleActive(){
    this.settingStatus = true;
    await this.offersService.setOfferStatus(this.offerID, !this.offer.activated);
    this.offer = await this.loadOffer(this.offerID);
    this.settingStatus = false;
  }

  async showDeleteModal(offer){
    let dialogRef = await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Eliminar oferta",
        body: "¿Está seguro que desea eliminar esta oferta?",
        ok: "Aceptar",
        action: (async () => {

          await this.offersService.deleteOffer(offer);
          window.history.back();
          console.log('deleted offerID', offer.key);
        }),
        cancel: "Cancelar",
      }
    });
  }

}
