import { Component, OnInit } from '@angular/core';
import { LegalService } from 'app/services/legal.service';
import { MatDialog } from '@angular/material';
import { InfoDialogComponent } from 'app/general/info-dialog/info-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-publish-new-legal-version',
  templateUrl: './publish-new-legal-version.component.html',
  styleUrls: ['./publish-new-legal-version.component.css']
})
export class PublishNewLegalVersionComponent implements OnInit {


  loadingTerms: boolean = true;
  loadingPolicies: boolean = true;
  publishing: boolean = false;

  termsSections = [];
  policiesSections = [];

  constructor(
    private legalService: LegalService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loadTerms();
    this.loadPolicies();

  }

  async loadTerms(){
    this.loadingTerms = true;
    this.termsSections = await this.legalService.getFullTerms();
    this.loadingTerms = false;
  }

  async loadPolicies(){
    this.loadingPolicies = true;
    this.policiesSections = await this.legalService.getFullPolicies();
    this.loadingPolicies = false;
  }

  async publish(){
    if(!this.publishing){
      this.publishing = true;
      let success = await this.legalService.publishVersion(this.termsSections, this.policiesSections);

      if(success){
        this.showInfoDialog("Se ha publicado una nueva versión correctamente.")
      } else {
        this.showInfoDialog("Ha ocurrido un error al publicar esta nueva versión.")
      }

      this.publishing = false;
    }
  }

  async showInfoDialog(message){
    let dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: "Legal",
        body: message,
        ok: "OK",
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigateByUrl('/dashboard/legal');
    });

  
  }

}
