import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],

})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  loading: boolean = false;
  error: any;
  returnUrl: string;
  formLogin: any;
  loginParams: any;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.formLogin = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_REGEX),
        /*         forbiddenNameValidator(/bob/i) // <-- Here's how you pass in the custom validator.
         */
      ]),
      'password': new FormControl('', [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {
    this.authService.logout(() => {

    }, (err) => {

    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

  }

  login() {
    this.loading = true;

    this.authService.login(this.formLogin.get('email').value, this.formLogin.get('password').value).then(user => {
      console.log(user);
      localStorage.setItem('logued', JSON.stringify(user));
      this.authService.userData(() => {
        this.loading = false;
        this.router.navigateByUrl(this.returnUrl);
      }, () => {
        this.error = { code: 'invalid-auth' };
        this.loading = false;
      });
    }).catch(err => {
      console.log(err);
      this.error = err;
      this.loading = false;
    });
  }

}
