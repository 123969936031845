import { Component, OnInit } from '@angular/core';
import { BusinessService } from 'app/services/business/business.service';
import { UserTypeService } from 'app/services/user-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BusinessAccountModel } from 'app/models/business-account.model';

@Component({
  selector: 'app-detail-type',
  templateUrl: './detail-type.component.html',
  styleUrls: ['./detail-type.component.css']
})
export class DetailTypeComponent implements OnInit {

  partnerID;
  rolID;
  rolData;
  loaded: boolean = true;
  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private userTypeService: UserTypeService,
    private businessService: BusinessService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    
  }

  ngOnInit() {

    this.loadData();
  }

  async loadData() {


    this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.partnerID = document.lastPartnerSelected;
      }
    });

    this.rolID = this.route.snapshot.paramMap.get('rolID');
    if (this.rolID) {
      this.loaded = false;
      this.rolData = ((await this.businessService.getBusinessData(this.partnerID).then()).rol || {})[this.rolID];

      this.loaded = true;
    }

  }

  async showDeleteModal() {
    await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Remover Rol",
        body: "¿Está seguro que desea remover este rol?",
        ok: "Aceptar",
        action: (async () => {
          this.loaded = false;
          console.log(this.partnerID, this.rolID);
          await this.userTypeService.removeRol(this.partnerID, this.rolID);
          this.location.back();
        }),
        cancel: "Cancelar",
      }
    });
  }

}
