import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ProductService } from 'app/services/product.service';
import * as firebase from 'firebase';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {
  myForm: any;
  prices;
  loading: any;
  loggedData;
  levels;
  visible;

  constructor(
    public productService: ProductService,
    public dialogRef: MatDialogRef<CreateComponent>,
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.myForm = new FormGroup({
      'type': new FormControl('', [
        Validators.required
      ]),
      'description': new FormControl('', [
        Validators.required,
      ]),
      'title': new FormControl('', [
        Validators.required,
      ]),
      'level': new FormControl('', [
      ]),
      'price': new FormControl('', [
        Validators.min(1),
      ]),
      'percentage': new FormControl('', [
        Validators.min(1),
        Validators.max(99),
      ]),
      'promo_price': new FormControl('', [
        Validators.min(0),
      ]),
    });
    this.myForm.get('type').setValue("free");
    this.myForm.get('level').setValue("1XP");
    this.setValidators('free');
  }

  setValidators(type) {
    this.myForm.get('level').setValidators([]);
    this.myForm.get('price').setValidators([Validators.min(1),]);
    this.myForm.get('promo_price').setValidators([Validators.min(0),]);
    this.myForm.get('percentage').setValidators([Validators.min(1), Validators.max(99),]);

    var visible = [];
    if (type == 'free') {
      visible = ['type', 'level', 'title', 'description'];
    } else if (type == 'static_price') {
      visible = ['type', 'level', 'title', 'description', 'promo_price'];
    } else if (type == 'dinamic_price') {
      visible = ['type', 'level', 'title', 'description'];
    } else if (type == 'static_discount') {
      visible = ['type', 'title', 'description', 'price', 'promo_price'];
    } else if (type == 'dinamic_discount') {
      visible = ['type', 'title', 'description', 'percentage'];
    }
    this.visible = visible;

    if (this.visible.indexOf('level') > -1) {
      this.myForm.get('level').setValidators([
        Validators.required
      ]);
    }
    if (this.visible.indexOf('price') > -1) {
      this.myForm.get('price').setValidators([
        Validators.required, Validators.min(1),
      ]);
    }
    if (this.visible.indexOf('promo_price') > -1) {
      this.myForm.get('promo_price').setValidators([
        Validators.required, Validators.min(0),
      ]);
    }
    if (this.visible.indexOf('percentage') > -1) {
      this.myForm.get('percentage').setValidators([
        Validators.required, Validators.min(1), Validators.max(99),
      ]);
    }
  }

  setMaxValidator() {
    this.myForm.get('promo_price').setValidators([
      Validators.max(this.myForm.get('price').value - 1), Validators.required, Validators.min(0),
    ]);
    this.myForm.get('promo_price').setValue("");
  }

  calculatePromo(price, promo_price) {
    const part = price - promo_price;
    return {
      '2XP': {
        promo: price - (part / 3)
      },
      '3XP': {
        promo: price - (part / 3 * 2)
      },
      '4XP': {
        promo: price - (part)
      },
      '5XP': {
        promo: price - (part)
      },
    };
  }

  getDiscountPercentage(percentage) {

    return {
      '2XP': {
        percentage: (percentage / 3)
      },
      '3XP': {
        percentage: (percentage / 3 * 2)
      },
      '4XP': {
        percentage: percentage
      },
      '5XP': {
        percentage: percentage
      },
    };
  }

  add() {
    var item = {
      title: this.myForm.get('title').value,
      description: this.myForm.get('description').value,
      type: this.myForm.get('type').value
    };
    item['levels'] = {};

    if (this.myForm.get('type').value == 'free') {
      item['levels'][this.myForm.get('level').value] = { promo: 0 };
    } else if (this.myForm.get('type').value == 'static_price') {
      item['levels'][this.myForm.get('level').value] = { promo: this.myForm.get('promo_price').value };
    } else if (this.myForm.get('type').value == 'dinamic_price') {
      item['levels'][this.myForm.get('level').value] = { variable: true };
    } if (this.myForm.get('type').value == 'static_discount') {
      item['promo_price'] = this.myForm.get('promo_price').value;
      item['regular_price'] = this.myForm.get('price').value;
      item['levels'] = this.calculatePromo(this.myForm.get('price').value, this.myForm.get('promo_price').value);
    } if (this.myForm.get('type').value == 'dinamic_discount') {
      item['percentage'] = parseFloat(this.myForm.get('percentage').value)
      item['levels'] = this.getDiscountPercentage(this.myForm.get('percentage').value);
    }

    this.productService.add(item, this.loggedData.partner.id, () => {
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }

}

