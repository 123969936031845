import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { UserTypeService } from 'app/services/user-type.service';
import { BusinessService } from 'app/services/business/business.service';
import { Location } from '@angular/common';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BusinessAccountModel } from 'app/models/business-account.model';

@Component({
  selector: 'app-new-type',
  templateUrl: './new-type.component.html',
  styleUrls: ['./new-type.component.css']
})
export class NewTypeComponent implements OnInit {

  formRol: FormGroup;
  partnerID;
  rolID;
  loaded: boolean = true;
  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService,
    private userTypeService: UserTypeService,
    private businessService: BusinessService
  ) {
    this.formRol = this.formBuilder.group({
      'name': new FormControl('', [
        Validators.required,
      ]),
      'users': new FormControl(),
      'offers': new FormControl(),
      'events': new FormControl(),
      'exchange_offers': new FormControl()
    });
  }

  ngOnInit() {

    this.loadData();
  }

  async loadData() {
    this.rolID = this.route.snapshot.paramMap.get('rolID');


    this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.partnerID = document.lastPartnerSelected;
        if (this.rolID && document.lastPartnerSelected) {
          this.loadPartnerData();
        }
      }
    });

  }


  async loadPartnerData(){
    this.loaded = false;
    const data = (await this.businessService.getBusinessData(this.partnerID).then()).rol[this.rolID];
    this.formRol.setValue(
      {
        name: data.name,
        users: data.users,
        offers: data.offers,
        events: data.events,
        exchange_offers: data.exchange_offers,
      }
    );

    this.loaded = true;
  }

  async save() {
    this.loaded = false;

    var rolData = {
      name: this.formRol.get('name').value,
      users: this.formRol.get('users').value,
      offers: this.formRol.get('offers').value,
      events: this.formRol.get('events').value,
      exchange_offers: this.formRol.get('exchange_offers').value,
    };

    /*     if (!rolData.users || rolData.users == false) {
          delete rolData.users;
        } if (!rolData.offers || rolData.offers == false) {
          delete rolData.offers;
        } if (!rolData.exchange_offers || rolData.exchange_offers == false) {
          delete rolData.exchange_offers;
        }
     */
    console.log(rolData);
    await this.userTypeService.saveRol(this.partnerID, this.rolID, rolData);
    this.location.back();
  }

}
