import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';

const PHONE_REGEX = '^[\+][0-9]{3}[0-9]{3}[0-9]{4,7}$';
const PHONE_REGEX_WITHOUT_DIAL = '^[0-9]{3}[0-9]{4,7}$';
const DIAL_REGEX = '^[\+][0-9]{3}$';

export class PhoneValidator {

  static invalidPhone(form: AbstractControl) {
    var value = form.value.split(' ').join('');
    const valid = value.match(PHONE_REGEX);
    if (!valid) {
      //      form.setValue(form.value.trim());            
      return ({ invalidPhone: true })
    } else {
      return null
    }
  }

  static invalidPhoneWithoutDial(form: AbstractControl) {
    var value = form.value.split(' ').join('');
    const valid = value.match(PHONE_REGEX_WITHOUT_DIAL);
    if (!valid) {           
      return ({ invalidPhoneWithoutDial: true })
    } else {
      return null
    }
  }

  static invalidDialCode(form: AbstractControl) {
    var value = form.value.split(' ').join('');
    const valid = value.match(DIAL_REGEX);
    if (!valid) {           
      return ({ invalidDialCode: true })
    } else {
      return null
    }
  }

  static overEighteen(form: AbstractControl) {
    const dob = form.value;
    console.log(`dob data ${dob}`);
    const today = moment().startOf('day');
    console.log(`today data ${today}`);
    const delta = today.diff(dob, 'years', false);
    console.log(`delta data ${delta}`);
    if (delta < 16) {
      return {
        underEighteen: {
          'requiredAge': '18+',
          'currentAge': delta
        }
      };
    }

    return null;
  }
}