import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToArray'
})
export class ObjectToArrayPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    if(value){
      return Object.keys(value).map(
        (index) => {
            value[index].key =index;
            return value[index];
        }
      )
    }
  }
}
