import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Http, Headers } from '@angular/http';
import { environment } from 'environments/environment.prod';
import { take, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable()
export class PaymentsService {
  constructor(
    public af: AngularFireDatabase,
    public afs: AngularFirestore,
    public http: Http,
    private fns: AngularFireFunctions
  ) {}

  getPayments(success) {
    let alerts = this.af.list('history/');
    alerts
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as Object),
          }))
        )
      )
      .subscribe((queriedItems) => {
        success(queriedItems);
      });
  }

  historyAsObject(success) {
    let alerts = this.af.object('history/');
    alerts
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((c) => ({ key: c.payload.key, ...(c.payload.val() as Object) }))
      )
      .subscribe((queriedItems) => {
        success(queriedItems);
      });
  }

  updateHistory(key, data) {
    this.af.object('history/' + key).update(data);
  }

  async getBankList() {
    const bankList = await this.af
      .list('data/banks/', (ref) => ref.orderByChild('id'))
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as Object),
          }))
        )
      )
      .toPromise()
      .then((list) => list);

    return bankList;
  }

  async getBankByID(bankID) {
    const bankData = await this.af
      .object('data/banks/' + bankID)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise()
      .then((bank) => ({ ...(bank.payload.val() as Object), key: bank.key }));

    return bankData;
  }

  async requestBusinessPayment(payment, type) {
    const cloudFn = this.fns.httpsCallable('requestBusinessPayment');
    let response = await cloudFn({
      payment: payment,
      type: type,
    }).toPromise();

    return response;
  }

  async getPayment(paymentID) {
    let document = await this.afs
      .collection('payments')
      .doc(paymentID)
      .get()
      .toPromise()
      .then((doc) => ({ ...doc.data(), key: doc.id }));
    return document;
  }

  async cancelTicketsPaymentRequest(paymentID) {
    const cloudFn = this.fns.httpsCallable('cancelTicketsPaymentRequest');
    let response = await cloudFn({
      paymentID: paymentID,
    }).toPromise();

    return response;
  }

  async getExecutedPaymentsOfBank(bankID, deposit_status) {
    let query = this.afs.collection(
      'payments',
      (ref) =>
        ref
          .where('bankID', '==', bankID)
          .where('deposit_status', '==', deposit_status)
      // .limit(10)
    );

    const snapshot = await query
      .get()
      .toPromise()
      .then((data) => data);
    return snapshot.docs.map((doc) => ({ ...doc.data(), key: doc.id }));
  }

  async getPaymentBatches() {
    let query = this.afs.collection('paymentBatches', (ref) =>
      ref.orderBy('time', 'desc')
    );

    const snapshot = await query
      .get()
      .toPromise()
      .then((data) => data);
    let successfulAmountToTransfer = 0;
    let unsuccessfulAmountToTransfer = 0;

    return snapshot.docs.map((doc) => ({ ...doc.data(), key: doc.id }));
  }

  async getBatchData(batchID) {
    let data = await this.afs
      .collection('paymentBatches')
      .doc(batchID)
      .get()
      .toPromise()
      .then((doc) => ({ ...doc.data(), id: doc.id }));

    return data;
  }

  async getPaymentRequests() {
    const query = this.afs.collection('transferRequest', (ref) =>
      ref.where('status', '==', 'PENDING').orderBy('createdAt', 'desc')
    );

    const snapshot = await query
      .get()
      .toPromise()
      .then((data) => data);
    return snapshot.docs.map((doc) => ({ ...doc.data(), key: doc.id }));
  }

  async updatePaymentDepositStatus(paymentID, status) {
    await this.afs.collection('transferRequest').doc(paymentID).update({
      deposit_status: status,
    });
  }

  async closePaymentBatch(batchID) {
    const FieldValue = firebase.firestore.FieldValue;

    await this.afs.collection('paymentBatches').doc(batchID).update({
      executed: true,
      time: FieldValue.serverTimestamp(),
    });
    return true;
  }

  async operatePaymentBatch(batchID, paymentRequests) {
    const cloudFn = this.fns.httpsCallable('operatePaymentBatch');
    const response = await cloudFn({
      batchID: batchID,
      payments: paymentRequests,
    }).toPromise();

    return response;
  }

  async getPaymentRequestsOfBatch(batchID) {
    const query = this.afs.collection('transferRequest', (ref) =>
      ref.where('batchID', '==', batchID)
    );

    const snapshot = await query
      .get()
      .toPromise()
      .then((data) => data);
    if (snapshot.docs.length === 0) {
      const queryPayments = this.afs.collection('payments', (ref) =>
        ref.where('batchID', '==', batchID)
      );
      const snapshotPayments = await queryPayments
        .get()
        .toPromise()
        .then((data) => data);

      return snapshotPayments.docs.map((doc) => ({
        ...doc.data(),
        key: doc.id,
        accountName: doc.data().owner_name,
        accountType: doc.data().value,
        accountNumber: doc.data().account_number,
        createdAt: doc.data().time,
        amount: doc.data().total_Q,
        amountISR: 0,
        amountToTransfer: doc.data().total_Q,
      }));

    } else {
      return snapshot.docs.map((doc) => ({ ...doc.data(), key: doc.id }));
    }
  }

  async generateBatch(selection) {
    const db = firebase.firestore();
    const FieldValue = firebase.firestore.FieldValue;
    const batch = db.batch();

    const paymentBatchRef = db.collection('paymentBatches').doc();
    let totalAmount: number = 0;
    let totalISR: number = 0;
    let total: number = 0;

    for (let i = 0; i < selection.length; i++) {
      const transferRequestID = selection[i].key;
      const paymentRef = db
        .collection('transferRequest')
        .doc(transferRequestID);
      batch.update(paymentRef, {
        batchID: paymentBatchRef.id,
        status: 'PROCESSING',
      });

      totalAmount += Number(selection[i].amount);
      totalISR += Number(selection[i].amountISR);
      total += Number(selection[i].amountToTransfer);
    }

    batch.set(paymentBatchRef, {
      time: FieldValue.serverTimestamp(),
      createdAt: FieldValue.serverTimestamp(),
      total: total,
      totalISR,
      totalAmount,
      executed: false,
      items: selection.length,
      successfulAmountToTransfer: 0,
      unsuccessfulAmountToTransfer: 0,
    });

    try {
      await batch.commit();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  getBusinessBalanceDocRef(businessID: string) {
    return this.afs.collection('businessPrivateInfo').doc(businessID);
  }

  getBanks(success, err) {
    this.af
      .list('data/banks/')
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            key: c.payload.key,
            ...(c.payload.val() as Object),
          }))
        )
      )
      .subscribe(
        (data) => {
          success(data);
        },
        (error) => {
          err(error);
        }
      );
  }

  bankAsObject(success) {
    this.af
      .object('data/banks/')
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((c) => ({ key: c.payload.key, ...(c.payload.val() as Object) }))
      )
      .subscribe((data) => {
        success(data);
      });
  }

  sendTransactions(users, okArray, errorArray, history, response) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let item = {
      users: users,
      correct: okArray,
      failed: errorArray,
      history: history,
    };

    this.http
      .post(environment.cloudBaseUrl + '/updateDeposits', item, {
        headers: headers,
      })
      .subscribe((data) => {
        response(data);
      });
  }

  createPartnerPayment(accounts, response) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let item = {
      accounts: accounts,
    };

    this.http
      .post(environment.cloudBaseUrl + '/partnersPayment', item, {
        headers: headers,
      })
      .subscribe((data) => {
        response(data);
      });
  }

  updateBusinessDeposits(okArray, errorArray, response) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let item = {
      correct: okArray,
      failed: errorArray,
    };

    this.http
      .post(environment.cloudBaseUrl + '/updateBusinessTransactions', item, {
        headers: headers,
      })
      .subscribe((data) => {
        response(data);
      });
  }
}
