import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { Http, Headers } from '@angular/http';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';
import { environment } from 'environments/environment';
import { take, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class NotificationsService {

  constructor(
    public authService: AuthService,
    public http: Http,
    public af: AngularFireDatabase,
    public afs: AngularFirestore,
  ) { }

  async createAlert(receiver: string, title: string, message: string, args: string, link: string) {

    console.log({
      sender: {
        id: "traeguate"
      },
      receiver: receiver,
      title: title,
      message: message,
      type: 0,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      seen: null,
      screenPath: link,
      args: args,
    }
    );
    this.afs.collection('alerts').add({
      sender: {
        id: "traeguate"
      },
      receiver: receiver,
      title: title,
      message: message,
      type: 0,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      seen: null,
      screenPath: link,
      args: args,
    });
  }

  updateNotificationCounter(userID) {
    this.afs.collection('/users/notifications/items').doc(userID).update({
      alerts_counter: firebase.firestore.FieldValue.increment(1),
    })
  }




}
