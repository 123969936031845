import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { GeneralService } from '../general.service';
import { take } from 'rxjs/operators';
import { BusinessModel } from 'app/models/business.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    public afs: AngularFirestore,
    public generalService: GeneralService

  ) { }


  async getUsersByBusiness(businessID) {

    let query = this.afs.collection('businessAccounts', ref => ref
      .where('businessList', 'array-contains', businessID)
    );

    const snapshot = await query.get().pipe(take(1)).toPromise().then(data => data);
    return snapshot.docs.map(doc => ({ ...doc.data(), key: doc.id }));
  }

  async getBusinessByUser(email) {
    const hashEmail = this.generalService.hashCode(email).toString();

    let query = this.afs.collection('business', ref => ref
      .where('employees', 'array-contains', hashEmail)
    );

    const snapshot = await query.get().pipe(take(1)).toPromise().then(data => data);
    console.log(snapshot.docs.map(doc => ({ ...doc.data(), key: doc.id })));
    return snapshot.docs.map(doc => ({ ...doc.data(), key: doc.id }));
  }

  async getBusiness() {
    let query = this.afs.collection('business');
    const snapshot = await query.get().pipe(take(1)).toPromise().then(data => data);
    return snapshot.docs.map(doc => ({ ...doc.data(), key: doc.id }));
  }

  updateBusiness(businessID, businessData) {
    return this.afs.collection('business').doc(businessID).update(businessData).then();
  }

  async createBusinessSearchIndex(businessID, name) {
    const nameWords = name.toLowerCase().split(" ");
    let searchIndex = [""];

    for (let index = 0; index < nameWords.length; index++) {
      const word = nameWords[index];
      let acumWord = "";

      for (let index2 = 0; index2 < word.length; index2++) {
        acumWord += word[index2];
        searchIndex.push(acumWord);
      }
    }

    await this.afs.collection('business').doc(businessID).update({
      searchIndex: searchIndex,
    });

  }

  saveBusiness(businessData) {
    return this.afs.collection('business').add(businessData).then();
  }

  deleteBusiness(businessID) {
    return this.afs.collection('business').doc(businessID).delete().then()
  }

  async getBusinessData(businessID): Promise<BusinessModel> {
    let document: any = await this.afs.collection('business').doc(businessID).get().toPromise().then((doc: any) => ({ ...doc.data(), key: doc.id }));
    return document;
  }

  async getInstitutionRequest(userID: string, status: string): Promise<any> {
    let documents: any = await this.afs.collection('institutionRequests', ref => ref.where('status', '==', status).where('user', '==', userID)).get().toPromise().then(snap => snap.docs.map(doc => ({ ...doc.data(), key: doc.id })));
    return documents;
  }

}
