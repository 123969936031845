import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, types?: any): any {

    let output = [];

    if (value && types) {
      value.forEach(element => {
        if (types.indexOf(element.status) != -1) {
          output.push(element);
        }
      });
    }
    return output;
  }

}
