import { Injectable, EventEmitter, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { take, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { GeneralService } from './general.service';
import * as firebase from 'firebase/app';
import { FirebaseAuth } from '@angular/fire';

@Injectable()
export class AuthService {
  user: any;
  @Output() onPartnerSelectedChange: EventEmitter<boolean> = new EventEmitter();
  selected_partnerID;

  constructor(
    public fireAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public db: AngularFireDatabase,
    private router: Router,
    private generalService: GeneralService,
    public http: HttpClient
  ) {
    let auth = this.fireAuth.authState;
    auth.subscribe(user => {
      console.log(user);
      this.user = user;
      if (!this.user) {
        localStorage.removeItem('logued');
        localStorage.removeItem('selected_partnerID');
      }
    });
  }

  userAuthenticated() {
    return this.user;
  }

  localUser() {
    return JSON.parse(localStorage.getItem("logued")).user;
  }

  async getAuth(): Promise<any> {
    return new Promise((resolve) => {
      console.log(this.fireAuth.auth.currentUser);
      if (this.fireAuth.auth.currentUser && this.fireAuth.auth.currentUser.email) {
        resolve(this.fireAuth.auth.currentUser);
      } else {
        this.fireAuth.authState.subscribe((data: firebase.User) => {
          resolve(data);
        });
      }
    });
  }

  async userData(success, err) {
    this.db.object('/collaborator/' + (await this.getAuth()).uid).snapshotChanges().pipe(take(1)).pipe(
      map(c =>
        ({ key: c.payload.key, ...c.payload.val() }))
    ).subscribe((userData: any) => {
      if (userData.name) {
        success(userData);
      } else {
        this.logout(() => {
          this.router.navigateByUrl("/admin");
          err();
        }, (error) => {
          err(error);
        });
      }
    });
  }

  login(email, password) {
    return this.fireAuth.auth.signInWithEmailAndPassword(email, password);
  }

 /*  userAuthenticated() {
    return this.user;
  }

  localUser() {
    return JSON.parse(localStorage.getItem("logued")).user;
  }
 */
  async getCurrentUserID() {
    const uid = await this.fireAuth.auth.currentUser.uid;
    return uid;
  }

  async getCurrentUser() {
    const user = await this.fireAuth.auth.currentUser;
    return user;
  }

/*   async getSelectedPartner() {
    console.log("USE SELECTED PARTNER");
    if (this.selected_partnerID) {
      return this.selected_partnerID;
    }

    const hashEmail = this.generalService.hashCode(
      (await this.getAuth()).email
    ).toString();
    console.log(hashEmail);

    let document = await this.afs.collection('businessAccounts').doc(hashEmail).get().toPromise().then((doc: any) => ({ ...doc.data(), key: doc.id }));
    this.selected_partnerID = document.lastPartnerSelected || document.businessList[0];
    return this.selected_partnerID;
  } */

  async setSelectedPartner(partnerID) {
    /*     localStorage.setItem('selected_partnerID', partnerID);
     */
    this.selected_partnerID = partnerID;
    this.onPartnerSelectedChange.emit(this.selected_partnerID);

    const hashEmail = this.generalService.hashCode(
      (await this.getAuth()).email
    ).toString();

    this.afs.collection('businessAccounts').doc(hashEmail).update({ lastPartnerSelected: this.selected_partnerID });
  }

  createUser(email, password, callback, error) {
    this.fireAuth.auth.createUserWithEmailAndPassword(email, password).then(() => {
      let ref = this.afs.collection('businessAccounts').doc(this.generalService.hashCode(email).toString());
      ref.update({
        email: email,
      });
      callback();
    }).catch(err => {
      error(err);
    });
  }

  recoverPassword(email, success, err) {
    this.fireAuth.auth.sendPasswordResetEmail(email).then(() => {
      success();
    }, (error) => {
      err(error)
    })
  }


  changePassword(code, newPassword, success, err) {
    this.fireAuth.auth.confirmPasswordReset(code, newPassword).then((data) => {
      success(data);
    }, (error) => {
      err(error)
    })
  }

  canShowResetPasswordForm(code, success, err) {
    this.fireAuth.auth.verifyPasswordResetCode(code).then((data) => {
      success(true);
    }, (error) => {
      err(error)
    })
  }

  logout(success, err) {
    this.fireAuth.auth.signOut().then(() => {
      success();
    }, (error) => {
      err(error)
    })
  }

}
