import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { LegalService } from 'app/services/legal.service';

@Component({
  selector: 'app-new-terms-section',
  templateUrl: './new-terms-section.component.html',
  styleUrls: ['./new-terms-section.component.css']
})
export class NewTermsSectionComponent implements OnInit {

  form: FormGroup;
  sectionID;
  title;
  content;
  index = 0;
  type;
  submitted:boolean = false;

  constructor(
    public legalService: LegalService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewTermsSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
  ) { 
    
    this.sectionID = params.sectionID;
    this.title = params.title;
    this.content = params.content;
    this.index = params.index;
    this.type = params.type;

    this.form = this.formBuilder.group({
      'title': new FormControl('', [
        Validators.required,
      ]),
      'content': new FormControl('', [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {
    if(this.sectionID){
      this.form.get('title').setValue(this.title);
      this.form.get('content').setValue(this.content);
    }
  }


  close(){
    this.dialogRef.close();
  }

  async createSection(){
    this.submitted = true;

    if(this.form.valid){

      if(this.sectionID){
        this.legalService.updateSection(
          this.sectionID,
          this.form.get('title').value,
          this.form.get('content').value,
          this.index,
          this.type,
        )
      }else{
        this.legalService.addSection(
          this.form.get('title').value,
          this.form.get('content').value,
          this.index,
          this.type,
        );
      }

      this.close();
    }

  }


}
