import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EventsService } from 'app/services/events/events.service';
import { initDomAdapter } from '@angular/platform-browser/src/browser';
import { MatDialog } from '@angular/material';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { ImageModalComponent } from 'app/general/image-modal/image-modal.component';
import { TransactionsService } from 'app/services/transactions/transactions.service';
import { NotificationsService } from 'app/services/notifications.service';
declare var google;

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  @ViewChild('map') mapElement: ElementRef;

  eventID: any;
  eventData: any;
  map: any;
  reservations = [];
  refoundRequests = [];
  loaded: boolean = false; 

  constructor(
    public eventsService: EventsService,
    private transactionsService: TransactionsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public notificationsService: NotificationsService,
  ) { }

  ngOnInit() {
    this.eventID = this.route.snapshot.paramMap.get('eventID');
    this.loaded = false;
    this.eventsService.getEventData(this.eventID).then(async data =>{
      this.eventData = ({...data.val(), id: data.key});
      await this.loadData();
      this.loaded = true;
      this.initMap();
    });
  }
  
  async loadData(){   
    this.reservations = await this.eventsService.getEventReservationsList(this.eventID);
    this.refoundRequests = await this.eventsService.getEventRefondRequests(this.eventID);
    console.log('Refound requests', this.refoundRequests);
  }

  initMap(){
    let location = {lat: this.eventData.location.lat, lng: this.eventData.location.lng};

    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      center: location,
      zoom: 13,
      mapTypeId: 'roadmap',
      zoomControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: false,
    });

    var marker = new google.maps.Marker({
      position: location,
      map: this.map,
      title: this.eventData.place,
    });
  }

  async showRefoundDoc(docURL){
    let dialogRef = await this.dialog.open(ImageModalComponent, {
      data: {
        documentURL: docURL,
      }
    });

  }


  async showConfirmationModal(refoundRequest: any, selectedAction: string, index: number) {
    let dialogRef = await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "",
        body: "¿Está seguro que desea " + selectedAction + " esta solicitud de reembolso?",
        ok: "Continuar",
        action: (async () => {
          
          if(selectedAction === "Aceptar"){
            this.acceptRefound(refoundRequest);
          } else if (selectedAction === "Denegar"){
            this.denyRefound(refoundRequest);
          }
        }),
        cancel: "Cancelar",
      }
    });
  }

  async acceptRefound(request){
    let refoundResponse;
    let userID = request.key;
    let type = request['reservation'].type;
    let paymentID = request['reservation'].paymentID;

    try{
      if (type == "tokens") {
        refoundResponse =  await this.eventsService.doEventRefound(
          'doEventTokensRefound',
          request,
          this.eventData.owner,
          this.eventID,
        );
      } else if (type == "tickets") {
        refoundResponse =  await this.eventsService.doEventRefound(
          'doEventTicketsRefound',
          request,
          this.eventData.owner,
          this.eventID,
        );
      }

      console.log(refoundResponse);

      if(refoundResponse.executed){
          await this.eventsService.removeEventReservation(this.eventID,userID, paymentID, this.eventData);
          await this.eventsService.removeRefoundRequest(this.eventID, userID);
          await this.eventsService.removeEventToUserReservationsList(userID, this.eventID);

          /*this.notificationsService.createNotificationV2(
            userID,
            "¡Tu solicitud ha sido aceptada!'",
            "Solicitud de reembolso",
            this.eventData.photo_url,
            'event_refound',
            'event-detail;id=' + this.eventID,
          );*/

          this.loadData();
      }

    }catch (e){
      console.error(e);
    }
  }

  async denyRefound(request){
    console.log(request);
    let userID = request.key;

    await this.eventsService.setRefoundRequestStatus(this.eventID, request.key, 'denegada');
    /*this.notificationsService.createNotificationV2(
      userID,
      "¡Su solicitud ha sido denegada!'",
      "Solicitud de reembolso",
      this.eventData.photo_url,
      'event_refound',
      'event-detail;id=' + this.eventID,
    );*/

    this.loadData();
  }

  editEvent(){
    let isTraeguateEvent = this.route.snapshot.parent.paramMap.has('traeguateID');
    if(isTraeguateEvent){
      this.router.navigate([`/dashboard/events/traeguate/edit/${this.eventID}`]); 
    }else{
      this.router.navigate([`/partner/events/edit/${this.eventID}`]); 
    }
  }
}
