import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  displayedColumns: string[] = ['Nombres', 'Creación', 'Correo'];
  list: any;
  constructor(public customerService:CustomerService) {

    customerService.getUsersList((list) => {
      this.list = list;
    });
  }

  ngOnInit() {
  }

}
