import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, map } from 'rxjs/operators';

@Injectable()
export class VehicleService {

  constructor(public db: AngularFireDatabase) { }

  getList(callback){
    this.db.list('/vehicles').snapshotChanges().pipe(take(1)).pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(snapshot => {
      callback(snapshot);
    });
  }
}
