import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { GeneralService } from '../general.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(
    public http: HttpClient,
    public afs: AngularFirestore,
    public generalService: GeneralService
  ) { }

  async changeInvitationRol(email, businessID, rolID) {
    const hashEmail = this.generalService.hashCode(email).toString();
    await this.afs.collection('businessAccounts').doc(hashEmail).set({
      businessRol: {
        [businessID]: rolID
      }
    }, { merge: true });

  }

  async sendInvitation(email, businessID, rol) {
    const hashEmail = this.generalService.hashCode(email).toString();
    const ref = this.afs.collection('businessAccounts').doc(hashEmail).ref;
    const businessRef = this.afs.collection('business').doc(businessID).ref;

    // Uncomment to initialize the doc.
    // sfDocRef.set({ population: 0 });
    await this.afs.collection('businessAccounts').doc(hashEmail).set({ email: email }, { merge: true });

    return this.afs.firestore.runTransaction(async (transaction) => {
      var data = (await transaction.get(ref).then(async (doc) => doc)).data() || {};
      var businessData = (await transaction.get(businessRef).then(async (doc) => doc)).data() || {};

      var newBusinessList;
      if (!data.businessList) {
        newBusinessList = [businessID];
      } else if (data.businessList.indexOf(businessID) == -1) {
        data.businessList.push(businessID);
        newBusinessList = data.businessList;
      }

      var newBusinessRol;
      newBusinessRol = data.businessRol || {};
      newBusinessRol[businessID] = rol;

      transaction.update(ref, { businessRol: newBusinessRol });
      transaction.update(ref, { businessList: newBusinessList });

      var newEmployeesList;
      console.log(businessData.employees);

      if (!businessData.employees) {
        newEmployeesList = [hashEmail];
        console.log(newEmployeesList);

      } else if (businessData.employees.indexOf(hashEmail) == -1) {
        businessData.employees.push(hashEmail);
        newEmployeesList = businessData.employees;
        console.log(newEmployeesList);
      }

      transaction.update(businessRef, { employees: newEmployeesList });

    }).then(() => {
      console.log("Transaction successfully committed!");
    }).catch((error) => {
      console.log("Transaction failed: ", error);
    });
  }


  removeInvitation(email, businessID) {
    const hashEmail = this.generalService.hashCode(email).toString();
    const ref = this.afs.collection('businessAccounts').doc(hashEmail).ref;
    const businessRef = this.afs.collection('business').doc(businessID).ref;

    // Uncomment to initialize the doc.
    // sfDocRef.set({ population: 0 });

    return this.afs.firestore.runTransaction(async (transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      var data = (await transaction.get(ref).then(async (doc) => doc)).data() || {};
      var businessData = (await transaction.get(businessRef).then(async (doc) => doc)).data() || {};

      var newBusinessList = data.businessList;
      var newBusinessRol = data.businessRol;

      newBusinessList.splice(newBusinessList.indexOf(businessID), 1);
      delete newBusinessRol[businessID];

      console.log(newBusinessList, newBusinessRol);

      transaction.update(ref, { businessRol: newBusinessRol || {} });
      transaction.update(ref, { businessList: newBusinessList || [] });

      var newEmployeesList = businessData.employees;
      newEmployeesList.splice(newEmployeesList.indexOf(hashEmail), 1);
      transaction.update(businessRef, { employees: newEmployeesList || [] });

    }).then(() => {
      console.log("Transaction successfully committed!");
    }).catch((error) => {
      console.log("Transaction failed: ", error);
    });
  }

  sendEmail(data) {
    return this.http.post(environment.cloudBaseUrl + '/sendActionEmail', data, { observe: 'response' }).toPromise();
  }
}
