import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class LegalService {

  constructor(
    public db: AngularFireDatabase,
    private afs: AngularFirestore,
  ) { }

  async publishVersion(terms, policies){
    let generalInfoRef = this.afs.firestore.collection('legal').doc('general-info');

    let response = await  this.afs.firestore.runTransaction( async transaction => {

      let generalInfoDoc = await transaction.get(generalInfoRef);
      let newVersionNumber;
      if(generalInfoDoc.exists){
        let generalInfo = generalInfoDoc.data();
        newVersionNumber = generalInfo.version_counter + 1;
      } else {
        newVersionNumber = 1;
      }

      let docRef = this.afs.firestore.collection('legal/versions/items').doc();


      //Update terms section indexes
      for (let i = 0; i < terms.length; i++) {
        const section = terms[i];
        const sectionRef = this.afs.firestore.collection('/legal/terms-conditions/sections').doc(section.id);
      
        transaction.update(sectionRef, {
          index: section.index
        }); 
      }

      //Update policies section indexes
      for (let i = 0; i < policies.length; i++) {
        const section = policies[i];
        const sectionRef = this.afs.firestore.collection('/legal/privacy-policies/sections').doc(section.id);
      
        transaction.update(sectionRef, {
          index: section.index
        }); 
      }

      transaction.set(generalInfoRef,{
        published_date: firebase.firestore.FieldValue.serverTimestamp(),
        version_counter: newVersionNumber,
        version_id: docRef.id,
      });

      transaction.set(docRef,{
        date: firebase.firestore.FieldValue.serverTimestamp(),
        version: newVersionNumber,
        terms: terms,
        policies: policies,
      });

      return;
    }).then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });


    return response;
  }

  async getVersion(versionID){
    return this.afs.collection('/legal/versions/items').doc(versionID)
      .get()
      .toPromise()
      .then(doc => ({...doc.data(), id: doc.id}));
  }
  
  async updateTermsSectionIndexes(terms){
    let batch = this.afs.firestore.batch();
    for (let i = 0; i < terms.length; i++) {
      const section = terms[i];
      const sectionRef = this.afs.firestore.collection(`/legal/terms-conditions/sections`).doc(section.id);

      batch.update(sectionRef, {
        index: i + 1,
      }); 
    }

    await batch.commit();
  }

  async updatePoliciesSectionIndexes(policies){
    let batch = this.afs.firestore.batch();
    for (let i = 0; i < policies.length; i++) {
      const section = policies[i];
      const sectionRef = this.afs.firestore.collection(`/legal/privacy-policies/sections`).doc(section.id);

      batch.update(sectionRef, {
        index: i + 1,
      }); 
    }

    await batch.commit();
  }

  async updateSubindexes(sectionID, subsections, type){
    let batch = this.afs.firestore.batch();
    for (let i = 0; i < subsections.length; i++) {
      const subsection = subsections[i];
      const sectionRef = this.afs.firestore.collection(`/legal/${type}/sections/${sectionID}/subsections`).doc(subsection.id);

      batch.update(sectionRef, {
        index: i + 1,
      }); 
    }

    await batch.commit();
  }

  async getlastVersion(){
    let query = this.afs.collection('/legal/versions/items', ref => ref
      .orderBy('version','desc')
      .limit(1)
    );

    let snapshot = await query.get().toPromise();

    if(!snapshot.empty){
      let doc = snapshot.docs[0];
      return {
        ...doc.data(),
        id: doc.id,
      };
    }
    
    return null;
  }

  async getFullTerms(){
    let query = this.afs.collection('/legal/terms-conditions/sections', ref => ref
      .orderBy('index','asc')
    );


    let sections: any = await query.get().toPromise().then(snapshot => snapshot.docs);
    for (let i = 0; i < sections.length; i++) {
      const doc = sections[i];
      sections[i] = {
        ...doc.data(),
        id: doc.id,
        index: i + 1,
      }
    }

    let subsectionPromises = [];

    for (const key in sections) {
      if (sections.hasOwnProperty(key)) {
        const section = sections[key];
        
        let query2 = this.afs.collection(`/legal/terms-conditions/sections/${section.id}/subsections`, ref => ref
          .orderBy('index','asc')
        );

        subsectionPromises.push(
          query2.get().toPromise().then(snapshot => snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        );
      }
    }

    let subsections = await Promise.all(subsectionPromises);

    let i = 0;
    for (const key in sections) {
      if (sections.hasOwnProperty(key)) {
        sections[key]['subsections'] = subsections[i];
        i++;
      }
    }

    return sections;
  }


  async getFullPolicies(){
    let query = this.afs.collection('/legal/privacy-policies/sections', ref => ref
      .orderBy('index','asc')
    );

    let sections: any = await query.get().toPromise().then(snapshot => snapshot.docs);
    for (let i = 0; i < sections.length; i++) {
      const doc = sections[i];
      sections[i] = {
        ...doc.data(),
        id: doc.id,
        index: i + 1,
      }
    }
    
    
    let subsectionPromises = [];

    for (const key in sections) {
      if (sections.hasOwnProperty(key)) {
        const section = sections[key];
        
        let query2 = this.afs.collection(`/legal/privacy-policies/sections/${section.id}/subsections`, ref => ref
          .orderBy('index','asc')
        );

        subsectionPromises.push(
          query2.get().toPromise().then(snapshot => snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        );
      }
    }

    let subsections = await Promise.all(subsectionPromises);

    let i = 0;
    for (const key in sections) {
      if (sections.hasOwnProperty(key)) {
        sections[key]['subsections'] = subsections[i];
        i++;
      }
    }

    return sections;
  }

  getVersions() {
    let query = this.afs.collection('/legal/versions/items', ref => ref
      .orderBy('version','asc')
    );

    return query;
  }

  getTermsSections() {
    let query = this.afs.collection('/legal/terms-conditions/sections', ref => ref
      .orderBy('index','asc')
    );

    return query;
  }

  getPoliciesSections() {
    let query = this.afs.collection('/legal/privacy-policies/sections', ref => ref
      .orderBy('index','asc')
    );

    return query;
  }


  getSubsections(sectionID, type) {
    let query = this.afs.collection(`/legal/${type}/sections/${sectionID}/subsections`, ref => ref
      .orderBy('index','asc')
    );

    return query;
  }

  getSectionDoc(type, sectionID) {
    return this.afs.doc(`/legal/${type}/sections/${sectionID}`);
  }

  addSection(title, content, index, type){
    return this.afs.collection(`/legal/${type}/sections`).add({
      title: title,
      content: content,
      index: index,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  async deleteSection(sectionID, type){
    return this.afs.collection(`/legal/${type}/sections`).doc(sectionID).delete();
  }

  async deleteSubsection(sectionID, subsectionID, type){
    return this.afs.collection(`/legal/${type}/sections/${sectionID}/subsections`).doc(subsectionID).delete();
  }

  updateSection(sectionID, title, content, index, type){
    return this.afs.doc(`/legal/${type}/sections/${sectionID}`).update({
      title: title,
      content: content,
      index: index,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  updateSectionContent(sectionID, type, content){
    return this.afs.doc(`/legal/${type}/sections/${sectionID}`).update({
      content: content,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  addSubsection(sectionID, type, content, subindex){
    let batch = this.afs.firestore.batch();
    batch.set(this.afs.firestore.collection(`/legal/${type}/sections/${sectionID}/subsections`).doc(),{
      index: subindex,
      content: content,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });

    batch.update(this.afs.firestore.collection(`/legal/${type}/sections`).doc(sectionID),{
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    });

    return batch.commit();
  }

  updateSubsection(sectionID, subsectionID, type, content, subindex){

    let batch = this.afs.firestore.batch();
    batch.set(this.afs.firestore.collection(`/legal/${type}/sections/${sectionID}/subsections`).doc(subsectionID),{
      index: subindex,
      content: content,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });

    batch.update(this.afs.firestore.collection(`/legal/${type}/sections`).doc(sectionID),{
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    });

    return batch.commit();
  }
}
