import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource,} from '@angular/material';
import { map, take } from 'rxjs/operators';
import { LegalService } from 'app/services/legal.service';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  loadingVersions: boolean = true;
  versionsSubscription: any;
  versions = [];
  dataSourceVersions = new MatTableDataSource<{}>();
  displayedColumnsVersions: string[] = ['version', 'date', 'options'];


  constructor(
    public dialog: MatDialog,
    private legalSerice: LegalService,
  ) {
  }

  ngOnInit() {
    this.listenToVersions();
  }

  ngOnDestroy(){
    if(this.versionsSubscription){
      this.versionsSubscription.unsubscribe();
    }

  }

  async listenToVersions(){
    this.loadingVersions = true;
    this.versionsSubscription  = this.legalSerice
      .getVersions()
      .valueChanges({idField: 'id'})
      .subscribe((data) => {
        this.versions = data;
        this.dataSourceVersions.data = this.versions;
        this.loadingVersions = false;
      });
  }

}
