import { Component, OnInit } from '@angular/core';
import { BenefitService } from '../../../services/benefit.service';
import { CustomerService } from '../../../services/customer.service';
import { MatDialog } from '@angular/material';
import { HistoryService } from '../../../services/history.service';
import * as firebase from 'firebase';
import { AuthService } from '../../../services/auth.service';
import { PaymentsService } from '../../../services/payments.service'

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.css']
})
export class BenefitComponent implements OnInit {

  list: any;
  customers: any;
  request = { code: '' };
  loggedData;
  data = {};
  history;

  constructor(
    public benefitService: BenefitService,
    public customerService: CustomerService,
    public historyService: HistoryService,
    public authService: AuthService,
    public dialog: MatDialog,
    public paymentService: PaymentsService
  ) {
    this.loggedData = JSON.parse(localStorage.getItem('logued_partner_data'));
    this.benefitService.getListByPartner(localStorage.getItem('bussiness_active'), (list) => {
      this.list = list;
      this.customerService.getList((customers) => {
        this.customers = customers;
      });
    });

    this.paymentService.getPayments(data => {
      this.history = data;
    });
  }

  retrieve(request, type, user_tickets) {
    if (type == 'tickets') {
      if (user_tickets >= request.prices.tickets) {
        this.data = {
          type: 'benefit_exchange',
          company: this.loggedData.partner.id,
          driver: request.user,
          offer: request.offer,
          offer_type: request.offer_type,
          level: request.level,
          prices: {
            quetzales: parseFloat(request.prices.quetzales),
            exchange: request.prices.exchange,
            tickets: request.prices.tickets
          },
          status: 'pending',
          time: firebase.database.ServerValue.TIMESTAMP,
          payment_type: request.payment_type
        };

        if (this.list['offers'][request.offer].type == 'dinamic_discount') {
          this.data['regular_price'] = request.payment;
          this.data['percentage'] = request.percentage;
        }
        else if (this.list['offers'][request.offer].type == 'static_discount') {
          this.data['promo_price'] = request.promo_price;
        }

        this.historyService.create(this.data, () => {
          this.benefitService.removePromo(this.loggedData.partner.id, this.request.code, ok => {
            alert('Compra realizada exitosamente');
            this.request.code = '';
          }, err => {
            console.log(err);
          });
        }, () => {
          alert('Error al realizar transacción');
        });
      }
      else {
        alert('No se cuenta con suficientes tickets para realizar la transacción');
      }
    }
    else if (type == 'tokens') {
      this.benefitService.getClientTokens(request.user, tokens => {

        if (tokens >= request.prices.tokens) {
          this.data = {
            type: 'benefit_exchange',
            company: this.loggedData.partner.id,
            driver: request.user,
            offer: request.offer,
            offer_type: request.offer_type,
            level: request.level,
            prices: {
              quetzales: parseFloat(request.prices.quetzales),
              exchange: request.prices.exchange,
              tokens: request.prices.tokens
            },
            status: 'pending',
            time: firebase.database.ServerValue.TIMESTAMP,
            payment_type: request.payment_type
          };

          if (this.list['offers'][request.offer].type == 'dinamic_discount') {
            this.data['regular_price'] = request.payment;
            this.data['percentage'] = request.percentage;
          }
          else if (this.list['offers'][request.offer].type == 'static_discount') {
            this.data['promo_price'] = request.promo_price;
          }
          let newTokens = tokens - request.prices.tokens;
          this.benefitService.updateClientTokens(request.user, newTokens)
          this.historyService.create(this.data, () => {
            this.benefitService.removePromo(this.loggedData.partner.id, this.request.code, ok => {
              alert('Compra realizada exitosamente');
              this.request.code = '';
            }, err => {
              console.log(err);
            });
          }, () => {
            alert('Error al realizar transacción');
          });
        }
        else {
          alert('No se cuenta con suficientes tokens para realizar la transacción');
        }
      }, err => {
        console.log(err);
      });
    }
    else {
      this.data = {
        type: 'benefit_exchange',
        company: this.loggedData.partner.id,
        driver: request.user,
        offer: request.offer,
        offer_type: request.offer_type,
        level: request.level,
        prices: {
          quetzales: parseFloat(request.prices.quetzales),
          exchange: request.prices.exchange,
          tickets: request.prices.tickets
        },
        status: 'pending',
        time: firebase.database.ServerValue.TIMESTAMP,
        payment_type: request.payment_type
      };

      if (this.list['offers'][request.offer].type == 'dinamic_discount') {
        this.data['regular_price'] = request.payment;
        this.data['percentage'] = request.percentage;
      }
      else if (this.list['offers'][request.offer].type == 'static_discount') {
        this.data['promo_price'] = request.promo_price;
      }

      this.historyService.create(this.data, () => {
        this.benefitService.removePromo(this.loggedData.partner.id, this.request.code, ok => {
          alert('Compra realizada exitosamente');
          this.request.code = '';
        }, err => {
          console.log(err);
        });
      }, () => {
        alert('Error al realizar transacción');
      });
    }


  }

  ngOnInit() {

  }
}
