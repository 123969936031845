import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CreateComponent } from 'app/components/partner/product/create/create.component';
import { ProductService } from 'app/services/product.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  
  list: any;
  partnerId;
  loggedData;
  carouselOne;
  permissions;

  constructor(
    public dialog: MatDialog,
    public productService: ProductService,
    public authService: AuthService,
  ) { }

  changeVisibility(offerId, offers) {
    this.productService.getOfferRef(this.partnerId, offerId).update({ disabled: !offers.disabled });
  }

  ngOnInit() {
    
    this.productService.getListByPartner(localStorage.getItem('bussiness_active'), (data) => {
      this.list = data;
    });


    this.carouselOne =
      {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 4,
        speed: 500,
        interval: 5000,
        point: {
          visible: true,
          pointStyles: `
              .ngxcarouselPoint {
                list-style-type: none;
                text-align: center;
                padding: 12px;
                margin: 0;
                white-space: nowrap;
                overflow: auto;
                position: absolute;
                width: 100%;
                bottom: 20px;
                left: 0;
                box-sizing: border-box;
              }
              .ngxcarouselPoint li {
                display: inline-block;
                border-radius: 999px;
                background: rgba(255, 255, 255, 0.55);
                padding: 5px;
                margin: 0 3px;
                transition: .4s ease all;
              }
              .ngxcarouselPoint li.active {
                  background: white;
                  width: 10px;
              }
            `
        },
        custom: 'banner',
        touch: true,
        loop: false,
        easing: 'cubic-bezier(0, 0, 0.2, 1)'
      };
  }
  openDialog(): void {
    let dialogRef = this.dialog.open(CreateComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
