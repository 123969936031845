import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "accountType",
})
export class AccountTypePipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 1:
        return "Monetaria";
      case 2:
        return "Ahorros";
    }

    return "No definida";
  }
}
