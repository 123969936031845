import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable()
export class BillService {
  headers
  constructor(
    public http: Http,
    public db: AngularFireDatabase,
    public afs: AngularFirestore,
  ) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
  }

  getResolutions() {
    return this.db.list('data/billing');
  }


  async getBillsBatch(type, lastDocument, length) {
    let docs = [];
    if (lastDocument != null) {
      docs = await this.afs.collection('bills', ref => ref
        .where('type', '==', type)
        .orderBy('bill.count', 'desc')
        .startAfter(lastDocument)
        .limit(length)
      ).get().toPromise().then(snap => snap.docs);
    } else {
      docs = await this.afs.collection('bills', ref => ref
        .where('type', '==', type)
        .orderBy('bill.count', 'desc')
        .limit(length)
      ).get().toPromise().then(snap => snap.docs);
    }

    return docs;
  }

  cancelBill(bill, key, userId) {

    let data = {
      audit_number: bill['audit_number'],
      user_id: userId,
      transaction_ref: key
    };
    var processAnnulment = firebase.functions().httpsCallable('processAnnulment');

    return processAnnulment(data).then(function (result) {
      console.log('Response from firebase');
      console.log(result.data);
      return result.data;
    }).catch(function (error) {
      console.log(`Error from firebase ${error.message}`);
      return error;
    });
  }

  async getBillTransaction(transactionID: string) {
    return await this.afs.collection('transactions').doc(transactionID).get().toPromise().then(snap => snap.data());
  }

  async searchBill(key: string, type: string, lastDocument, length) {

    enum requestType {
      transaction = 1,
      referenceNumber = 2,
      billNumber = 3,
      email = 4,
      authNumber = 5,
      auditNumber = 6,
    }

    let docs = [];

    switch (Number(type)) {
      case requestType.transaction:
        // uttb7e8K1MhQaV29co3b
        let doc = await this.afs.collection('bills').doc(key).get().toPromise().then(snap => snap);
        if (doc.exists) {
          docs.push(doc);
        }
        break;
      case requestType.referenceNumber:
        // 000418000140
        // let querySnapshot = await this.afs.collection('transactions', ref => ref.where('extra_data.reference_number', '==', key)).get().toPromise();
        // let transactions = querySnapshot.docs.map(doc => doc.data());
        // console.log(transactions);
        let docsSnapshot = await this.afs.collection('transactions', ref => ref.where('extra_data.reference_number', '==', key)).get().toPromise().then(snap => snap.docs);
        if (docsSnapshot.length > 0) {
          let bill = await this.afs.collection('bills').doc(docsSnapshot[0].id).get().toPromise().then(snap => snap);
          docs.push(bill);
        }
        break;
      case requestType.billNumber:
        if (lastDocument != null) {
          docs = await this.afs.collection('bills', ref => ref
            .where('bill.count', '==', Number(key))
            .startAfter(lastDocument)
            .limit(length)
          ).get().toPromise().then(snap => snap.docs);
        } else {
          docs = await this.afs.collection('bills', ref => ref
            .where('bill.count', '==', Number(key))
            .limit(length)
          ).get().toPromise().then(snap => snap.docs);
        }

        break;
      case requestType.email:
        //let userSnapshot = await this.afs.collection('users/master/items', ref => ref.where('email', '==', key)).get().toPromise();
        let userSnapshot = await this.afs.collection('users').doc('master').collection('items', ref => ref.where('email', '==', key)).get().toPromise().then(snap => snap.docs);
        if (userSnapshot.length > 0) {
          if (lastDocument != null) {
            docs = await this.afs.collection('bills', ref => ref
              .where('user', '==', userSnapshot[0].id)
              .startAfter(lastDocument)
              .limit(length)
            ).get().toPromise().then(snap => snap.docs);
          } else {
            docs = await this.afs.collection('bills', ref => ref
              .where('user', '==', userSnapshot[0].id)
              .limit(length)
            ).get().toPromise().then(snap => snap.docs);
          }
        }
        break;
      case requestType.authNumber:
        // 000070 or HOSTOK
        let authDocsSnapshot = await this.afs.collection('transactions', ref => ref.where('extra_data.authorization_number', '==', key)).get().toPromise().then(snap => snap.docs);
        if (authDocsSnapshot.length > 0) {
          console.log(authDocsSnapshot);
          for (let index = 0; index < authDocsSnapshot.length; index++) {
            const bill = await this.afs.collection('bills').doc(authDocsSnapshot[index].id).get().toPromise().then(snap => snap);
            if (!bill['_document']) {
              console.log('Documento dañado');
            } else {
              console.log(bill);
              docs.push(bill);
            }
          }
        }
        break;
      case requestType.auditNumber:
        if (lastDocument != null) {
          docs = await this.afs.collection('bills', ref => ref
            .where('bill.audit_number', '==', key)
            .startAfter(lastDocument)
            .limit(length)
          ).get().toPromise().then(snap => snap.docs);
        } else {
          docs = await this.afs.collection('bills', ref => ref
            .where('bill.audit_number', '==', key)
            .limit(length)
          ).get().toPromise().then(snap => snap.docs);
        }
        break;
      default:
        break;
    }
    console.log('Search Result');
    console.log(docs);
    return docs;
  }

  getBill(bill, key) {
    let data = {
      key: key,
      bill: bill
    };

    return this.http.post(environment.cloudBaseUrl + '/getBills', data, { headers: this.headers });
  }

  createCustomInvoice(params) {

    var customBill = firebase.functions().httpsCallable('createCustomInvoice');

    return customBill(params).then(function (result) {
      console.log('Response from firebase');
      console.log(result.data);
      return result.data;
    }).catch(function (error) {
      console.log(`Error from firebase ${error.message}`);
      return error;
    });
  }

}
