import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { OffersService } from 'app/services/offers.service';
import { AuthService } from 'app/services/auth.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BusinessAccountModel } from 'app/models/business-account.model';



/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.css']
})
export class NewOfferComponent implements OnInit {

  institutionID;
  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  loaded: boolean = false;

  matcher = new MyErrorStateMatcher();
  offerID: string;
  offer: any;
  formOffer;

  types = [
    {value: 'free', viewValue: 'Gratuita'},
    {value: 'discount', viewValue: 'Descuento'},
    {value: 'promotion', viewValue: 'Promoción'}
  ];

  levels = ['1XP', '2XP', '3XP', '4XP', '5XP'];

  constructor(
    private formBuilder: FormBuilder,
    private offersService: OffersService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) { 
    this.formOffer = this.formBuilder.group({
      'title': new FormControl('', [
        Validators.required,
      ]),
      'description': new FormControl('', [
        Validators.required,
      ]),
      'minLevel': new FormControl('', [
        Validators.required,
      ]),
      'type': new FormControl('', [
        Validators.required,
      ]),
      'percentage': new FormControl('',[
        Validators.required,
        Validators.min(1),
        Validators.max(99),
      ]),
      'price': new FormControl('',[
        Validators.min(1),        
      ]),
      'activated': new FormControl(),
    });
  }

  async ngOnInit() {
    this.offerID = this.route.snapshot.paramMap.get('offerID');
    await this.autofillData();

/*     this.institutionID = this.route.snapshot.paramMap.get('partnerID') || await this.authService.getSelectedPartner();
    this.authService.onPartnerSelectedChange.subscribe(async (businessID) => {
      this.institutionID = this.route.snapshot.paramMap.get('partnerID') || businessID;
    }); */


    this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.institutionID = (document.lastPartnerSelected);
      }
    });
  }

  async autofillData(){
    if(this.offerID != null){
      this.loaded = false;
      this.offer = await this.offersService.getOfferData(this.offerID);
      console.log('oferta', this.offer);
      console.log('dato', this.offer['title']);


      this.formOffer.get('title').setValue(this.offer.title);
      this.formOffer.get('description').setValue(this.offer.description);
      this.formOffer.get('minLevel').setValue(this.offer.minLevel);
      this.formOffer.get('type').setValue(this.offer.type);
      this.formOffer.get('activated').setValue(this.offer.activated);

      if(this.offer.type === 'discount'){
        this.formOffer.get('percentage').setValue(this.offer.percentage);
      } 
      else if(this.offer.type ==='promotion' && this.offer.price){
        this.formOffer.get('price').setValue(this.offer.price);
      }

      this.setValidators(this.offer.type);

    } else{   
      this.formOffer.get('minLevel').setValue(this.levels[0]);
      this.formOffer.get('type').setValue(this.types[0].value);
      this.formOffer.get('percentage').disable();
      this.formOffer.get('activated').setValue(true);
    }

    this.loaded = true;
  }
  
  setValidators(value: String){

    if(value !== 'discount'){
      this.formOffer.get('percentage').disable();
    } else if (value === 'discount'){
      this.formOffer.get('percentage').enable();
    }

  }

  setStatus(event: MatSlideToggleChange) {
    this.formOffer.get('activated').value = event.checked;
  }

  async saveOffer(){

    this.offer = {
      title: this.formOffer.get('title').value,
      description: this.formOffer.get('description').value,
      minLevel: this.formOffer.get('minLevel').value,
      type: this.formOffer.get('type').value,
      activated: this.formOffer.get('activated').value,
      institutionID: this.institutionID,
    }

    if(this.offer.type === 'discount'){
      this.offer['percentage'] =  this.formOffer.get('percentage').value;
    } 
    else if(this.offer.type ==='promotion' && this.formOffer.get('price').value){
      this.offer['price'] = this.formOffer.get('price').value
    }
    
    try{
      if(this.offerID != null){
        await this.offersService.updateOffer(this.offerID, this.offer);
      }else{   
        await this.offersService.saveOffer(this.offer);
      }

      window.history.back();
      // this.router.navigateByUrl("/partner/offers");
    } catch (e){
      console.log(e);
    }
  }
}
