import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatTable } from '@angular/material';
import { LegalService } from 'app/services/legal.service';
import { NewTermsSectionComponent } from './new-terms-section/new-terms-section.component';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle} from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { InfoDialogComponent } from 'app/general/info-dialog/info-dialog.component';

@Component({
  selector: 'app-new-legal-version',
  templateUrl: './new-legal-version.component.html',
  styleUrls: ['./new-legal-version.component.css']
})
export class NewLegalVersionComponent implements OnInit {

  loadingTerms: boolean = true;
  termsSubscription: any;
  updatedTermsIndexes: boolean = false;
  terms = [];
  currentTermsSectionIndex = 0;
  dataSourceTermsSections = new MatTableDataSource<{}>();
  displayedColumnsTermsSections: string[] = ['position', 'index', 'title', 'options'];
  
  loadingPolicies: boolean = true;
  policiesSubscription: any;
  updatedPoliciesIndexes: boolean = false;
  policies = [];
  currentPoliciesSectionIndex = 0;
  dataSourcePoliciesSections = new MatTableDataSource<{}>();
  displayedColumnsPoliciesSections: string[] = ['position', 'index', 'title', 'options'];
  
  loadingPrivacy: boolean = true;
  
  constructor(
    private legalService: LegalService,
    public dialog: MatDialog,
    public router: Router,
  ) { }

  ngOnInit() {
    this.listenToTerms();
    this.listenToPolicies();
  }

  ngOnDestroy(){
    if(this.termsSubscription){
      this.termsSubscription.unsubscribe();
    }

    if(this.policiesSubscription){
      this.policiesSubscription.unsubscribe();
    }
  }

  async listenToTerms(){
    this.loadingTerms = true;
    this.termsSubscription  = this.legalService
      .getTermsSections()
      .valueChanges({idField: 'id'})
      .subscribe((data: any) => {

        for (let i = 0; i < data.length; i++) {
          data[i].index = i + 1;   
        }
        this.terms = data;

        if(!this.updatedTermsIndexes){
          this.updatedTermsIndexes = true;
          this.legalService.updateTermsSectionIndexes(this.terms);
        }
        this.dataSourceTermsSections.data = this.terms;
        this.currentTermsSectionIndex = this.terms.length;
        this.loadingTerms = false;
      });
  }

  async listenToPolicies(){
    this.loadingPolicies = true;
    this.policiesSubscription  = this.legalService
      .getPoliciesSections()
      .valueChanges({idField: 'id'})
      .subscribe((data: any) => {

        for (let i = 0; i < data.length; i++) {
          data[i].index = i + 1;   
        }
        this.policies = data;

        if(!this.updatedPoliciesIndexes){
          this.updatedPoliciesIndexes = true;
          this.legalService.updatePoliciesSectionIndexes(this.policies);
        }

        this.dataSourcePoliciesSections.data = this.policies;
        this.currentPoliciesSectionIndex = this.policies.length;
        this.loadingPolicies = false;
      });
  }


  createSection(type){
    let customIndex = type === 'terms-conditions' ? this.currentTermsSectionIndex : this.currentPoliciesSectionIndex;
    this.dialog.open(NewTermsSectionComponent, {
      data: {
        sectionID: null,
        title: null,
        content: null,
        type: type,
        index: customIndex + 1,
      },    

      maxWidth  : '95vw',
      maxHeight: '95vh',
    });
  }

  editSection(type, element){
    this.dialog.open(NewTermsSectionComponent, {
      data: {
        sectionID: element.id,
        title: element.title,
        content: element.content,
        type: type,
        index: element.index,
      },    

      maxWidth  : '95vw',
      maxHeight: '95vh',
    });
  }

  async showConfirmationDialog(type, element, message){
    this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Eliminar sección",
        body: message,
        cancel: "Cancelar",
        ok: "Eliminar",
        action: ( async () => {
          await this.legalService.deleteSection(element.id, type);
          if(type === 'terms-conditions'){
            this.updatedTermsIndexes = false;
          } else {
            this.updatedPoliciesIndexes = false;
          }
        }),
      },

      maxWidth  : '400px',
      maxHeight: '95vh',
    });

  }

  deleteSection(type, element){
    this.showConfirmationDialog(type, element, '¿Estás seguro de eliminar este inciso? Una vez elminado no podrás recuperarlo.');
  }

  showInfoDialog(title, message){
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: title,
        body: message,
        ok: "OK",
      },
      maxWidth  : '400px',
      maxHeight: '95vh',
    });
  }


  publish(){
    if(this.terms.length === 0 ){
      this.showInfoDialog(
        "Publicar", 
        "No se puede publicar una versión sin contenido de términos y condiciones. Por favor agrega incisos."
      );
    } else if(this.policies.length === 0){
      this.showInfoDialog(
        "Publicar", 
        "No se puede publicar una versión sin contenido de políticas de privacidad. Por favor agrega incisos."
      );
    } else {
      this.router.navigate(['/dashboard/legal/new-version/publish']);
    }

  }

  dropTermsTable(event) {
    let temp = this.terms[event.previousIndex];
    this.terms[event.previousIndex] = this.terms[event.currentIndex];
    this.terms[event.currentIndex] = temp;
    
    this.dataSourceTermsSections.data = this.terms;
    this.legalService.updateTermsSectionIndexes(this.terms);
  }

  dropPoliciesTable(event) {
    let temp = this.policies[event.previousIndex];
    this.policies[event.previousIndex] = this.policies[event.currentIndex];
    this.policies[event.currentIndex] = temp;

    this.dataSourcePoliciesSections.data = this.policies;
    this.legalService.updatePoliciesSectionIndexes(this.policies);
  }
  
}
