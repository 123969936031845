import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoryService } from 'app/services/history.service';
import { take, map } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import * as moment from 'moment';
import { CustomerService } from 'app/services/customer.service';
import { BillService } from 'app/services/bill.service';
import { GeneralService } from 'app/services/general.service';


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  filteredList: any = {};
  transactions: any = [];
  customers: any = [];
  displayedColumnsCustomers: string[] = ['key', 'img_url', 'tickets', 'names'];
  displayedColumnsTransactions: string[] = ['key', 'img_url', 'tickets', 'names', 'bill', 'time'];
  totalTransactionTokens = 0;
  transactionTokens: any = [];
  totalCustomerTokens = 0;
  customerTokens: any = [];
  dataSourceCustomers;
  dataSourceTransactions;
  users: any = {};
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private historyService: HistoryService,
    private customerService: CustomerService,
    private billService: BillService,
    private generalService: GeneralService
  ) { }

  async ngOnInit() {

    /*     this.transactions = new MatTableDataSource<TransactionsTable>(
          await this.historyService.getTransactionsRef().snapshotChanges().pipe(take(1)).pipe(
            map(changes =>
              changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
            )
          ).toPromise().then((data: any) => data)
        ); */

    await this.historyService.getTransactionsRef().snapshotChanges().pipe(take(1)).pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).toPromise().then((data: any) => {
      data.forEach(element => {
        if (!this.filteredList[element.type]) {
          this.filteredList[element.type] = 0;
        }
        this.filteredList[element.type] = this.filteredList[element.type] + 1;
        if (element.type == 'tokens_purchase' && element.status == 'ACCEPT') {
          console.log(element);
          this.totalTransactionTokens += (element.payment.total);
          this.transactionTokens.push(element.payment.total);
          this.transactions.push({...element});
          this.loadUser(element.owner);
        }
      });

      this.dataSourceTransactions = new MatTableDataSource(
        this.transactions
      );
      this.dataSourceTransactions.paginator = this.paginator;
      this.dataSourceTransactions.sort = this.sort;
    });





    this.customerService.getUsersRef().snapshotChanges().pipe(take(1)).pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).toPromise().then((data: any) => {
      data.forEach(customer => {
        if (customer.tickets && customer.tickets > 0) {
          this.totalCustomerTokens += (customer.tickets);
          this.customerTokens.push(customer.tickets);
          this.customers.push(customer);
        }
      });
      this.dataSourceCustomers = new MatTableDataSource(
        this.customers
      );
    });
  }
/* 
  sortData(sort: Sort) {
    const data = this.desserts.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'calories': return compare(a.calories, b.calories, isAsc);
        case 'fat': return compare(a.fat, b.fat, isAsc);
        case 'carbs': return compare(a.carbs, b.carbs, isAsc);
        case 'protein': return compare(a.protein, b.protein, isAsc);
        default: return 0;
      }
    });
  } */

  downloadBill(element: any) {
    //console.log(key, bill);
    element.download = true;
    this.billService.getBill(element.bill, element.key).subscribe((response) => {
      if (response.status == 200) {
        this.generalService.printPdf(element.key + '.pdf', response['_body']);
      }
      element.download = false;
    });
  }

  async loadUser(idUser) {
    if (!this.users[idUser]) {
      this.users[idUser] = await this.customerService.getById(idUser).valueChanges().pipe(take(1)).toPromise().then(data => data);
      this.users[idUser].key = idUser;
    }
  }
}