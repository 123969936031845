import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  actions: any;
  constructor(
    private afs: AngularFirestore,
  ) { }

  getUserMasterDocRef(uid) {
    return this.afs.collection('/users/master/items').doc(uid);
  }
  
  async getUserIDbyEmail(email: string){
    let query = this.afs.collection('/users/master/items', ref => ref
      .where('email', '==', email)
      .limit(1)
    );

    const snapshot = await query.get().toPromise().then(data => data);
    
    if(!snapshot.empty){
      return snapshot.docs[0].id;
    }

    return null;
  }
}
