import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { GeneralService } from '../general.service';
import { take } from 'rxjs/operators';
import { BusinessAccountModel } from 'app/models/business-account.model';


@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    public afs: AngularFirestore,
    public generalService: GeneralService

  ) { }

  async getPartnerData(email): Promise<BusinessAccountModel> {
    const hashEmail = this.generalService.hashCode(email).toString();
    let document: any =
      await this.afs.collection('businessAccounts').doc(hashEmail).get().toPromise().
        then((doc) =>
          ({
            ...doc.data(), key: doc.id,
          }));

    return document;
  }
  async updatePartnerData(email, data) {
    const hashEmail = this.generalService.hashCode(email).toString();
    let document = await this.afs.collection('businessAccounts').doc(hashEmail).set(data, { merge: true });
    return document
  }

}
