import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogOkCancelComponent } from '../../../general/dialog-ok-cancel/dialog-ok-cancel.component';
import { BusinessService } from '../../../services/business/business.service';
import { SelectedBusinessComponent } from './selected-business/selected-business.component';
import { PartnerService } from 'app/services/partner/partner.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BusinessAccountModel } from 'app/models/business-account.model';
import { RequestBusinessAccountAttempt } from 'app/shared/business-account.actions';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {

  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;
  lastPartnerSelected: string;
  businessData;
  permissions;
  myRol;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private businessService: BusinessService,
    private partnerService: PartnerService,
    private store: Store
  ) {


  }

  changeBusiness() {
    const dialogRef = this.dialog.open(SelectedBusinessComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  async loadData() {
    const email = (await this.authService.getAuth()).email;
    this.store.dispatch(new RequestBusinessAccountAttempt(email));
     
    this.businessAccount$.subscribe((account) => {
      if (account.lastPartnerSelected) {
        this.loadPartnerData(account);
      }
    });

    /*     const lastSelectedPartner = await this.authService.getSelectedPartner();
        this.loadPartnerData(lastSelectedPartner);
        this.authService.onPartnerSelectedChange.subscribe(async (businessID) => {
          this.loadPartnerData(businessID);
        }); */

  }

  async loadPartnerData(account) {
    const lastPartnerSelected = account.lastPartnerSelected;
    this.businessData = await this.businessService.getBusinessData(account.lastPartnerSelected).then(data => data);
    
    this.myRol = account.businessRol[lastPartnerSelected];

    if(this.businessData && this.businessData.rol){
      this.permissions = this.businessData.rol[this.myRol];
    }
    
    console.log(this.myRol);

  }

  ngOnInit() {
    this.loadData();
  }
}
