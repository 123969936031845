import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ConfiabilityService } from '../../../services/confiability.service';
import { CustomerService } from '../../../services/customer.service';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { DetailConfiabilityComponent } from './detail-confiability/detail-confiability.component';
import { map } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { UsersService } from 'app/services/users/users.service';
import { EditUserComponent } from '../users/edit-user/edit-user.component';
import { InfoDialogComponent } from 'app/general/info-dialog/info-dialog.component';
import { take } from 'rxjs-compat/operator/take';

@Component({
  selector: 'app-confiability',
  templateUrl: './confiability.component.html',
  styleUrls: ['./confiability.component.css']
})
export class ConfiabilityComponent implements OnInit {

  searchUserEmail: string = "";
  loading: boolean = true;
  requests = [];
  requestsSubscription: any;

  dataSourceNewRequests = new MatTableDataSource<{}>();
  displayedColumnsNewRequests: string[] = ['first_name', 'last_request_time', 'pending'];
  newRequestsSelection = new SelectionModel<{}>(true, []);
  @ViewChild('newRequestsSort') newRequestsSort: MatSort;
  @ViewChild('newRequestsPaginator') newRequestsPaginator: MatPaginator;

  constructor(
    public confiabilityService: ConfiabilityService,
    public customerService: CustomerService,
    public dialog: MatDialog,
    private usersService: UsersService,
  ) {
  }

  ngOnInit() {
    this.listenToRequests();
  }

  ngOnDestroy(){
    if(this.requestsSubscription){
      this.requestsSubscription.unsubscribe();
    }
  }

  async listenToRequests(){
    this.loading = true;
    this.requestsSubscription  = this.confiabilityService.getList().valueChanges({idField: 'key'}).pipe().take(1).subscribe((data) => {
      console.log(data);
      this.requests = data;
      this.dataSourceNewRequests.data = this.requests
      this.dataSourceNewRequests.paginator = this.newRequestsPaginator;
      

      this.dataSourceNewRequests.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'user.displayName': return item['user']['displayName'];
          case 'user.email': return item['user']['email'];
          default: return item[property];
        }
      };

      this.dataSourceNewRequests.filterPredicate = (data, filter: string)  => {

        const accumulator = (currentTerm, key) => this.nestedFilterCheck(currentTerm, data, key);

        const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };
      
      this.dataSourceNewRequests.sort = this.newRequestsSort;
  
      this.loading = false
    });
  }

  doNewRequestsFilter(value: string) {
    this.dataSourceNewRequests.filter = value.trim().toLocaleLowerCase();

    if (this.dataSourceNewRequests.paginator) {
      this.dataSourceNewRequests.paginator.firstPage();
    }
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }


  openDetail(userID: string) {
   this.dialog.open(DetailConfiabilityComponent, {
      // id: '#detailConfDialog',
      data: {
        userID: userID,
      },    
      // minWidth: '95vw',
      maxWidth  : '95vw',
      minHeight: '95vh',
      maxHeight: 'none',
      height: '95vh',
      width: '95vw',
    });
  }

  async searchUser(){
    this.loading = true;
    let userID = await this.usersService.getUserIDbyEmail(this.searchUserEmail);
    this.loading = false;

    if(userID){
      this.showUserModal(userID);
    } else {
      this.showInfoDialog();
    }
  }

  async showUserModal(userID: string){
    this.dialog.open(EditUserComponent, {
      data: {
        userID: userID,
      },    

      minWidth: '400px',
      maxWidth: '90vw',
      maxHeight: '80vh',      
    });
  }

  async showInfoDialog(){
    await this.dialog.open(InfoDialogComponent, {
      data: {
        title: "Sin resultados",
        body: `No se ha encontrado ningún usuario que posea el correo ${this.searchUserEmail}`,
        ok: "OK",
      }
    });
  }
}


