import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { BenefitService } from '../../../../services/benefit.service';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent, MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { PasswordValidator } from '../../../../validators/password'
import { StorageService } from '../../../../services/storage.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GeneralService } from 'app/services/general.service';
import { BusinessService } from 'app/services/business/business.service';
import { Location } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-bussiness',
  templateUrl: './new-bussiness.component.html',
  styleUrls: ['./new-bussiness.component.css']
})
export class NewBussinessComponent implements OnInit {
  myForm;
  partnerID;
  info;
  file: File;
  @ViewChild("inputFile") inputFile: any;
  loading: boolean = false;
  labels: any = ['todas'];
  allLabels: string[] = ['automóviles', 'restaurantes', 'todas', 'electronicos', 'servicios', 'salud', 'turismo', 'belleza', 'kid', 'comida rápida', 'panaderias', 'ropa', 'café', 'finanzas', 'gimnasio', 'hogar', 'helados', 'seguros', 'joyas', 'licor', 'cine', 'opticas', 'parqueo', 'mascotas', 'educación', 'zapaterias'];
  filteredLabels: Observable<string[]>;
  searchableLabels: any;
  separatorKeysCodes: number[] = [COMMA];
  @ViewChild('labelsInput') labelInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public businessService: BusinessService,
    public location: Location,
    public generalService: GeneralService,/* 
    public dialogRef: MatDialogRef<NewBussinessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, */
    public formBuilder: FormBuilder,
    public storageService: StorageService,
    private route: ActivatedRoute,
  ) {
    /*     this.info = data;
        console.log(data); */
    this.myForm = this.formBuilder.group({
      'name': new FormControl('', [
        Validators.required,
      ]),
      'icon': new FormControl('', [
        Validators.required,
      ]),
      'description': new FormControl('', [
        Validators.required,
      ]),
      'labels': new FormControl('', [
      ]),
      'website': new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
        )
      ]),
    });


  }

  handleFiles(e, file) {
    this.generalService.fileToData64(e.srcElement.files[0], (data64) => {
      file.value = '';
      this.getImage(data64);
      this.loading = false;
    }, (err) => {
      file.value = '';

      /*       this.translate.get(err.error || 'ERR_PROCESS').subscribe(
              value => {
                this.generalProvider.presentToast(value);
              }
            ) */
      this.loading = false;
    });
  }

  async getImage(image) {
    /*     if(this.request && this.request.image){
          delete this.request.image;
        } */
    this.myForm.get('icon').setValue(image);
  };

  editPhoto(file) {
    file.click();
  }

  async autofillData() {
    if (this.partnerID != null) {
      this.loading = true;
      const business: any = await this.businessService.getBusinessData(this.partnerID);

      this.myForm.get('name').setValue(business.name);
      this.myForm.get('description').setValue(business.description);
      this.myForm.get('icon').setValue(business.icon);
      this.myForm.get('website').setValue(business.website);
      this.labels = (business.labels) || ['todas'];

      console.log(this.labels);
    }
    this.filteredLabels = this.myForm.get('labels').valueChanges.pipe(
      startWith(null),
      map((label: string | null) => label ? this._filter(label) : this.searchableLabels.slice()));

    this.filterSearchableLabels();
    this.loading = false;
  }

  ngOnInit() {
    this.partnerID = this.route.snapshot.paramMap.get('partnerID');
    console.log(this.partnerID);
    this.autofillData();
  }

  addLabel(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.labels.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.myForm.get('labels').setValue(null);
    }
  }

  removeLabel(label: string): void {
    if (label != "todas") {
      const index = this.labels.indexOf(label);

      if (index >= 0) {
        this.labels.splice(index, 1);
      }
      this.filterSearchableLabels();
    }
  }

  selectedLabel(event: MatAutocompleteSelectedEvent): void {
    this.labels.push(event.option.viewValue);
    this.labelInput.nativeElement.value = '';
    this.myForm.get('labels').setValue(null);
    this.filterSearchableLabels();
  }

  filterSearchableLabels() {
    this.searchableLabels = this.allLabels.filter((elem) => {
      return this.labels.indexOf(elem) == -1;
    });
  }

  private _filter(value: string): string[] {
    console.log('searching');

    const filterValue = value.toLowerCase();
    return this.searchableLabels.filter(label => label.toLowerCase().indexOf(filterValue) != -1);
  }

  async add() {
    this.loading = true;
    let data: any = {
      name: this.myForm.get('name').value,
      website: this.myForm.get('website').value,
      description: this.myForm.get('description').value,
      labels: this.labels
    };

    const key = this.partnerID || (await this.businessService.saveBusiness({})).id;
    console.log((this.myForm.get('icon').value).startsWith('http'));
    if ((this.myForm.get('icon').value).startsWith('http') == false) {
      const downloadURL = await this.storageService.uploadFile('partners/' + key, this.myForm.get('icon').value);
      data.icon = downloadURL;
    }
    await this.businessService.updateBusiness(key, data);
    await this.businessService.createBusinessSearchIndex(key, data.name);

    try {
      this.location.back();
    } catch (e) {
      console.log(e);
    }

  }

}
