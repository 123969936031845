import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'app-auth-partner',
  templateUrl: './auth-partner.component.html',
  styleUrls: ['./auth-partner.component.css']
})
export class AuthPartnerComponent implements OnInit {
  email: string;
  password: string;
  loading: boolean = false;
  error: any;
  returnUrl: string;
  formLogin: any;
  loginParams: any;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.formLogin = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]),
      'password': new FormControl('', [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {
    this.authService.logout(() => {
      localStorage.clear();
    }, (err) => {

    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/partner';


  }

  login() {

    this.loading = true;

    this.authService.login(
      this.formLogin.get('email').value,
      this.formLogin.get('password').value
    ).then(user => {
      this.loading = false;

      this.router.navigateByUrl(this.returnUrl);

    }).catch(err => {
      console.log(err);
      this.error = err;
      this.loading = false;
    });
  }

  register() {
    this.router.navigateByUrl('/signIn');
  }

}
