import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { UsersService } from 'app/services/users/users.service';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { NitValidator } from 'app/validators/nit';
import { PhoneValidator } from 'app/validators/phone';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  userID: string; 
  userData: any;
  myForm: FormGroup;
  loading: boolean = true;
  imageURL: string = "assets/placeholder_profile.jpg";

  constructor(
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    private usersService: UsersService,
    public formBuilder: FormBuilder,
  ) { 
    this.userID = params.userID;

    this.myForm = this.formBuilder.group({
      'first_name': new FormControl('', [
        Validators.required,
      ]),
      'last_name': new FormControl('', [
        Validators.required,
      ]),
      'address': new FormControl('', [
        Validators.required,
      ]),
      'birth': new FormControl('', [
        Validators.required,
        PhoneValidator.overEighteen,
      ]),
      'genre': new FormControl('', [
        Validators.required,
      ]),
      'nit': new FormControl('', [
        NitValidator.invalidNit,
      ]),
      'dial_code': new FormControl('', [
        Validators.required,
        PhoneValidator.invalidDialCode,
      ]),
      'phone_number': new FormControl('', [
        Validators.required,
        PhoneValidator.invalidPhoneWithoutDial,
      ]),
    });
  }

  ngOnInit() {
    this.loadInitialData();
  }

  async loadInitialData(){
    this.loading = true;
    this.userData = await this.usersService.getUserMasterDocRef(this.userID).get().toPromise().then(doc => doc.data());
    this.loading = false;
    
    this.imageURL = (this.userData.img_url == null || this.userData.img_url == "")? "assets/placeholder_profile.jpg" : this.userData.img_url;
    this.myForm.get('first_name').setValue(this.userData.first_name);
    this.myForm.get('last_name').setValue(this.userData.last_name);
    this.myForm.get('address').setValue(this.userData.address);
    this.myForm.get('birth').setValue(this.userData.birth.toDate());
    this.myForm.get('genre').setValue(this.userData.genre);
    this.myForm.get('nit').setValue(this.userData.nit);
    this.myForm.get('dial_code').setValue(this.userData.phone['dial_code']);
    this.myForm.get('phone_number').setValue(this.userData.phone['number']);
  }

  close(){
    this.dialogRef.close();
  }


  async saveChanges(){

    this.loading = true;
    console.log(`BirthDate: ${this.myForm.get('birth').value.date}`);
    await this.usersService.getUserMasterDocRef(this.userID).update({
      first_name: this.myForm.get('first_name').value,
      last_name: this.myForm.get('last_name').value,
      address: this.myForm.get('address').value,
      birth: this.myForm.get('birth').value,
      genre: this.myForm.get('genre').value,
      nit: this.myForm.get('nit').value,
      phone: {
        dial_code: this.myForm.get('dial_code').value,
        number: this.myForm.get('phone_number').value,
      }
    });
    this.loading = false;

    this.close();
  }

  
}
