import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegalService } from 'app/services/legal.service';
import { MatDialog } from '@angular/material';
import { EditSectionComponent } from './edit-section-content/edit-section-content.component';
import { LegalSubsectionComponent } from './legal-subsection/legal-subsection.component';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';

@Component({
  selector: 'app-section-detail',
  templateUrl: './section-detail.component.html',
  styleUrls: ['./section-detail.component.css']
})
export class SectionDetailComponent implements OnInit {

  type: string;
  sectionID: string;
  index: number;
  updatedSubsectionIndexes: boolean = false;
  loadingMainContent: boolean = true;
  loadingSubsections: boolean = true; 

  contentSubscription: any;
  subsectionsSubscription: any;

  sectionData: any = {};
  subsections = [];
  currentSubsectionIndex = 0;


  constructor(
    private legalService: LegalService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) { 
  }

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type');
    this.sectionID = this.route.snapshot.paramMap.get('sectionID');
    this.index = Number(this.route.snapshot.paramMap.get('index'));

    this.listenToSection();
  }

  ngOnDestroy(){
    if(this.contentSubscription){
      this.contentSubscription.unsubscribe();
    }

    if(this.subsectionsSubscription){
      this.subsectionsSubscription.unsubscribe();
    }
  }

  listenToSection(){
    this.loadingMainContent = true;
    this.loadingSubsections = true;

    this.contentSubscription = this.legalService.getSectionDoc(this.type, this.sectionID).valueChanges().subscribe((data) => {
      this.sectionData = data;
      this.sectionData.index = this.index;
      this.loadingMainContent = false;
    });

    this.subsectionsSubscription  = this.legalService.getSubsections(this.sectionID, this.type).valueChanges({idField: 'id'}).subscribe((data) => {
      this.subsections = data;

      if(!this.updatedSubsectionIndexes){
        this.updatedSubsectionIndexes = true;
        this.legalService.updateSubindexes(this.sectionID, this.subsections, this.type);
      }

      this.currentSubsectionIndex = this.subsections.length;
      this.loadingSubsections = false;
    });
  }

  editSectionContent(){
    this.dialog.open(EditSectionComponent, {
      data: {
        sectionID: this.sectionID,
        title: this.sectionData.title,
        content: this.sectionData.content,
        index: this.sectionData.index,
        type: this.type,
      },    

      maxWidth  : '95vw',
      maxHeight: '95vh',
    });
  }

  editSubsection(subsection){
    this.dialog.open(LegalSubsectionComponent, {
      data: {
        sectionID: this.sectionID,
        subsectionID: subsection.id,
        index: this.sectionData.index,
        subindex: subsection.index,
        type: this.type,
        content: subsection.content,
      },    

      maxWidth  : '95vw',
      maxHeight: '95vh',
    });
  }

  newSubsection(){
    this.dialog.open(LegalSubsectionComponent, {
      data: {
        sectionID: this.sectionID,
        subsectionID: null,
        index: this.sectionData.index,
        subindex: this.currentSubsectionIndex + 1,
        type: this.type,
        content: null,
      },    

      maxWidth  : '95vw',
      maxHeight: '95vh',
    });
  }

  async showConfirmationDialog(element, message){
    this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Eliminar sección",
        body: message,
        cancel: "Cancelar",
        ok: "Eliminar",
        action: ( async () => {
          await this.legalService.deleteSubsection(this.sectionID, element.id, this.type);
          this.updatedSubsectionIndexes = false;
        }),
      },

      maxWidth  : '400px',
      maxHeight: '95vh',
    });

  }

  deleteSubsection(element){
    this.showConfirmationDialog(element, '¿Estás seguro de eliminar este inciso? Una vez elminado no podrás recuperarlo.');
  }
}
