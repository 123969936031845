import { Component, OnInit, NgZone } from '@angular/core';
declare var google: any;
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { log } from 'util';
declare var jQuery: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {


  service: any;
  geocoder: any;
  tripSearch: any;
  searchRequest: any;
  type: string;
  startDate: any;
  autoCompleteItems = [];
  minDate: any;
  resultsStyle: any;
  loading: any;
  loadingLocation = false;
  openSearch = false;
  isWeb;
  benefits;
  carouselOne;
  carouselTileOne;

  ngOnInit() {

    jQuery('.gallery-slide').owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 4000,
      smartSpeed: 1000,
      navText: ['<i class="lnr lnr-chevron-left"></i>', '<i class="lnr lnr-chevron-right"></i>'],
      responsive: {
          0: {
              items: 1,
          },
          600: {
              items: 2
          },
          1280: {
              items: 3
          },
          1500: {
              items: 4
          }
      }
  });
  /* testimonials Slider Active
  =============================*/
  jQuery('.team-slide').owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      nav: true,
      autoplay: true,
      autoplayTimeout: 4000,
      smartSpeed: 1000,
      navText: ['<i class="lnr lnr-chevron-left"></i>', '<i class="lnr lnr-chevron-right"></i>'],
      responsive: {
          0: {
              items: 1,
          },
          600: {
              items: 2
          },
          1000: {
              items: 3
          }
      }
  });

    this.benefits = {
      1: { title: "Asistencia", text: "Obten asistencia de los bomberos o policia en cualquier momento" },
      2: { title: "Carpooling", text: "Comparte tu vehiculo" },
      3: { title: "GPS", text: "Comparte tu ubicación para estar seguro" },
      4: { title: "Seguro", text: "Con el resguardo de aseguradoras" },
      5: { title: "Tickets", text: "Podras cambiarlos por dinero real" },
      6: { title: "Tokens", text: "Paga con dinero virtual" },
      7: { title: "Amigos", text: "Viaja con tus amigos de facebook" },
      8: { title: "Alianzas", text: "Aprovecha los descuentos en las empresas afiliadas" }
    }
    this.carouselOne =
      {

        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 4,
        speed: 500,
        interval: 5000,
        point: {
          visible: true,
          pointStyles: `
              .ngxcarouselPoint {
                list-style-type: none;
                text-align: center;
                padding: 12px;
                margin: 0;
                white-space: nowrap;
                overflow: auto;
                position: absolute;
                width: 100%;
                bottom: 20px;
                left: 0;
                box-sizing: border-box;
              }
              .ngxcarouselPoint li {
                display: inline-block;
                border-radius: 999px;
                background: rgba(255, 255, 255, 0.55);
                padding: 5px;
                margin: 0 3px;
                transition: .4s ease all;
              }
              .ngxcarouselPoint li.active {
                  background: white;
                  width: 10px;
              }
            `
        },
        load: 2,
        custom: 'banner',
        touch: true,
        loop: true,
        easing: 'cubic-bezier(0, 0, 0.2, 1)'
      };
    this.carouselTileOne = {
      grid: { xs: 1, sm: 2, md: 3, lg: 4, all: 0 },
      speed: 600,
      interval: 3000,
      point: {
        visible: true,
        pointStyles: `
          .ngxcarouselPoint {
            list-style-type: none;
            text-align: center;
            padding: 12px;
            margin: 0;
            white-space: nowrap;
            overflow: auto;
            box-sizing: border-box;
          }
          .ngxcarouselPoint li {
            display: inline-block;
            border-radius: 50%;
            background: #6b6b6b;
            padding: 5px;
            margin: 0 3px;
            transition: .4s;
          }
          .ngxcarouselPoint li.active {
              border: 2px solid rgba(0, 0, 0, 0.55);
              transform: scale(1.2);
              background: transparent;
            }
        `
      },
      load: 2,
      loop: true,
      touch: true,
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
      animation: 'lazy'
    };

  }

  constructor(
    private zone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.resultsStyle = { top: 0 };
    this.loading = false;
    this.tripSearch = { origin: '', destination: '' };
    this.searchRequest = { origin: [], destination: [], date: undefined };
    this.minDate = moment().add(5, 'minutes').toDate();
    console.log(this.minDate);
    this.loadMap();
    this.activatedRoute.params.pipe().subscribe((params)=>{
      console.log(params);
    });
  }

  ionViewCanLeave(): boolean {
    if (this.openSearch) {
      this.closeSearch();
      return false;
    }
    return true;
  }
  loadMap() {
    this.service = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();
  }

  ionViewDidLoad() {
  }

  getLocation() {
    this.loadingLocation = true;

    // this.locationProvider.getLocation((resp) => {

    //   this.zone.run(() => {
    //     this.loadingLocation = false;

    //     if (resp.coords.latitude) {
    //       this.type = 'origin';
    //       this.chooseItem(undefined,
    //         { lat: resp.coords.latitude, lng: resp.coords.longitude }
    //       );
    //     } else {
    //       this.generalProvider.presentToast('Imposible obtener ubicación');
    //     }
    //   })
    // }, (error) => {
    //   this.loadingLocation = false;

    //   this.generalProvider.presentToast('Imposible obtener ubicación');
    //   console.log('Error getting location', error);
    // });
  }

  searchTrip() {
    this.searchRequest['date'] = this.tripSearch.date;

    // this.navCtrl.push('SearchResultsPage', {
    //   origin: this.tripSearch['origin'],
    //   destination: this.tripSearch['destination'],
    //   date: moment(this.searchRequest.date).format("YYYY-MM-DD"),
    //   originlat: this.searchRequest.origin[0],
    //   originlng: this.searchRequest.origin[1],
    //   destinationlat: this.searchRequest.destination[0],
    //   destinationlng: this.searchRequest.destination[1],
    // });
    //    window.location.href = ('https://app.traeguate.gt/#/'+moment(this.searchRequest.date).format("YYYY-MM-DD")+"/"+ this.searchRequest.origin[0]+"/"+ this.searchRequest.origin[1]+"/"+this.searchRequest.destination[0]+"/"+ this.searchRequest.destination[1]);
    window.location.href = ('https://app.traeguate.gt/#/search/' + moment(this.searchRequest.date).format("YYYY-MM-DD") + "/" + this.searchRequest.origin[0] + "/" + this.searchRequest.origin[1] + "/" + this.searchRequest.destination[0] + "/" + this.searchRequest.destination[1]);
  }

  closeSearch() {
    this.openSearch = false;
    this.tripSearch[this.type] = '';
    this.searchRequest[this.type] = undefined;
  }

  initSearch(type) {
    this.tripSearch[type] = '';
    this.updateSearch(type);
  }

  updateSearch(type) {
    this.openSearch = true;
    this.loading = true;
    this.type = type;
    if (!this.tripSearch[type] || this.tripSearch[type] == '') {
      this.loading = false;
      this.autoCompleteItems = [];
      return;
    }
    this.service.getPlacePredictions(
      {
        input: this.tripSearch[type],
        componentRestrictions: { country: 'GT' }
      },
      (predictions, status) => {
        this.loading = false;
        this.autoCompleteItems = [];
        this.zone.run(() => {
          if (predictions) {
            predictions.forEach((prediction) => {
              this.autoCompleteItems.push(prediction);
            });
          }
        });
      });
  }

  getTime() {
    // this.dateTime.getTime('date', new Date(this.tripSearch.date), (date) => {
    //   console.log(date);

    //   this.tripSearch.date = moment(date).toISOString();
    // });
  }

  chooseItem(item: any, latlng) {
    this.openSearch = false;

    this.autoCompleteItems = [];
    let search = { params: {}, name: false };
    if (latlng) {
      search.params = { 'location': latlng };
      this.tripSearch[this.type] = 'Buscando ubicación...';
    } else {
      search.params = { 'address': item.description };
      search.name = item.description;
      this.tripSearch[this.type] = 'Cargando...';
    }
    let type = this.type;
    this.geocoder.geocode(search.params, (results, status) => {
      this.zone.run(() => {

        if (status === 'OK') {
          if (latlng) {
            search.name = results[0].formatted_address;
            this.tripSearch[type] = results[0].formatted_address;
          } else {
            this.tripSearch[type] = search.name;
          }
          this.searchRequest[type] = [results[0].geometry.location.lat(), results[0].geometry.location.lng()];
        }
      });
    });
  }

  ionViewCanEnter() {
    return true;
  }



}
