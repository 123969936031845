import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BillService } from '../../../../services/bill.service';
import { GeneralService } from '../../../../services/general.service';
import { HistoryService } from '../../../../services/history.service';
import { AuthService } from '../../../../services/auth.service';
import { NitValidator } from '../../../../validators/nit'

@Component({
  selector: 'app-bill-detail',
  templateUrl: './bill-detail.component.html',
  styleUrls: ['./bill-detail.component.css']
})
export class BillDetailComponent implements OnInit {

  billTransaction: any;
  billAnnulment: any;
  loading: boolean = false;
  isAnnulment: boolean = false;
  error: String = null;
  myForm;
  resolutions;
  transaction;
  billId: string;

  constructor(
    public formBuilder: FormBuilder,
    public generalService: GeneralService,
    public historyService: HistoryService,
    public authService: AuthService,
    public billService: BillService,
    public dialogRef: MatDialogRef<BillDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.transaction = data.transaction;
    this.billId = data.billId;
    this.resolutions = this.billService.getResolutions();  
  }
 
  close(created: boolean){
    this.dialogRef.close(created); 
  }

  ngOnInit() {
    this.getTransaction();
  }

  async getTransaction(){
    this.billTransaction = await this.billService.getBillTransaction(this.billId);
    console.log(this.billTransaction['title']);

    if (this.billTransaction.extra_data.annulment_id) {
          this.isAnnulment = true;
          this.billAnnulment = await this.billService.getBillTransaction(this.billTransaction.extra_data.annulment_id);
    } else {
      this.isAnnulment = false;
    }
  }

  async downloadBill(key, bill) {
    try{
      this.loading = true;
      this.error = null;
      let response = await this.billService.getBill(bill, key).toPromise().then(res => res);
      
      if (response.status == 200) {
        this.generalService.printPdf('factura_traeguate.pdf', response['_body']);
      } else {
        this.error = 'No se ha podido descargar la factura';
      }
    }catch (e){
      console.error(e);
      this.error = e.message;
    } finally{
      this.loading = false;
    }
  }

  async downloadCreditNote(key, bill) {
    console.log('descargando nota de credito');
  }

  async cancelBill(key, bill) {
    try{
      this.error = null;
      this.loading = true;
      console.log(' ---> cancelBill');
      console.log(key);
      console.log(this.billId);
      //console.log(this.transaction.bill)
      let response = await this.billService.cancelBill(bill, key, this.transaction.user);
      if (response['status'] == 200) {
        console.log('Success');
        if (response['response']['extra_data']['response_code'] == 0) {
          console.log('Anulacion exitosa.');
          this.getTransaction();
        } else {
          console.log('Esta transacción ya fue anulada o ya no existe.');
          this.error = 'Esta transacción ya fue anulada o ya no existe.';
        }
      } else { 
        this.error = 'No se ha podido anular la factura, por favor intenta mas tarde.';
        console.log('Error');
        console.log('No se ha podido anular la factura, por favor intenta mas tarde.');
      }

    }catch (e){
      this.error = e.message;
      console.error(e);
    } finally{
      this.loading = false;
    }
  }
}
