import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descountOffer'
})
export class DescountOfferPipe implements PipeTransform {

  transform(value: any): any {
    let output = [];

    if (value) {
      value.forEach(element => {
        if (element.offer_type == "static_discount") {
          output.push(element.prices.quetzales - element.promo_price);
        }
        else if (element.offer_type == 'dinamic_discount') {
          output.push(element.prices.quetzales - (element.regular_price - (element.regular_price * (element.percentage / 100))));
        }
      });
    }
    return output;
  }

}
