import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogOkCancelComponent } from '../../../general/dialog-ok-cancel/dialog-ok-cancel.component';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  user: any;
  constructor(
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }
  
  logout() {
    this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Cerrar Sesión",
        body: "Esta seguro que desea cerrar sesión?",
        ok: "Aceptar",
        action: (() => {
          this.authService.logout(() => {
            this.router.navigateByUrl("/admin");
          }, (err) => {
            console.log(err);
          });
        }),
        cancel: "Cancelar",
      }
    });

    /*     dialogRef.afterClosed().subscribe(result => {
          console.log(result);
        }); */
  }
}
