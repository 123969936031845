import { Component, OnInit } from '@angular/core';
import { LegalService } from 'app/services/legal.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-legal-version-detail',
  templateUrl: './legal-version-detail.component.html',
  styleUrls: ['./legal-version-detail.component.css']
})
export class LegalVersionDetailComponent implements OnInit {

  loading: boolean = true;
  versionID: string;
  version: any = {};
  termsSections = [];
  policiesSections = [];


  constructor(   
    private legalService: LegalService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.versionID = this.route.snapshot.paramMap.get('versionID');
    this.loadVersion();
  }

  async loadVersion(){
    this.loading = true;
    this.version = await this.legalService.getVersion(this.versionID);
    this.termsSections = this.version.terms;
    this.policiesSections = this.version.policies;
    this.loading = false;
  }

}
