import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'environments/environment';
import * as firebase from 'firebase';
import { take } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  user;
  ip;
  transaction_id;


  constructor(
    public af: AngularFireDatabase,
    public afs: AngularFirestore,
  ) {
  }

  userTickets(userId) {
    return firebase.database().ref('/users/' + userId).child('tickets');
  }

  userRealTickets(userId) {
    return firebase.database().ref('/users/' + userId).child('realTickets');
  }

  updateUserTokens(transaction, userID, tokensToAdd) {
    return new Promise(Resolve => {
      this.af.object('users/' + userID + '/tickets').query.ref.transaction((currentTokens) => {
        console.log("run update tokens transaction");
        if (!currentTokens) {
          currentTokens = 0;
        }
        const newValue = Math.round((currentTokens + tokensToAdd) * 100) / 100;
        console.log(newValue);
        if (newValue > 0) {
          return newValue;
        }
        return currentTokens;
      }, (error, committed, snapshot) => {
        if (error) {
          console.log('The transaction fail', error);
          return Resolve(false);
        } else if (!committed) {
          console.log('The transaction is not committed');
          return Resolve(false);
        } else {
          this.af.list('transactions').push({ ...transaction, time: firebase.database.ServerValue.TIMESTAMP });
          return Resolve(true);
          console.log('User Tokens operate successfully');
        }
        console.log("New Tokens value: ", snapshot.val());
      });
    })
  }

  referenceVisanetTransaction() {
    return new Promise(Resolve => {
      this.af.object('data/visanet_transaction_reference_counter').query.ref.transaction((currentValue) => {
        if (!currentValue) {
          currentValue = 0;
        }
        return currentValue + 1;
      }, (error, committed, snapshot) => {
        if (error) {
          console.log('The transaction fail', error);
          return Resolve(false);
        } else if (!committed) {
          console.log('The transaction is not committed');
          return Resolve(false);
        } else {
          return Resolve(snapshot.val());
          console.log('User Tokens operate successfully');
        }
      });
    })
  }

  async GTQtoTokens(GTQ) {
    const exchange: number = await this.af.object('data/exchange_tokens').valueChanges().pipe(take(1)).toPromise().then((data: any) => data)
    const tokens = Math.round(GTQ * exchange * 100) / 100;
    return { tokens: tokens, GTQ: GTQ, exchange: exchange };
  }

  async TokensToGTQ(Tokens) {
    const exchange: number = await this.af.object('data/exchange_tokens').valueChanges().pipe(take(1)).toPromise().then((data: any) => data)
    const GTQ = Math.round(Tokens / exchange * 100) / 100;
    return { tokens: Tokens, GTQ: GTQ, exchange: exchange };
  }

  async GTQtoTickets(GTQ) {
    const exchange: number = await this.af.object('data/exchange_tickets').valueChanges().pipe(take(1)).toPromise().then((data: any) => data)
    const tickets = Math.round(GTQ * exchange * 100) / 100;
    return { tickets: tickets, GTQ: GTQ, exchange: exchange };
  }

  async TicketsToGTQ(Tickets) {
    const exchange: number = await this.af.object('data/exchange_tickets').valueChanges().pipe(take(1)).toPromise().then((data: any) => data)
    const GTQ = Math.round(Tickets / exchange * 100) / 100;
    return { tickets: Tickets, GTQ: GTQ, exchange: exchange };
  }


  updateUserTickets(transaction, userID, ticketsToAdd) {
    console.log(transaction, userID, ticketsToAdd);
    console.log(this.af.object('users/' + userID + '/realTickets').valueChanges().pipe(take(1)).toPromise().then((data) => data));

    return new Promise(Resolve => {
      this.af.object('users/' + userID + '/realTickets').query.ref.transaction((currentTickets) => {
        console.log(currentTickets);
        if (!currentTickets) {
          currentTickets = 0;
        }
        const newValue = Math.round((currentTickets + ticketsToAdd) * 100) / 100;
        console.log(currentTickets, newValue);
        if (newValue > 0) {
          return newValue;
        }
        return currentTickets;
      }, (error, committed, snapshot) => {
        if (error) {
          console.log('The transaction fail', error);
          return Resolve(false);
        } else if (!committed) {
          console.log('The transaction is not committed');
          return Resolve(false);
        } else {
          this.af.list('transactions').push({ ...transaction, time: firebase.database.ServerValue.TIMESTAMP });
          return Resolve(true);
          console.log('User Tickets operate successfully');
        }
        console.log("New Tickets value: ", snapshot.val());
      },);
    })
  }
  
  updateInstitutionTokens(transaction, institutionID,  tokensToAdd: number) {
    let institutionRef = this.afs.firestore.collection('business').doc(institutionID);

    return this.afs.firestore.runTransaction(transaction => {
      return transaction.get(institutionRef).then(doc => {

        let tokens: Number;
        if (!doc.data().tokens) {
          tokens = tokensToAdd;
        } else {
          tokens = doc.data().tokens + tokensToAdd;
        }

        transaction.update(institutionRef, { tokens: tokens });
        return tokens
      });
    }).then(success => {
      
      this.af.list('transactions').push({ ...transaction, time: firebase.database.ServerValue.TIMESTAMP });
      console.log("Transaction succeed, current tokens:", success);
      return true;
    })
    .catch(error => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  updateInstitutionTickets(transaction, institutionID,  ticketsToAdd: number) {
    let institutionRef = this.afs.firestore.collection('business').doc(institutionID);

    return this.afs.firestore.runTransaction(transaction => {
      return transaction.get(institutionRef).then(doc => {

        let tickets: Number;
        if (!doc.data().tickets) {
          tickets = ticketsToAdd;
        } else {
          tickets = doc.data().tickets + ticketsToAdd;
        }

        transaction.update(institutionRef, { tickets: tickets });
        return tickets
      });
    }).then(success => {
      
      this.af.list('transactions').push({ ...transaction, time: firebase.database.ServerValue.TIMESTAMP });
      console.log("Transaction succeed, current tickets:", success);
      return true;
    })
    .catch(error => {
      console.log("Transaction failed: ", error);
      return false;
    });
  }

  /* NEW PROVIDER */
  getTransactionById(id) {
    /*     var ref = firebase.database().ref("/history/" + id);
        ref.on('value', snapshot => {
          success(snapshot.val());
        }); */
    return this.af.object("/transactions/" + id);
  }

  getTransactionsByUserID(uid) {
    console.log('filtering');
    return this.af.list('transactions/', ref => ref.orderByChild('user').equalTo(uid));
  }
}
