import { Component, OnInit, ViewChild } from '@angular/core';
import { InfoDialogComponent } from 'app/general/info-dialog/info-dialog.component';
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatDialog,
} from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { PaymentsService } from 'app/services/payments.service';
import { CustomerService } from 'app/services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'app/services/notifications.service';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { saveAs } from 'file-saver';
import { TransactionsService } from 'app/services/transactions/transactions.service';

@Component({
  selector: 'app-batch-detail',
  templateUrl: './batch-detail.component.html',
  styleUrls: ['./batch-detail.component.css'],
})
export class BatchDetailComponent implements OnInit {
  batchID;
  batchData;
  bankList = [];
  paymentRequests = [];

  loading: boolean = true;

  depositStatuses = [
    { value: 'successful', viewValue: 'Realizado' },
    { value: 'unsuccessful', viewValue: 'Fallado' },
  ];

  dataSourceBatchRequests = new MatTableDataSource<{}>();
  displayedColumnsBatchRequests: string[] = [
    'accountName',
    'accountType',
    'accountNumber',
    'createdAt',
    'amount',
    'amountISR',
    'amountToTransfer',
    'status',
  ];

  @ViewChild('batchRequestsSort') batchRequestsSort: MatSort;
  @ViewChild('batchRequestsPaginator') batchRequestsPaginator: MatPaginator;

  constructor(
    public paymentsService: PaymentsService,
    public customerService: CustomerService,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private transactionsService: TransactionsService
  ) {}

  ngOnInit() {
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;
    this.batchID = this.route.snapshot.paramMap.get('batchID');

    let result = await Promise.all([
      this.paymentsService.getBatchData(this.batchID),
      this.paymentsService.getBankList(),
    ]);

    this.batchData = result[0];
    this.bankList = result[1];

    console.log('Batch: ', this.batchData);

    this.loadBatchRequests();
    this.loading = false;
  }

  doBatchRequestsFilter(value: string) {
    this.dataSourceBatchRequests.filter = value.trim().toLocaleLowerCase();

    if (this.dataSourceBatchRequests.paginator) {
      this.dataSourceBatchRequests.paginator.firstPage();
    }
  }

  async loadBatchRequests() {
    this.loading = true;
    this.paymentRequests = await this.paymentsService.getPaymentRequestsOfBatch(
      this.batchID
    );
    this.dataSourceBatchRequests.data = this.paymentRequests;
    this.dataSourceBatchRequests.paginator = this.batchRequestsPaginator;
    this.dataSourceBatchRequests.sort = this.batchRequestsSort;

    console.log('requested Payments: ', this.paymentRequests);
    this.loading = false;
  }

  async setDepositStatus(index, status) {
    this.loading = true;
    await this.paymentsService.updatePaymentDepositStatus(
      this.paymentRequests[index].key,
      status
    );
    this.paymentRequests[index]['deposit_status'] = status;
    this.loading = false;
  }

  validatePayments() {
    let notDepositStatus = this.paymentRequests.filter(
      (payment) => payment.deposit_status === undefined
    );
    return notDepositStatus.length === 0;
  }

  getSuccessfulPaymentsOfSatus(status) {
    return this.paymentRequests.filter(
      (payment) => payment.deposit_status === status
    );
  }

  async finishBatch() {
    if (this.validatePayments()) {
      this.showConfirmationDialog('¿Está seguro de finalizar el lote?');
    } else {
      this.showInfoDialog(
        'No ha seleccionado el estado de depósito de todas las solicitudes del lote.'
      );
    }
  }

  async showInfoDialog(message) {
    await this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Finalización de lote',
        body: message,
        ok: 'OK',
      },
    });
  }

  async showConfirmationDialog(message) {
    await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: 'Finalización de lote',
        body: message,
        cancel: 'Cancelar',
        ok: 'Finalizar',
        action: async () => {
          this.loading = true;
          try {
            console.log('requests: ', this.paymentRequests);
            const response = await this.paymentsService.operatePaymentBatch(
              this.batchID,
              this.paymentRequests
            );
            // const response = await this.paymentsService.closePaymentBatch(
            //   this.batchID
            // );
            console.log('Batch response: ', response);

            if (response) {
              this.batchData.executed = true;
              this.showInfoDialog('Se ha finalizado el lote exitosamente.');
            } else {
              this.showInfoDialog('Ha ocurrido un error.');
            }
          } catch (e) {
            this.showInfoDialog('Ha ocurrido un error.');
            console.error(e);
          }

          this.loading = false;
        },
      },
    });
  }

  async downloadAddAccountsToBI() {
    let payments = this.paymentRequests;
    let txt = [];
    payments.forEach((payment) => {
      if (payment['bankID'] == 4) {
        txt.push(
          payment['accountType'] +
            ',' +
            payment['accountNumber'] +
            ',' +
            payment['batchID'] +
            payment['accountNumber'] +
            ',' +
            '9999,' +
            '1'
        );
      }
    });

    let txtArray = txt.join('\r\n');
    var blob = new Blob([txtArray], { type: 'text/plain' });
    saveAs(blob, 'AddAccountsToBI-' + this.batchID + '.txt');
  }

  async downloadAddAccountsToACH() {
    let payments = this.paymentRequests;
    let txt = [];
    payments.forEach((payment) => {
      if (payment['bankID'] != 4) {
        txt.push(
          payment['accountName'] +
            ',' +
            this.bankList[payment['bankID'] - 1].codes.GTQ +
            ',' +
            payment['accountType'] +
            ',' +
            payment['accountNumber'] +
            ',' +
            payment['amountToTransfer']
        );
      }
    });

    let txtArray = txt.join('\r\n');
    var blob = new Blob([txtArray], { type: 'text/plain' });
    saveAs(blob, 'AddAccountsToACH-' + this.batchID + '.txt');
  }

  async downloadTransfersFromBItoBI() {
    let payments = this.paymentRequests;
    let txt = [];
    payments.forEach((payment) => {
      if (payment['bankID'] == 4) {
        txt.push(
          payment['accountType'] +
            ',' +
            payment['accountNumber'] +
            ',' +
            payment['accountName'] +
            ',' +
            payment['amountToTransfer'] +
            ',' +
            'Canje de Tickets'
        );
      }
    });

    let txtArray = txt.join('\r\n');
    var blob = new Blob([txtArray], { type: 'text/plain' });
    saveAs(blob, 'TransfersFromBItoBI-' + this.batchID + '.txt');
  }

  async downloadTransfersFromBItoACH() {
    let payments = this.paymentRequests;
    let txt = [];
    let count = 0;
    payments.forEach((payment) => {
      if (payment['bankID'] != 4) {
        count++;
        txt.push(
          this.zeroPad(count, 5) +
            ',' +
            payment['accountName'] +
            ',' +
            this.bankList[payment['bankID'] - 1].codes.GTQ +
            ',' +
            payment['accountNumber'] +
            ',' +
            payment['accountType'] +
            ',' +
            payment['amountToTransfer']
        );
      }
    });

    let txtArray = txt.join('\r\n');
    var blob = new Blob([txtArray], { type: 'text/plain' });
    saveAs(blob, 'TransfersFromBItoBI-' + this.batchID + '.txt');
  }

  zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }
}
