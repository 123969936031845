import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBank'
})
export class FilterBankPipe implements PipeTransform {

  transform(value: any, users?: any, bank?: any, type?: any): any {

    let output = [];

    if (type == 'users') {
      if (value && users && bank) {
        value.forEach(element => {
          try {
            if (users[element.driver].account && users[element.driver].account.bank == bank && element.status != 'finish') {
              output.push(element);
            }
          } catch (error) {
            
          }

        });
      }
    }
    else if (type == 'business') {
      if (value && users && bank) {
        value.forEach(element => {
          try{
            if (users[element.company].account && users[element.company].account.bank == bank && element.status != 'finish') {
              output.push(element);
            }
          }catch(error){
            console.log(error);
            
          }

        });
      }
    }
    return output;
  }


}
