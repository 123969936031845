import { Component, OnInit } from '@angular/core';
import { BusinessService } from 'app/services/business/business.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'app/services/storage.service';
import { MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { KeysPipe } from 'ngx-pipes';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.css']
})
export class BusinessDetailComponent implements OnInit {

  partnerID;
  partner;
  loaded:boolean = true;

  constructor(
    private route: ActivatedRoute,
    public businessService: BusinessService,
    public storageService: StorageService,
    public dialog: MatDialog,
    public location: Location,
    public formBuilder: FormBuilder,
  ) {

  }

  ngOnInit() {
    this.partnerID = this.route.snapshot.paramMap.get('partnerID');
    this.loadData();
  }

  async loadData() {
    this.loaded = false;
    this.partner = await this.businessService.getBusinessData(this.partnerID);
    this.loaded = true;
  }

  async showDeleteModal(businessID) {
    await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Eliminar empresa",
        body: "¿Está seguro que desea eliminar esta empresa?",
        ok: "Aceptar",
        action: (async () => {
          this.loaded = false;

          await this.businessService.deleteBusiness(businessID);
          
          this.location.back();
        }),
        cancel: "Cancelar",
      }
    });
  }
}
