import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { ProductService } from 'app/services/product.service';
import { EventsService } from 'app/services/events/events.service';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BusinessAccountModel } from 'app/models/business-account.model';


@Component({
  selector: 'app-events-overview',
  templateUrl: './events-overview.component.html',
  styleUrls: ['./events-overview.component.css']
})
export class EventsOverviewComponent implements OnInit {
   
  partner;
  refs;
  partnerEventsData = [];
  userID;
  loaded: boolean = true;
  lastSelectedPartner: string;
  traeguateID: string;

  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  constructor(
    public authService: AuthService,
    public productService: ProductService,
    public eventsService: EventsService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.traeguateID = this.route.snapshot.paramMap.get('traeguateID');
    this.userID = (await this.authService.getAuth()).uid;
    console.log(this.traeguateID);

    if(this.traeguateID){
      this.loaded = false;
      this.partnerEventsData = await this.eventsService.getEventsListData(this.traeguateID);
      this.loaded = true;
      console.log(this.partnerEventsData);
    } else {

      this.businessAccount$.subscribe((document) => {
        if (document.lastPartnerSelected) {
          this.lastSelectedPartner = (document.lastPartnerSelected);
          this.getPartnerEventsData();    
        }
      });
    }
  }

  async getPartnerEventsData(){
    this.loaded = false;
    this.partnerEventsData = await this.eventsService.getEventsListData(this.lastSelectedPartner);
    this.loaded = true;
    console.log(this.partnerEventsData);
  }

}
