import { Injectable } from '@angular/core';
import {AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';
import { take, map } from 'rxjs/operators';

@Injectable()
export class CustomerService {

  ref: any;
  list: any;

  constructor(public db: AngularFireDatabase) { }

  getList(callback) {
    this.db.object('/users').snapshotChanges().pipe(take(1)).pipe(
      map(c => 
        ({ key: c.payload.key, ...c.payload.val() })      )
    ).subscribe(snapshot => {
      callback(snapshot);
    });
  }

 /*  getPartnersObject(callback){
    this.db.object('benefits/')
  } */

  getUsersRef(){
    return this.db.list('/users/');
  }
  getById(id: string) {
    return this.db.object('/users/' + id);
  }


  updateUser(id, data, success, err) {
    this.db.object("users/" + id).update(data).then((data) => {
      success(data);
    }, (error) => {
      err(error);
    });
  }

  getUsersList(callback) {
    this.db.list('users/').snapshotChanges().pipe(take(1)).pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(queriedItems => {
      callback(queriedItems);
    });
  }

}



