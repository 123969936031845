import { ApplicationRef } from '@angular/core';
import { Action, State, StateContext, Store, NgxsOnInit } from '@ngxs/store';

import { BusinessAccountModel } from 'app/models/business-account.model';
import { RequestBusinessAccountAttempt, ChangeSelectedBusiness } from './business-account.actions';
import { PartnerService } from 'app/services/partner/partner.service';

@State<BusinessAccountModel>({
    name: 'businessAccount',
    defaults: {
        key: '',
        displayName: '',
        email: '',
        phone: ''
    }
})

export class BusinessAccountState implements NgxsOnInit {
    constructor(
        private store: Store,
        private ref: ApplicationRef,
        private partnerService: PartnerService
    ) {

    }

    /*     @Selector()
        static getCategories(state: BusinessAccountModel) {
            return state.displayName;
        }
    
        @Selector()
        static getCategory(state: BusinessAccountModel, categoryId: string) {
            return state.displayName;
        } */

    ngxsOnInit(sc: StateContext<BusinessAccountModel>) {
        //sc.dispatch(new LoadCatgories());
    }

    @Action(RequestBusinessAccountAttempt)
    async RequestBusinessAccountAttempt(
        stateContext: StateContext<BusinessAccountModel>,
        action: RequestBusinessAccountAttempt
    ) {
        console.log('load categories');
        const businessAccountEmail = await this.partnerService.getPartnerData(action.businessAccountEmail);
        if (
            !businessAccountEmail.lastPartnerSelected &&
            businessAccountEmail.businessList &&
            businessAccountEmail.businessList[0]
        ) {
            businessAccountEmail.lastPartnerSelected = businessAccountEmail.businessList[0];
        }
        stateContext.patchState(businessAccountEmail);
    }


    @Action(ChangeSelectedBusiness)
    async ChangeSelectedBusiness(
        stateContext: StateContext<BusinessAccountModel>,
        action: ChangeSelectedBusiness
    ) {
        stateContext.patchState({ lastPartnerSelected: action.businessID });
    }
}