/* import { BusinessAccountModel } from '../models/business-account.model'
 */
export class RequestBusinessAccountAttempt {
    static readonly type = '[BusinessAccount] Request Attempt';
    constructor(public businessAccountEmail: string) {}
}

export class ChangeSelectedBusiness {
    static readonly type = '[BusinessAccount] Change Selected Business';
    constructor(public businessID: string) {}
}

/* 
export class RequestBusinessAccountSuccess {
    static readonly type = '[BusinessAccount] Request Success';
    constructor(public businessAccount: BusinessAccountModel) {}
}

export class RequestBusinessAccountFailure {
    static readonly type = '[BusinessAccount] Request Failure';
    constructor(public error: any) {}
} */