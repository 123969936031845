import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PhoneValidator } from 'app/validators/phone';
import { BusinessService } from 'app/services/business/business.service';
import { PartnerService } from 'app/services/partner/partner.service';

@Component({
  selector: 'app-complete-user-data',
  templateUrl: './complete-user-data.component.html',
  styleUrls: ['./complete-user-data.component.css']
})
export class CompleteUserDataComponent implements OnInit {
  loading: boolean = false;
  error: any;
  returnUrl: string;
  form: any;

  constructor(
    public authService: AuthService,
    public businessService: BusinessService,
    public partnerService: PartnerService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.form = new FormGroup({
      'name': new FormControl('', [
        Validators.required
      ]),
      'phone': new FormControl('+502', [
        Validators.required,
        PhoneValidator.invalidPhone
      ]),
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/partner/offers';
  }

  async continue() {
    this.form.get('phone').setValue(this.form.get('phone').value.split(' ').join(''));

    this.loading = true;
    this.partnerService.updatePartnerData(
      (await this.authService.getAuth()).email,
      {
        displayName: this.form.get('name').value,
        phone: this.form.get('phone').value
      }
    ).then(() => {

      this.router.navigateByUrl(this.returnUrl);

    }).catch(err => {
      console.log(err);
      this.error = err;
      this.loading = false;
    });
  }

}
