import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog } from '@angular/material';
import { BusinessService } from 'app/services/business/business.service';
import { AuthService } from 'app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { UserTypeService } from 'app/services/user-type.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BusinessAccountModel } from 'app/models/business-account.model';

@Component({
  selector: 'app-user-types',
  templateUrl: './user-types.component.html',
  styleUrls: ['./user-types.component.css']
})
export class UserTypesComponent implements OnInit {
  dataSource = new MatTableDataSource<{}>();
  displayedColumns: string[] = ['name', 'options'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  partnerID;
  loading: boolean = false;
  types: any;
  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  constructor(
    private userTypeService: UserTypeService,
    private businessService: BusinessService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

 /*    const lastSelectedPartner = this.route.snapshot.paramMap.get('partnerID') || await this.authService.getSelectedPartner();

    this.loadRolData(lastSelectedPartner);
    this.authService.onPartnerSelectedChange.subscribe(async (businessID) => {
      this.loadRolData(this.route.snapshot.paramMap.get('partnerID') || businessID);

    }); */ 

    this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.loadRolData(document.lastPartnerSelected);
      }
    });
  }

  async loadRolData(partnerID) {
    this.loading = true;

    this.partnerID = partnerID;

    const data = (await this.businessService.getBusinessData(this.partnerID).then()).rol;
    this.types = Object.keys(data || []).map((id: any) => ({ ...data[id], key: id }));

    console.log(this.types);
    this.dataSource.data = ([{ 'name': 'Super Administrador', 'key': 'superadmin' }]).concat(this.types);

    this.loading = false;

  }

  async showDeleteModal(rolID) {
    await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Remover Rol",
        body: "¿Está seguro que desea remover este rol?",
        ok: "Aceptar",
        action: (async () => {
          this.loading = true;
          await this.userTypeService.removeRol(this.partnerID, rolID);

          await this.loadData();
        }),
        cancel: "Cancelar",
      }
    });
  }
}
