import { Routes } from '@angular/router';

import { AboutUsComponent } from './components/public/about-us/about-us.component';
import { ArticleComponent } from './components/public/article/article.component';
import { BlogComponent } from './components/public/blog/blog.component';
import { CarouselComponent } from './components/public/carousel/carousel.component';
import { CarpoolingComponent } from './components/public/information/carpooling.component';
import { ContactUsComponent } from './components/public/contact-us/contact-us.component';
import { CookiesComponent } from './components/public/information/cookies.component';
import { CGUComponent } from './components/public/information/cgu.component';
import { FAQComponent } from './components/public/faq/faq.component';
import { FooterComponent } from './components/public/footer/footer.component';
import { HeaderComponent } from './components/public/header/header.component';
import { HomePageComponent } from './components/public/home-page/home-page.component';
import { PrivacyComponent } from './components/public/information/privacy.component';
import { GeneralComponent } from './components/public/general/general.component';
import { NotFoundComponent } from './components/public/not-found/not-found.component'
import { RecoverPasswordComponent } from './components/public/recover-password/recover-password.component'
import { EmploymentComponent } from './components/public/employment/employment.component'

import { LoginComponent } from './components/dashboard/login/login.component';
import { PrincipalComponent } from './components/dashboard/principal/principal.component';
import { CustomerComponent } from './components/dashboard/customer/customer.component';
import { AssistComponent } from './components/dashboard/assist/assist.component';
import { ArticlesComponent } from './components/dashboard/articles/articles.component';
import { ConfiabilityComponent } from './components/dashboard/confiability/confiability.component';
import { PaymentsComponent } from './components/dashboard/payments/payments.component';
import { BusinessPaymentsComponent } from './components/dashboard/business-payments/business-payments.component';

import { BenefitComponent } from './components/partner/benefit/benefit.component';
import { EventsComponent } from './components/partner/events/events.component';
import { EventDetailComponent } from './components/partner/events/event-detail/event-detail.component';
import { PartnerComponent } from './components/partner/partner/partner.component';

import { AuthGuard } from './guards/auth/auth.guard';
import { HistoryComponent } from 'app/components/partner/history/history.component';
import { ProductComponent } from 'app/components/partner/product/product.component';
import { PartnersComponent } from './components/dashboard/partners/partners.component';
import { PartnerGuard } from './guards/Partner/partner.guard';
import { AuthPartnerComponent } from './components/partner/auth-partner/auth-partner.component';
import { StatisticsComponent } from './components/dashboard/statistics/statistics.component';
import { BillsComponent } from './components/dashboard/bills/bills.component';
import { TransactionsComponent } from './components/dashboard/transactions/transactions.component';
import { RegisterComponent } from './components/partner/register/register.component';
import { NewOfferComponent } from './components/partner/offers/new-offer/new-offer.component';
import { OffersOverviewComponent } from './components/partner/offers/offers-overview/offers-overview.component';
import { OfferDetailComponent } from './components/partner/offers/offer-detail/offer-detail.component';
import { ExchangeOfferComponent } from './components/partner/offers/exchange-offer/exchange-offer.component';
import { NewBussinessComponent } from './components/dashboard/partners/new-bussiness/new-bussiness.component';
import { InviteUsersComponent } from './components/general/invite-users/invite-users.component';
import { BusinessDetailComponent } from './components/dashboard/partners/business-detail/business-detail.component';
import { CompleteUserDataComponent } from './components/general/complete-user-data/complete-user-data.component';
import { EventsOverviewComponent } from './components/partner/events/events-overview/events-overview.component';
import { NewEventComponent } from './components/partner/events/new-event/new-event.component';
import { NewTypeComponent } from './components/general/invite-users/user-types/new-type/new-type.component';
import { DetailTypeComponent } from './components/general/invite-users/user-types/detail-type/detail-type.component';
import { BatchDetailComponent } from './components/dashboard/payments/batch-detail/batch-detail.component';
import { BalanceComponent } from './components/partner/balance/balance.component';
import { BalanceOverviewComponent } from './components/partner/balance/balance-overview/balance-overview.component';
import { BalancePaymentComponent } from './components/partner/balance/balance-payment/balance-payment.component';
import { LegalComponent } from './components/dashboard/legal/legal.component';
import { NewLegalVersionComponent } from './components/dashboard/legal/new-legal-version/new-legal-version.component';
import { SectionDetailComponent } from './components/dashboard/legal/new-legal-version/section-detail/section-detail.component';
import { PublishNewLegalVersionComponent } from './components/dashboard/legal/new-legal-version/publish-new-legal-version/publish-new-legal-version.component';
import { LegalVersionDetailComponent } from './components/dashboard/legal/legal-version-detail/legal-version-detail.component';
import { PublicLegalComponent } from './components/public/public-legal/public-legal.component';


export const routes: Routes = [
  {
    path: 'recuperar',
    component: RecoverPasswordComponent
  },
  {
    path: 'legal/:type',
    component: PublicLegalComponent,
  },
  {
    path: '',
    component: GeneralComponent,
    children: [
      {
        path: '',
        component: HomePageComponent
      }, {
        path: 'blog',
        component: BlogComponent
      },
      {
        path: 'blog/:number',
        component: ArticleComponent
      },
      {
        path: 'CGU',
        component: CGUComponent
      },
      // {
      //   path: 'privacidad',
      //   component: PrivacyComponent
      // },
      {
        path: 'cookies',
        component: CookiesComponent
      },
      {
        path: 'carpooling',
        component: CarpoolingComponent
      },
      {
        path: 'nosotros',
        component: AboutUsComponent
      },
      {
        path: 'FAQ',
        component: FAQComponent
      },
      {
        path: 'contacto',
        component: ContactUsComponent
      }, {
        path: 'employment',
        component: EmploymentComponent
      },
    ]
  },
  {
    path: 'login',
    component: AuthPartnerComponent
  },
  {
    path: 'signIn',
    component: RegisterComponent
  },
  {
    path: 'complete',
    component: CompleteUserDataComponent
  },
  {
    path: 'admin',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: PrincipalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'partners',
        children: [
          {
            path: '',
            component: PartnersComponent
          },
          /*           {
                      path: 'new-partner',
                      component: NewPartnerComponent
                    }, */
          {
            path: 'new-business',
            component: NewBussinessComponent
          },
          {
            path: ':partnerID',
            component: BusinessDetailComponent,
          },
          {
            path: 'offers/:partnerID',
            children: [              

              {
                path: ':offerID/edit',
                component: NewOfferComponent,
              },
              {
                path: 'new-offer',
                component: NewOfferComponent,
              },
              {
                path: ':offerID',
                component: OfferDetailComponent,
              },
              {
                path: '',
                component: OffersOverviewComponent,
              }
            ]
          },
          {
            path: 'edit/:partnerID',
            component: NewBussinessComponent
          },
          {
            path: 'invite/:partnerID',
            children: [
              {
                path: 'new-rol',
                component: NewTypeComponent
              },
              {
                path: ':rolID',
                component: DetailTypeComponent
              },
              {
                path: 'edit-rol/:rolID',
                component: NewTypeComponent
              },
              {
                path: '',
                component: InviteUsersComponent,
              }
            ]
          }
        ]
      },
      {
        path: 'statistics',
        component: StatisticsComponent
      },
      {
        path: 'assistance',
        component: AssistComponent
      },
      {
        path: '',
        component: ConfiabilityComponent
      },
      {
        path: 'customers',
        component: CustomerComponent
      },
      {
        path: 'articles',
        component: ArticlesComponent
      },
      {
        path: 'confiability',
        component: ConfiabilityComponent
      },
      {
        path: 'assistance',
        component: AssistComponent
      },
      {
        path: 'payments',
        children: [
          {
            path: '',
            component: PaymentsComponent,
          },
          {
            path: 'batch-detail',
            component: BatchDetailComponent,
          }
        ]
      },
      {
        path: 'business-payments',
        component: BusinessPaymentsComponent
      },
      {
        path: 'bill',
        component: BillsComponent
      },
      {
        path: 'legal',
        children: [
          {
            path: '',
            component: LegalComponent,
          },
          {
            path: 'version-detail/:versionID',
            component: LegalVersionDetailComponent,
          },
          {
            path: 'new-version',
            children: [
              {
                path: '',
                component: NewLegalVersionComponent,
              },
              {
                path: 'section/:type/:sectionID/:index',
                component: SectionDetailComponent,
              },
              {
                path: 'publish',
                component: PublishNewLegalVersionComponent,
              }
            ]
          }
        ]
      },
      {
        path: 'transactions',
        component: TransactionsComponent
      },
      {
        path: 'events/:traeguateID',
        component: EventsComponent,
        children: [
          {
            path: '',
            component: EventsOverviewComponent,
          },
          {
            path: 'edit/:eventID',
            component: NewEventComponent,
          },
          {
            path: 'new-event',
            component: NewEventComponent,
          },
          {
            path: ':eventID',
            component: EventDetailComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'partner',
    component: PartnerComponent,
/*     data: { permission: ['exchange_offers', 'offers'] }, 
 */    
    children: [
      {
        path: 'balance',
        canActivate: [PartnerGuard],
        component: BalanceComponent,
        children: [
          {
            path: '',
            component: BalanceOverviewComponent,
          },
          {
            path: 'payment/:type',
            component: BalancePaymentComponent,
          },
        ],
      },
      {
        path: 'offers',
        canActivate: [PartnerGuard],
        data: { permission: ['exchange_offers', 'offers'] }, 
        children: [
          {
            path: '',
            component: OffersOverviewComponent,
          },
          {
            path: ':offerID/edit',
            component: NewOfferComponent,
          },
          {
            path: 'new-offer',
            component: NewOfferComponent,
          },
          {
            path: 'exchange-offer/:code',
            component: ExchangeOfferComponent,
          },
          {
            path: ':offerID',
            component: OfferDetailComponent,
          },
        ],

      },
      {
        path: 'users',
        canActivate: [PartnerGuard],
        data: { permission: ['users'] }, 
        children: [
          {
            path: 'new-rol',
            component: NewTypeComponent
          },
          {
            path: ':rolID',
            component: DetailTypeComponent
          },
          {
            path: 'edit-rol/:rolID',
            component: NewTypeComponent
          },
          {
            path: '',
            component: InviteUsersComponent,
          }
        ]
      },
      {
        path: 'events',
        canActivate: [PartnerGuard],
        data: { permission: ['events'] }, 
        component: EventsComponent,
        children: [
          {
            path: '',
            component: EventsOverviewComponent,
          },
          {
            path: 'edit/:eventID',
            component: NewEventComponent,
          },
          {
            path: 'new-event',
            component: NewEventComponent,
          },
          {
            path: ':eventID',
            component: EventDetailComponent,
          },
        ],
      },

      {
        path: 'history',
        component: HistoryComponent,
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

export function benefitGuard() {
  return true;
}