import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../../../services/article.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  list: any;
  constructor(
    public articleService:ArticleService
  ) {
    this.list = articleService.getArticles();
  }

  ngOnInit() {
  }

}
