import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfiabilityService } from 'app/services/confiability.service';

@Component({
  selector: 'app-detail-metric',
  templateUrl: './detail-metric.component.html',
  styleUrls: ['./detail-metric.component.css']
})
export class DetailMetricComponent implements OnInit {

  metric: any;
  userID: string;
  metricID: string;

  constructor(
    public dialogRef: MatDialogRef<DetailMetricComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    public confiabilityService: ConfiabilityService,
  ) {
    this.metric = params.metric;
    this.userID = params.userID;
    this.metricID = params.metricID;
  }

  ngOnInit() {
  }

  qualify(status: string) {
    this.confiabilityService.updateMetricStatus(this.userID, this.metricID, status).then(() => {     
      this.close();
    });
  }


  close(){
    this.dialogRef.close();
  }



}
