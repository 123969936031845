import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { PasswordValidator } from '../../../validators/password'

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  canShowForm: boolean;
  formRecover: FormGroup;
  code: any;
  loading: boolean = false;
  response: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public formBuilder: FormBuilder,

  ) {
    this.loading = true;
    this.code = this.activatedRoute.snapshot.queryParams['oobCode'];
    this.authService.canShowResetPasswordForm(this.code, (response) => {
      console.log(response);
      this.loading = false;      
      if (!(response.code === 'auth/invalid-action-code')) {
        this.canShowForm = true;
      } else {
        this.canShowForm = false;
      }
    }, (err) => {
      this.loading = false;            
      console.log(err);

    });
  }

  ngOnInit() {
    this.formRecover = this.formBuilder.group({
      'confirmPassword': new FormControl('', [
        Validators.required,
        /*         forbiddenNameValidator(/bob/i) // <-- Here's how you pass in the custom validator.
         */
      ]),
      'password': new FormControl('', [
        Validators.required,
      ]),
    },
      {
        validator: PasswordValidator.MatchPassword
      });
  }

  changePassword() {
    this.loading = true;
    this.authService.changePassword(this.code, this.formRecover.get('password').value, (response) => {
      this.loading = false;
      this.response = 'Has cambiado tu contraseña!';
    }, (err) => {
      this.loading = false;
      console.log(err);
    });
  }

}
