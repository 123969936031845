import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientType'
})
export class ClientTypePipe implements PipeTransform {

  transform(value: any): any {
    switch(value){
      case 'user': return 'Usuario'
      case 'business': return 'Negocio'
    }

    return 'Usuario';
  }

}
