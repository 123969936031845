import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoryService } from '../../../services/history.service';
import * as moment from 'moment';
import { CustomerService } from '../../../services/customer.service';
import { GeneralService } from '../../../services/general.service';
import { BillService } from '../../../services/bill.service';
import { CreateBillComponent } from './create-bill/create-bill.component';
import { MatDialog, MatTableDataSource, MatSnackBar} from '@angular/material';
import { FormControl } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { BillDetailComponent } from './bill-detail/bill-detail.component';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})
export class BillsComponent implements OnInit {

  panelType = new FormControl('1');
  selected: string = '1';
  searchKey: string = '';
  searchButtonClicked: boolean = false;

  pageSize = 10;
  loadingBillKeys: any = {};
  
  loadingTokenBills: boolean = false;
  tokensPurchaseBills = [];
  lastTokensBillDoc: any;
  tokensTotalPages = 1;
  tokensCurrentPage = 1;
  tokensMaxPageReached: boolean = false;
  dataSourceTokensPurchase = new MatTableDataSource<{}>();
  displayedColumnsTokensPurchase: string[] = ['owner_name', 'date', 'bill.serie', 'bill.count', 'total_Q'];

  loadingTicketBills: boolean = false;
  ticketsPaymentBills = [];
  lastTicketsBillDoc: any;
  ticketsTotalPages = 1;
  ticketsCurrentPage = 1;
  ticketsMaxPageReached: boolean = false;
  dataSourceTicketsPayment = new MatTableDataSource<{}>();
  displayedColumnsTicketsPayment: string[] = ['owner_name', 'date', 'bill.serie', 'bill.count', 'total_Q', 'options'];

  loadingCustomBills: boolean = false;
  customBills = [];
  lastCustomBillDoc: any;
  customTotalPages = 1;
  customCurrentPage = 1;
  customMaxPageReached: boolean = false;
  dataSourceCustomBills = new MatTableDataSource<{}>();
  displayedColumnsCustomBills: string[] = ['owner_name', 'date', 'bill.serie', 'bill.count', 'total_Q', 'options'];

  constructor(
    public generalService: GeneralService,
    public billService: BillService,
    public historyService: HistoryService,
    public customerService: CustomerService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.loadInitialTokenBills();
    this.loadInitialTicketBills();
    this.loadInitialCustomtBills();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  // Tokens purchase bill operations----------------------------
  async loadInitialTokenBills(){
    this.loadingTokenBills = true;
    this.tokensPurchaseBills = await this.billService.getBillsBatch('tokens_purchase', null, this.pageSize);
    this.lastTokensBillDoc = this.tokensPurchaseBills[this.tokensPurchaseBills.length - 1];
    this.dataSourceTokensPurchase.data = this.tokensPurchaseBills;
    this.loadingTokenBills = false;
  }

  
  async downloadBill(key, bill) {
    console.log(key, bill);
    var error;
    try{
      this.loadingBillKeys[key] = true;
      error = null;
      let response = await this.billService.getBill(bill, key).toPromise().then(res => res);
      
      if (response.status == 200) {
        this.generalService.printPdf('factura_traeguate.pdf', response['_body']);
      } else {
        error = 'No se ha podido descargar la factura';
      }
    }catch (e){
      console.error(e);
      error = e.message;
    } finally{
      this.loadingBillKeys[key] = false;
    }
  }

  loadPreviousTokenBills(){
    if(this.tokensCurrentPage > 1){
      this.tokensCurrentPage--;
      this.dataSourceTokensPurchase.data = this.tokensPurchaseBills.slice((this.tokensCurrentPage - 1) * this.pageSize, this.tokensCurrentPage * this.pageSize);
    }
  }

  async loadMoreTokenBills(){
    if(this.tokensCurrentPage === this.tokensTotalPages){
      this.loadingTokenBills = true;
      
      let newDocs;
      
      if (this.searchKey.length > 0 && this.searchButtonClicked) {
        if (this.tokensPurchaseBills != null && this.tokensPurchaseBills.length >= 10) {
          console.log('next searching');
          newDocs = await this.billService.searchBill(this.searchKey, this.selected, this.lastTokensBillDoc, this.pageSize);
        }
      } else {
        console.log('next normal');
        newDocs = await this.billService.getBillsBatch('tokens_purchase', this.lastTokensBillDoc, this.pageSize);
      }

      this.loadingTokenBills = false;

      if(newDocs.length != 0){
        this.tokensTotalPages++;
        this.tokensCurrentPage++;
  
        this.tokensPurchaseBills.push(...newDocs);
        this.dataSourceTokensPurchase.data = this.tokensPurchaseBills.slice(this.tokensPurchaseBills.length - newDocs.length, this.tokensPurchaseBills.length);
        this.lastTokensBillDoc = this.tokensPurchaseBills[this.tokensPurchaseBills.length - 1];

        if(newDocs.length < this.pageSize){
          this.tokensMaxPageReached = true;
        }
      } else {
        this.tokensMaxPageReached = true;
      }
    } else {
      this.tokensCurrentPage++;

      let lastIndex;
      let initialIndex;
      if(this.tokensCurrentPage * this.pageSize < this.tokensPurchaseBills.length){
        lastIndex = this.tokensCurrentPage * this.pageSize;
        initialIndex = lastIndex - this.pageSize;
      } else{
        lastIndex = this.tokensPurchaseBills.length;
        initialIndex =  lastIndex - (this.pageSize - (this.tokensCurrentPage * this.pageSize - lastIndex));
      }

      this.dataSourceTokensPurchase.data = this.tokensPurchaseBills.slice(initialIndex, lastIndex);
    }
  }


  //Tickets purchase bill operations ---------------------------------
  async loadInitialTicketBills(){
    this.loadingTicketBills = true;
    this.ticketsPaymentBills = await this.billService.getBillsBatch('tickets_payment', null, this.pageSize);
    this.lastTicketsBillDoc = this.ticketsPaymentBills[this.ticketsPaymentBills.length - 1];
    this.dataSourceTicketsPayment.data = this.ticketsPaymentBills;
    this.loadingTicketBills = false;
  }

  loadPreviousTicketBills(){
    if(this.ticketsCurrentPage > 1){
      this.ticketsCurrentPage--;
      this.dataSourceTicketsPayment.data = this.ticketsPaymentBills.slice((this.ticketsCurrentPage - 1) * this.pageSize, this.ticketsCurrentPage * this.pageSize);
    }
  }

  async loadMoreTicketBills(){
    if(this.ticketsCurrentPage === this.ticketsTotalPages){

      this.loadingTicketBills = true;
      let newDocs = await this.billService.getBillsBatch('tickets_payment', this.lastTicketsBillDoc, this.pageSize);
      this.loadingTicketBills = false;

      if(newDocs.length != 0){
        this.ticketsTotalPages++;
        this.ticketsCurrentPage++;
  
        this.ticketsPaymentBills.push(...newDocs);
        this.dataSourceTicketsPayment.data = this.ticketsPaymentBills.slice(this.ticketsPaymentBills.length - newDocs.length, this.ticketsPaymentBills.length);
        this.lastTokensBillDoc = this.ticketsPaymentBills[this.ticketsPaymentBills.length - 1];

        if(newDocs.length < this.pageSize){
          this.ticketsMaxPageReached = true;
        }
      } else {
        this.ticketsMaxPageReached = true;
      }
    } else {
      this.ticketsCurrentPage++;

      let lastIndex;
      let initialIndex;
      if(this.ticketsCurrentPage * this.pageSize < this.ticketsPaymentBills.length){
        lastIndex = this.ticketsCurrentPage * this.pageSize;
        initialIndex = lastIndex - this.pageSize;
      } else{
        lastIndex = this.ticketsPaymentBills.length;
        initialIndex =  lastIndex - (this.pageSize - (this.ticketsCurrentPage * this.pageSize - lastIndex));
      }

      this.dataSourceTicketsPayment.data = this.ticketsPaymentBills.slice(initialIndex, lastIndex);
    }
  }


   //Custom bill operations ---------------------------------
  async loadInitialCustomtBills(){
    this.customBills = [];
    this.lastCustomBillDoc = undefined;
    this.customTotalPages = 1;
    this.customCurrentPage = 1;
    this.customMaxPageReached = false;

    this.loadingCustomBills = true;
    this.customBills = await this.billService.getBillsBatch('custom_bill', null, this.pageSize);
    this.lastCustomBillDoc = this.customBills[this.customBills.length - 1];
    this.dataSourceCustomBills.data = this.customBills;
    this.loadingCustomBills = false;
  }

  loadPreviousCustomBills(){
    if(this.customCurrentPage > 1){
      this.customCurrentPage--;
      this.dataSourceCustomBills.data = this.customBills.slice((this.customCurrentPage - 1) * this.pageSize, this.customCurrentPage * this.pageSize);
    }
  }

  async loadMoreCustomBills(){
    if(this.customCurrentPage === this.customTotalPages){
      this.loadingCustomBills = true;
      let newDocs = await this.billService.getBillsBatch('custom_bill', this.lastTicketsBillDoc, this.pageSize);
      this.loadingCustomBills = false;

      if(newDocs.length != 0){
        this.customTotalPages++;
        this.customCurrentPage++;
  
        this.customBills.push(...newDocs);
        this.dataSourceTicketsPayment.data = this.customBills.slice(this.customBills.length - newDocs.length, this.customBills.length);
        this.lastTokensBillDoc = this.customBills[this.customBills.length - 1];

        if(newDocs.length < this.pageSize){
          this.customMaxPageReached = true;
        }
      } else {
        this.customMaxPageReached = true;
      }
    } else {
      this.customCurrentPage++;

      let lastIndex;
      let initialIndex;
      if(this.customCurrentPage * this.pageSize < this.customBills.length){
        lastIndex = this.customCurrentPage * this.pageSize;
        initialIndex = lastIndex - this.pageSize;
      } else{
        lastIndex = this.customBills.length;
        initialIndex =  lastIndex - (this.pageSize - (this.customCurrentPage * this.pageSize - lastIndex));
      }

      this.dataSourceTicketsPayment.data = this.customBills.slice(initialIndex, lastIndex);
    }
  }

  openDetail(transaction) {
    console.log(transaction.id);
    console.log(transaction.data().owner_name);
    let dialogRef = this.dialog.open(BillDetailComponent, {
      data: {
        transaction: transaction.data(),
        billId: transaction.id,
      },
      minWidth: '60vw',
      maxWidth: '90vw',
      maxHeight: '80vh',  
    });

    dialogRef.afterClosed().toPromise().then(created => {
      if(created){
        this.loadInitialCustomtBills();
      }
    });
  }

  createBill(){
    let dialogRef = this.dialog.open(CreateBillComponent, {
      maxWidth  : '95vw',
      minHeight: '95vh',
      maxHeight: 'none',
      height: '95vh', 
    });

    dialogRef.afterClosed().toPromise().then(created => {
      if(created){
        this.loadInitialCustomtBills();
      }
    });
  }

  async searchInvoice(){

    this.searchButtonClicked = true;
    this.loadingTokenBills = true;
    console.log(`searchType ${this.selected}`);
    let docs = await this.billService.searchBill(this.searchKey, this.selected, null, this.pageSize);
    
    this.tokensTotalPages = 1;
    this.tokensCurrentPage = 1;
    this.tokensMaxPageReached = false;

    this.tokensPurchaseBills = docs;
    this.lastTokensBillDoc = this.tokensPurchaseBills[this.tokensPurchaseBills.length - 1];
    this.dataSourceTokensPurchase.data = this.tokensPurchaseBills;
    this.loadingTokenBills = false;
  }
}
