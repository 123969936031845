import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OffersService } from 'app/services/offers.service';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { Select } from '@ngxs/store';
import { BusinessAccountModel } from 'app/models/business-account.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-offers-overview',
  templateUrl: './offers-overview.component.html',
  styleUrls: ['./offers-overview.component.css']
})

export class OffersOverviewComponent implements OnInit {
  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  userID: any;
  loaded: boolean = false;
  code: string = "";
  exchangeError: string = "";

  offers: any = [];
  dataSourceOffers = new MatTableDataSource<{}>();
  displayedColumnsOffers: string[] = ['title', 'description', 'type', 'status', 'options'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private offersService: OffersService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.userID = (await this.authService.getAuth()).uid;
    console.log(this.userID);
    console.log(this.userID.uid);
    this.dataSourceOffers.paginator = this.paginator;
    this.dataSourceOffers.sort = this.sort;
    /*     const lastSelectedPartner = this.route.snapshot.paramMap.get('partnerID') || await this.authService.getSelectedPartner();
        this.loadPartnerData(lastSelectedPartner);
        this.authService.onPartnerSelectedChange.subscribe(async (businessID) => {
          this.loadPartnerData(this.route.snapshot.paramMap.get('partnerID') || businessID);
        });

     */
    this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.loadPartnerData(document.lastPartnerSelected);
      }
    });

    this.listenToScanner();
  }

  async loadPartnerData(businessID) {
    this.loaded = false;

    this.offers = await this.offersService.getInstituionOffers(businessID);
    this.dataSourceOffers.data = this.offers;

    this.loaded = true;
  }

  doFilter(value: string) {
    this.dataSourceOffers.filter = value.trim().toLocaleLowerCase();
  }

  async showDeleteModal(offer) {
    let dialogRef = await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Eliminar oferta",
        body: "¿Está seguro que desea eliminar esta oferta?",
        ok: "Aceptar",
        action: (async () => {

          await this.offersService.deleteOffer(offer);
          await this.loadData();
          console.log('deleted offerID', offer.key);
        }),
        cancel: "Cancelar",
      }
    });
  }

  async verifyCode() {

    if (this.code) {

      let exchangedOffer = await this.offersService.getOfferExchange(this.code.trim());
      if (exchangedOffer['status'] === 'pending') {

        this.exchangeError = "";
        await this.offersService.updateOfferExchangeByJSON(this.code, {'vendor': this.userID});
        this.router.navigateByUrl("/partner/offers/exchange-offer/" + this.code.trim());

      } else if (!exchangedOffer['status']) {
        this.exchangeError = "El código ingresado no es válido."
      } else {
        this.exchangeError = "Este código ya ha sido canjeado."
      }
    } else {
      this.exchangeError = "Debe ingresar un código."
    }
  }

  listenToScanner() {
    console.log('listening...');
    this.offersService.listenForOfferScann(this.userID, response => {
      console.log('response', response);

      if (response && response.code != null) {
        this.code = response.code;
      }
    });
  }
}

