import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-successful-message',
  templateUrl: './successful-message.component.html',
  styleUrls: ['./successful-message.component.css']
})
export class EventSuccessComponent implements OnInit {

  info;
  
  constructor( 
    public dialogRef: MatDialogRef<EventSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
  ) {
    this.info = data;
    console.log(this.info);
  }

  ngOnInit() {

  }

  close(){
    this.dialogRef.close();
  }

}
