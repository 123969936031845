import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, map } from 'rxjs/operators';

@Injectable()
export class HistoryService {

  constructor(public db: AngularFireDatabase) { }

  getHistoryRef(){
    return this.db.list('history');
  }

  getTransactionsRef(){
    return this.db.list('transactions');
  }

  getHistory(success, error) {
    this.db.list('history').snapshotChanges().pipe(take(1)).pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(data => {
      success(data);
    }, err => {
      error(err);
    });
  }

  create(data, success, error) {
    this.db.list('history').push(data).then(data => {
      success(data);
    }, err => {
      error(err);
    });
  }

  historyByCompany(uid, success, error) {
    let alerts = this.db.list('history/',
    ref => ref.orderByChild('company').equalTo(uid));
    alerts.snapshotChanges().pipe(take(1)).pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(queriedItems => {
      success(queriedItems);
    }, err => {
      error(err);
    });
  }

}
