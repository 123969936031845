import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogOkCancelComponent } from '../../../general/dialog-ok-cancel/dialog-ok-cancel.component';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  user: any;
  constructor(
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.authService.userData((data) => {
      this.user = data;
      console.log('user data');
    }, () => {
      console.log('err');
    });
  }

  ngOnInit() {
  }

  logout() {
    let dialogRef = this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Cerrar Sesión",
        body: "Esta seguro que desea cerrar sesión?",
        ok: "Aceptar",
        action: (() => {
          this.authService.logout(() => {
            this.router.navigateByUrl("/admin");
          }, (err) => {
            console.log(err);
          });
        }),
        cancel: "Cancelar",
      }
    });

    /*     dialogRef.afterClosed()..subscribe(result => {
          console.log(result);
        }); */
  }
}
