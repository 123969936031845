import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listBy'
})
export class ListByPipe implements PipeTransform {

  transform(value: any, args?: any, search?: any): any {
    var output = [];
    if(search == 'all'){
      return value;
    }
    if (value) {
      for (let prop in value) {
        for (let i in value[prop].confiability) {
          console.log(i);
          
          if(search == i ){
            output.push(value[prop]);
          }
        }
      }
      console.log(output);
      
      return output;
    } 


  }
}
