import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genre'
})
export class GenrePipe implements PipeTransform {

  transform(value: any): any {
    switch(value){
      case 'male': return 'Masculino'
      case 'female': return 'Femenino'
    }

    return 'No definido';
  }
}
