import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { PaymentsService } from "app/services/payments.service";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { BusinessAccountModel } from "app/models/business-account.model";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { InfoDialogComponent } from "app/general/info-dialog/info-dialog.component";
import { NitValidator } from "app/validators/nit";
import { DialogOkCancelComponent } from "app/general/dialog-ok-cancel/dialog-ok-cancel.component";

@Component({
  selector: "app-balance-payment",
  templateUrl: "./balance-payment.component.html",
  styleUrls: ["./balance-payment.component.css"],
})
export class BalancePaymentComponent implements OnInit {
  type: any;
  businessSubscription;
  balanceSubscription;
  businessID;
  balance: any;
  myForm: FormGroup;
  bankList = [];
  selectedBankID: string;
  selectedAccountType;
  sendingRequest: boolean = false;
  loading: boolean = true;
  previousRequest;

  @Select((state) => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private paymentsService: PaymentsService,
    public formBuilder: FormBuilder
  ) {
    this.myForm = this.formBuilder.group({
      quantity: new FormControl("", [Validators.required, Validators.min(1)]),
      address: new FormControl("", [Validators.required]),
      nit: new FormControl("", [
        Validators.min(25),
        Validators.required,
        NitValidator.invalidNit,
      ]),
      bank: new FormControl("", [Validators.required]),
      accountType: new FormControl("", [Validators.required]),
      owner_name: new FormControl("", [Validators.required]),
      account_number: new FormControl("", [Validators.required]),
      responsability: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get("type");
    console.log(this.type);
    this.loadInitialData();
    this.loadBalance();
  }

  //Remember to close all types of subscriptions;
  ngOnDestroy() {
    if (this.balanceSubscription) {
      this.balanceSubscription.unsubscribe();
    }

    if (this.businessSubscription) {
      this.businessSubscription.unsubscribe();
    }
  }

  async loadInitialData() {
    this.myForm.get("responsability").setValue(false);
    this.bankList = await this.paymentsService.getBankList();
    console.log(this.bankList);
  }

  loadBalance() {
    this.businessSubscription = this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.businessID = document.lastPartnerSelected;

        if (this.balanceSubscription) {
          this.balanceSubscription.unsubscribe();
        }

        this.balanceSubscription = this.paymentsService
          .getBusinessBalanceDocRef(this.businessID)
          .valueChanges()
          .subscribe((balance) => {
            this.balance = balance;
            this.loading = false;

            if (this.balance.ticketsPaymentRequestID) {
              this.loadPreviousRequest();
            } else {
              this.myForm
                .get("quantity")
                .setValidators([
                  Validators.required,
                  Validators.min(1),
                  Validators.max(
                    this.type === "tickets"
                      ? this.balance.tickets
                      : this.balance.tokens
                  ),
                ]);
            }
          });
      }
    });
  }

  async loadPreviousRequest() {
    this.previousRequest = await this.paymentsService.getPayment(
      this.balance.ticketsPaymentRequestID
    );
    console.log("previouuus request", this.previousRequest);
  }

  setBank(bankID: string) {
    this.selectedBankID = bankID;
  }

  setAccountType(accountType: any) {
    this.myForm
      .get("account_number")
      .setValidators([
        Validators.required,
        Validators.pattern(accountType.regex),
      ]);

    this.selectedAccountType = accountType;
  }

  async requestPayment() {
    // const loader = await this.loadingCtrl.create();
    // await loader.present();
    if (!this.sendingRequest) {
      this.sendingRequest = true;

      let payment = {
        bankID: this.myForm.get("bank").value,
        quantity: this.myForm.get("quantity").value,
        value: this.myForm.get("accountType").value,
        owner_name: this.myForm.get("owner_name").value,
        account_number: this.myForm.get("account_number").value,
        nit: this.myForm.get("nit").value,
        address: this.myForm.get("address").value,
        businessID: this.businessID,
      };

      try {
        let transactionResponse = await this.paymentsService.requestBusinessPayment(
          payment,
          this.type
        );
        this.sendingRequest = false;
        console.log("Response", transactionResponse);
        if (transactionResponse.executed) {
          // await loader.dismiss();
          // this.showInfoDialog('Error', transactionResponse.message);
          window.history.back();
        } else {
          // await loader.dismiss();
          this.showInfoDialog("Error", transactionResponse.error);
        }
      } catch (e) {
        this.sendingRequest = false;
        this.showInfoDialog("Error", "Ha ocurrido un error de conexión");
      }
    }
  }

  async cancelRequest() {
    if (!this.sendingRequest) {
      this.sendingRequest = true;
      await this.dialog.open(DialogOkCancelComponent, {
        data: {
          title: "Cancelar solicitud",
          body:
            "¿Está seguro de cancelar esta solicitud? La cantidad será reintegrada en forma de tickets.",
          cancel: "No",
          ok: "Sí, cancelar",
          action: async () => {
            try {
              let transactionResponse = await this.paymentsService.cancelTicketsPaymentRequest(
                this.balance.ticketsPaymentRequestID
              );
              console.log("Response", transactionResponse);
              if (transactionResponse.executed) {
                this.previousRequest = null;
              } else {
                this.showInfoDialog(
                  "Error",
                  "No se ha podido cancelar la solicitud."
                );
              }
            } catch (e) {
              console.error(e);
            }
            this.sendingRequest = false;
          },
        },
      });
    }
  }

  async showInfoDialog(title, message) {
    await this.dialog.open(InfoDialogComponent, {
      data: {
        title: title,
        body: message,
        ok: "OK",
      },
    });
  }
}
