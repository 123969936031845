import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { KeysPipe } from 'ngx-pipes';
import { StorageService } from '../../../services/storage.service';
import { BusinessService } from 'app/services/business/business.service';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css'],
  providers: [
    KeysPipe
  ]
})
export class PartnersComponent implements OnInit {

  business: any = [];
  dataSourceBusiness = new MatTableDataSource<{}>();
  displayedColumnsBusiness: string[] = ['icon', 'name', 'labels', 'options'];
  loaded: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public businessService: BusinessService,
    public storageService: StorageService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public keyPipe: KeysPipe
  ) {

  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    //get all the Business published by an institution 
    this.loaded = false;
    this.business = await this.businessService.getBusiness();
    this.dataSourceBusiness.data = this.business;

    this.dataSourceBusiness.paginator = this.paginator;
    this.dataSourceBusiness.sort = this.sort;
    this.loaded = true;

  }

  doFilter(value: string) {
    this.dataSourceBusiness.filter = value.trim().toLocaleLowerCase();
  }

  async showDeleteModal(businessID) {
    await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Eliminar empresa",
        body: "¿Está seguro que desea eliminar esta empresa?",
        ok: "Aceptar",
        action: (async () => {
          this.loaded = false;

          await this.businessService.deleteBusiness(businessID);
          await this.loadData();
          this.loaded = true;
        }),
        cancel: "Cancelar",
      }
    });
  }

}
