import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { PaymentsService } from "../../../services/payments.service";
import { CustomerService } from "../../../services/customer.service";
import { log } from "util";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatSort,
  MatPaginator,
} from "@angular/material";
import { DialogComponent } from "../../../dialog/dialog.component";
import { SelectionModel } from "@angular/cdk/collections";
import { InfoDialogComponent } from "app/general/info-dialog/info-dialog.component";
import { Router } from "@angular/router";

import { saveAs } from "file-saver";
import { DialogOkCancelComponent } from "app/general/dialog-ok-cancel/dialog-ok-cancel.component";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
  history;
  users;
  banks;

  bankList = [];
  paymentRequests = [];
  successfulPayments = [];
  unsuccessfulPayments = [];
  paymentBatches = [];

  loading: boolean = true;

  okArray = [];
  errorArray = [];
  filtro1;
  bank = "bi";
  cargando = false;
  selectedIndex = 0;
  historyObject;

  dataSourceNewRequests = new MatTableDataSource<{}>();
  displayedColumnsNewRequests: string[] = [
    "select",
    "accountName",
    "client_type",
    "bank_name",
    "accountType",
    "accountNumber",
    "createdAt",
    "amount",
    "amountISR",
    "amountToTransfer",
  ];
  newRequestsSelection = new SelectionModel<{}>(true, []);
  @ViewChild("newRequestsSort") newRequestsSort: MatSort;
  @ViewChild("newRequestsPaginator") newRequestsPaginator: MatPaginator;

  dataSourcePendingBatches = new MatTableDataSource<{}>();
  // displayedColumnsPendingBatches: string[] = ['key', 'time', 'total', 'options'];
  displayedColumnsPendingBatches: string[] = [
    "key",
    "time",
    "totalAmount",
    "totalISR",
    "total",
  ];
  @ViewChild("pendingBatchesSort") pendingBatchesSort: MatSort;
  @ViewChild("pendingBatchesPaginator") pendingBatchesPaginator: MatPaginator;

  dataSourceFinishedBatches = new MatTableDataSource<{}>();
  // displayedColumnsFinishedBatches: string[] = ['key', 'time', 'total', 'options'];
  displayedColumnsFinishedBatches: string[] = [
    "key",
    "time",
    "totalAmount",
    "totalISR",
    "total",
    "unsuccessfulAmountToTransfer",
    "successfulAmountToTransfer",
  ];
  @ViewChild("finishedBatchesSort") finishedBatchesSort: MatSort;
  @ViewChild("finishedBatchesPaginator") finishedBatchesPaginator: MatPaginator;

  constructor(
    public paymentsService: PaymentsService,
    public customerService: CustomerService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit() {
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;
    this.bankList = await this.paymentsService.getBankList();

    this.loadPaymentRequests();
    this.loadPaymentBatches();

    console.log("Banks: ", this.bankList);

    this.loading = false;
  }

  doNewRequestsFilter(value: string) {
    this.dataSourceNewRequests.filter = value.trim().toLocaleLowerCase();

    if (this.dataSourceNewRequests.paginator) {
      this.dataSourceNewRequests.paginator.firstPage();
    }
  }

  doPendingBatchesFilter(value: string) {
    this.dataSourcePendingBatches.filter = value.trim().toLocaleLowerCase();

    if (this.dataSourcePendingBatches.paginator) {
      this.dataSourcePendingBatches.paginator.firstPage();
    }
  }

  doFinishedBatchesFilter(value: string) {
    this.dataSourceFinishedBatches.filter = value.trim().toLocaleLowerCase();

    if (this.dataSourceFinishedBatches.paginator) {
      this.dataSourceFinishedBatches.paginator.firstPage();
    }
  }

  async loadPaymentRequests() {
    this.loading = true;
    this.paymentRequests = await this.paymentsService.getPaymentRequests();
    this.dataSourceNewRequests.data = this.paymentRequests;
    this.dataSourceNewRequests.paginator = this.newRequestsPaginator;
    this.dataSourceNewRequests.sort = this.newRequestsSort;

    this.loading = false;
  }

  async loadPaymentBatches() {
    this.loading = true;
    this.paymentBatches = await this.paymentsService.getPaymentBatches();

    this.dataSourcePendingBatches.data = this.paymentBatches.filter(
      (batch) => !batch.executed
    );
    this.dataSourcePendingBatches.paginator = this.pendingBatchesPaginator;
    this.dataSourcePendingBatches.sort = this.pendingBatchesSort;

    this.dataSourceFinishedBatches.data = this.paymentBatches.filter(
      (batch) => batch.executed
    );
    this.dataSourceFinishedBatches.paginator = this.finishedBatchesPaginator;
    this.dataSourceFinishedBatches.sort = this.finishedBatchesSort;

    this.loading = false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.newRequestsSelection.selected.length;
    const numRows = this.dataSourceNewRequests.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.newRequestsSelection.clear()
      : this.dataSourceNewRequests.data.forEach((row) =>
          this.newRequestsSelection.select(row)
        );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.newRequestsSelection.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
  }

  goToBatchDetail(batchID) {
    this.router.navigate([
      "/dashboard/payments/batch-detail",
      {
        batchID: batchID,
      },
    ]);
  }

  async generateBatch() {
    //TODO: discuss the min length of batch
    console.log("this.newRequestsSelection.selected.length");
    console.log(this.newRequestsSelection.selected.length);
    if (this.newRequestsSelection.selected.length > 0) {
      this.showConfirmationDialog("¿Está seguro de crear este lote?");
    } else {
      this.showInfoDialog("Debe seleccionar solicitudes para generar el lote.");
    }
  }

  async showInfoDialog(message) {
    await this.dialog.open(InfoDialogComponent, {
      data: {
        title: "Creación de lote",
        body: message,
        ok: "OK",
      },
    });
  }

  async showConfirmationDialog(message) {
    await this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Creación de lote",
        body: message,
        cancel: "Cancelar",
        ok: "Crear",
        action: async () => {
          const wasGenerated = await this.paymentsService.generateBatch(
            this.newRequestsSelection.selected
          );

          if (wasGenerated) {
            this.showInfoDialog("¡Se ha creado el lote de pagos!");
          } else {
            this.showInfoDialog("No se ha podido crear el lote.");
          }

          this.newRequestsSelection.clear();
          this.loadPaymentRequests();
          this.loadPaymentBatches();
        },
      },
    });
  }

  async downloadBatch(element) {
    let payments = await this.paymentsService.getPaymentRequestsOfBatch(
      element.key
    );
    let csv = [];
    payments.forEach((payment) => {
      csv.push(
        payment["user"] +
          "," +
          payment["accountName"] +
          "," +
          this.bankList[payment["bankID"] - 1].codes.GTQ +
          "," +
          payment["accountNumber"] +
          "," +
          payment["accountType"] +
          "," +
          payment["amountToTransfer"]
      );
    });

    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, element.key + ".csv");
  }
}
