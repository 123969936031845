import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { LegalService } from 'app/services/legal.service';

@Component({
  selector: 'app-edit-section-content',
  templateUrl: './edit-section-content.component.html',
  styleUrls: ['./edit-section-content.component.css']
})
export class EditSectionComponent implements OnInit {

  form: FormGroup;
  submitted:boolean = false;
  sectionID;
  title;
  content;
  index;
  type;

  constructor(
    public legalService: LegalService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
  ) { 

    this.sectionID = params.sectionID;
    this.title = params.title;
    this.content = params.content;
    this.index = params.index;
    this.type = params.type;

    this.form = this.formBuilder.group({
      'content': new FormControl('', [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {
    this.form.get('content').setValue(this.content);
  }


  close(){
    this.dialogRef.close();
  }

  async updateSection(){
    this.submitted = true;

    if(this.form.valid){
      this.legalService.updateSection(
        this.sectionID,
        this.title,
        this.form.get('content').value,
        this.index,
        this.type,
      );

      this.close();
    }

  }


}
