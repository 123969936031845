import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public myPicture: string;
  private window: any = window;
  fragment;




  ngOnInit() {
  }
  constructor(private route: ActivatedRoute) {

    this.route.fragment.pipe().subscribe(fragment => {
      this.fragment = fragment;
    });
    jQuery(window).scroll(() => {
      this.fragment = null;

      var a = 100;
      var pos = jQuery(window).scrollTop();
      if (pos > a) {
        jQuery("nav").addClass("affix");
      }
      else {
        jQuery("nav").removeClass("affix");
      }
    });

  }

  hide(){
    jQuery("#primary_menu").collapse("hide");

  }

  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        document.querySelector('#' + this.fragment).scrollIntoView();
      }
    } catch (e) { }
  }
}
