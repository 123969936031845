import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BusinessService } from 'app/services/business/business.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private authService: AuthService,
        private businessService: BusinessService
    ) {

    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise(async Resolve => {
            const auth = (await this.authService.getAuth()); 
            if (auth && auth.uid) {
                return Resolve(true);
            }
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return Resolve(false);
        });
    }
}