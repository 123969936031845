import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";

import { ConfiabilityService } from "../../../../services/confiability.service";
import { CustomerService } from "../../../../services/customer.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { DetailMetricComponent } from "../detail-metric/detail-metric.component";
import { EditUserComponent } from "../../users/edit-user/edit-user.component";
import { BusinessService } from "app/services/business/business.service";
import { DpiValidatorService } from "../../../../services/dpi-validator.service";

@Component({
  selector: "app-detail-confiability",
  templateUrl: "./detail-confiability.component.html",
  styleUrls: ["./detail-confiability.component.css"],
})
export class DetailConfiabilityComponent implements OnInit {
  confiabilitySubscription: any;
  confiability: any;
  userID: string;
  texts = {};
  statuses = {};
  errors = {};
  selectedItem: any;
  imageURL: string = "assets/placeholder_profile.jpg";
  institutionalRequestsCounter: any = {};
  loading = false;
  loadingResponse = false;
  userVehicles = [];
  constructor(
    public dialogRef: MatDialogRef<DetailConfiabilityComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    public confiabilityService: ConfiabilityService,
    public customerService: CustomerService,
    public businessService: BusinessService,
    public notificationsService: NotificationsService,
    public dialog: MatDialog,
    public dpiValidatorService: DpiValidatorService
  ) {
    this.userID = params.userID;
  }

  ngOnInit() {
    console.log(this.userID);
    this.loading = true;
    this.confiabilitySubscription = this.confiabilityService
      .getConfiability(this.userID)
      .valueChanges()
      .pipe()
      .take(1)
      .subscribe(async (data: any) => {
        console.log(data);
        if (data && data.requests) {
          this.institutionalRequestsCounter = data.requests.institution || 0;
          delete data.requests.institution;

          this.confiability = await this.loadPendingInstitutionRequests(data);
          console.log("User conf: ", this.confiability);

          this.imageURL =
            this.confiability.user.img_url == null ||
            this.confiability.user.img_url == ""
              ? "assets/placeholder_profile.jpg"
              : this.confiability.user.img_url;
        }
        this.loading = false;
      });
    this.getVehicles();
  }

  /*
  
  accepted_invitations = 0;
  metricsRequests.requests.institution.pending_invitations = 0;
  metricsRequests.requests.institution.denied_invitations

  */

  async getVehicles() {
    const query = await this.confiabilityService.getVehiclesByUserID(
      this.userID
    );
    query.docs.forEach((vehicle) => {
      this.userVehicles.push(vehicle.data());
    });
    console.log(this.userVehicles);
  }

  async loadPendingInstitutionRequests(confiability) {
    if (
      this.institutionalRequestsCounter != null &&
      this.institutionalRequestsCounter.pending_invitations > 0
    ) {
      const response = await this.businessService.getInstitutionRequest(
        this.userID,
        "pending"
      );
      console.log(response);

      response.forEach((element) => {
        confiability.requests[element.key] = {
          img_url: element.identification,
          status: element.status,
          institution: element.institution,
          text:
            confiability.user.first_name +
            " " +
            confiability.user.last_name +
            " quiere ser parte de " +
            element.institution.name,
          type: "institution",
        };
      });
    }

    return confiability;
  }

  ngOnDestroy() {
    if (this.confiabilitySubscription) {
      this.confiabilitySubscription.unsubscribe();
    }
  }

  close() {
    this.dialogRef.close();
  }

  async qualify(metricID: string) {
    const comment: string = this.texts[metricID];
    const metric = this.confiability.requests[metricID];
    if (!this.dpiValidatorService.validateDPI(metric.number) && metricID == "dpi") {
      this.errors[metricID] = 'El DPI está con un formato inválido';
    } else if (!this.statuses[metricID]) {
      this.errors[metricID] = "Debe elegir una acción.";
    } else if (comment && !comment.trim()) {
      this.errors[metricID] =
        "No puedes enviar un comentario solo con espacios.";
    } else if (metric.type !== "institution" && !metric.number && metricID == "dpi") {
      this.errors[metricID] = "Debes escribir algo en el DPI.";
    } else {
      this.loadingResponse = true;
      delete this.errors[metricID];
      let status = this.statuses[metricID];
      //TODO: Colocar esto en una transaccion
      await this.confiabilityService.updateMetricStatus(
        this.userID,
        metricID,
        status,
        metric.number
      );


      const statusMessage = status === "accepted"
      ? "¡Tu solicitud ha sido aceptada!"
      : "¡Tu solicitud ha sido denegada!"

      const message = comment || statusMessage;

      this.notificationsService.createAlert(
        this.userID,
        "Confiabilidad",
        message,
        metricID,
        "/metric-detail"
      );

      this.confiability.requests[metricID].status = status;
      delete this.statuses[metricID];
      this.loadingResponse = false;
    }
  }

  async qualifyInstitutional(institutionRequestID: string) {
    if (this.statuses[institutionRequestID]) {
      this.loadingResponse = true;

      delete this.errors[institutionRequestID];
      let status = this.statuses[institutionRequestID];

      console.log(institutionRequestID);

      var response = await this.confiabilityService
        .updateInstitutionalStatus(
          this.userID,
          institutionRequestID,
          status,
          this.texts[institutionRequestID]
        )
        .then((response) => response);
      console.log(response);
      if (response) {
        let message: string;
        if (status === "accepted") {
          message = this.texts[institutionRequestID]
            ? this.texts[institutionRequestID]
            : "¡Tu solicitud ha sido aceptada!";
        } else if (status === "denied") {
          message = this.texts[institutionRequestID]
            ? this.texts[institutionRequestID]
            : "¡Tu solicitud ha sido denegada!";
        }
        //TODO: Meter alerta y update status en una transaccion
        this.notificationsService.createAlert(
          this.userID,
          "Confiabilidad",
          message,
          institutionRequestID,
          "/institution-status-screen"
        );

        console.log(institutionRequestID);

        this.confiability.requests[institutionRequestID].status = status;
        delete this.statuses[institutionRequestID];

        this.loadingResponse = false;
      }
    } else {
      this.errors[institutionRequestID] = "Debe elegir una acción.";
    }
  }

  selectItem(metricID: string) {
    if (metricID !== "biography") {
      if (this.selectedItem === metricID) {
        this.selectedItem = undefined;
      } else {
        this.selectedItem = metricID;
      }
    } else {
      this.openItem(metricID);
    }
  }

  openItem(metricID: string) {
    this.dialog.open(DetailMetricComponent, {
      data: {
        metricID: metricID,
        userID: this.userID,
        metric: this.confiability.requests[metricID],
      },

      minWidth: "400px",
      maxWidth: "90vw",
      maxHeight: "80vh",
    });
  }

  editUser() {
    this.dialog.open(EditUserComponent, {
      data: {
        userID: this.userID,
      },

      minWidth: "400px",
      maxWidth: "90vw",
      maxHeight: "80vh",
    });
  }
}
