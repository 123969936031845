import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'multiple'
})
export class MultiplePipe implements PipeTransform {

  transform(value: any, serverTime: any): any {
    var output = [];

    if (value && serverTime) {

      var date = moment().format('X');

      value.forEach((value) => {
        if (value.arrive < date) {
          let total;
          total = value.driver_payment / value.exchange;
          output.push(total);
        }
      });
    }

    return output;

  }

}
