import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';
import { Ng2ImgToolsService } from 'ng2-img-tools';

@Injectable()
export class GeneralService {

  constructor(
    private db: AngularFireDatabase,
    private ng2ImgToolsService: Ng2ImgToolsService,
  ) { }

  /*   generateFirebaseId(){
      return this.db.list('/items').push(null).key
    } */

  async serverTimestamp() {
    const snapshot = await this.db.database.ref('.info/serverTimeOffset').once('value');
    return new Date(Date.now() + snapshot.val());
  }

  getFirebaseServerTime() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  public hashCode(input: string) {
    let hash = 0, i, chr;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
      chr = input.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  printPdf(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  fileToData64(file, callback, err) {
    this.resizeFile([file], (resizedFile) => {
      this.compressFile([resizedFile], (compressFile) => {
        callback(compressFile);
      }, err);
    }, err);
  }

  resizeFile(files, success, err) {
    this.ng2ImgToolsService.resize(files, 500, 500, false).subscribe(result => {
      success(result);
    }, error => {
      console.log(error);
      if (
        error.error == 'FILE_BIGGER_THAN_INITIAL_FILE' ||
        error.error == 'MAX_STEPS_EXCEEDED'
      ) {
        success(error.compressedFile);
      } else {
        err(error);
      }
    });
  }

  compressFile(files, success, err) {
    console.log(files);
    this.ng2ImgToolsService.compress(files, 0.1, false, false).subscribe(result => {
      var reader = new FileReader();
      reader.onload = (e) => {
        success(reader.result);
      }
      reader.readAsDataURL(result);
    }, error => {
      console.log(error);
      if (
        error.error == 'FILE_BIGGER_THAN_INITIAL_FILE' ||
        error.error == 'MAX_STEPS_EXCEEDED'
      ) {
        var reader = new FileReader();
        reader.onload = (e) => {
          var binaryString = reader.result;
          success(binaryString);
        }
        reader.readAsDataURL(error.compressedFile);
      } else {
        err(error);
      }
    });
  }

}
