import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { MatDialog } from '@angular/material';
import { BenefitService } from 'app/services/benefit.service';
import { EventsService } from 'app/services/events/events.service';
import { ProductService } from 'app/services/product.service';
import { StorageService } from '../../../../services/storage.service';
import { database } from 'firebase';
import { EventSuccessComponent } from '../successful-message/successful-message.component';
import { EventChangesModalComponent } from '../event-changes-modal/event-changes-modal.component';
import { Select } from '@ngxs/store';
import { BusinessAccountModel } from 'app/models/business-account.model';
import { Observable } from 'rxjs';

declare var google;


@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.css']
})
export class NewEventComponent implements OnInit {
  @ViewChild('categorySelector') categorySelectElement: ElementRef;
  @ViewChild('typeSelector') typeSelectElement: ElementRef;
  @ViewChild('photoInput') photoInputElement: ElementRef;
  @ViewChild('photoPlaceInput') photoPlaceInputElement: ElementRef;
  @ViewChild('map') mapElement: ElementRef;
  @ViewChild('searchInput') inputElement: ElementRef;
  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  userID: string;
  eventID: any;
  newEventID: any;
  eventPhotoFile: any;
  eventPhotoPlanFile: any;

  eventData = {
    owner: "",
    title: "",
    category: "social",
    type: "public",
    description: "",
    photo_url: null,
    photoPlan_url: null,
    website: "",
    location: null,
    place: "",
    guests: null,
    dates: null,
    lastFinishDate: null,
    firstStartDate: null,
    payment: null,
  };

  error = {
    name: null,
    description: null,
    photo: null,
    photoPlan: null,
    website: null,
    location: null,
    noDates: null,
    noPayments: null,
    dates: null,
    payments: null,
    guests: null,
  };

  previousData;
  changeMessages = [];

  showMainError;
  partner;
  refs;
  banks;
  accounts;

  map: any;
  geocoder: any;
  isAddingDates = false;
  isAddingPrices = false;

  start_date: string = null;
  start_hour: string = null;
  finish_date: string = null;
  finish_hour: string = null;
  
  paymentName: string = null;
  paymentDescription: string = null;
  paymentPrice: number = null;
  paymentQuantity: number = 0;
  guest: string = null;

  eventGuests = [];
  eventDates = [];
  eventFilterDates = [];
  eventPayments = [];
  newEventPayments = [];
  
  searchKey: String = "";
  loaded: boolean = false;
  publishing: boolean = false;
  lastSelectedPartner: string  = 'traeguate';
 

  constructor(
    public benefitService: BenefitService,
    public eventsService: EventsService,
    public authService: AuthService,
    public dialog: MatDialog,
    public productService: ProductService,
    public storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
  }

  clearDateFields(){
    this.start_date = null;
    this.start_hour = null;
    this.finish_date = null;
    this.finish_hour = null;
    this.error.dates = null;
  }

  clearPaymentFields(){
    this.paymentName = null;
    this.paymentDescription = null;
    this.paymentPrice = null;
    this.paymentQuantity = 0;
    this.error.payments = null;
  }


  async ngOnInit() {
    this.geocoder = new google.maps.Geocoder();

    this.userID = (await this.authService.getAuth()).uid;
    console.log(this.userID);
/* 
    this.lastSelectedPartner = this.route.snapshot.paramMap.get('partnerID') || await this.authService.getSelectedPartner();


    console.log('Partner:' , this.lastSelectedPartner);
    this.loadPartnerData(this.lastSelectedPartner);
    this.authService.onPartnerSelectedChange.subscribe(async (businessID) => {
      this.loadPartnerData(this.route.snapshot.paramMap.get('partnerID') || businessID);
    });
 */
    this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.lastSelectedPartner = document.lastPartnerSelected;
      }
    });
    
    this.initMap();
    this.initializeData();

    this.eventID = this.route.snapshot.paramMap.get('eventID');
    if(this.eventID){
      this.eventsService.getEventData(this.eventID).then(data =>{
        this.previousData = data.val();
        this.eventData = data.val();

        this.autofillData()
        console.log(this.eventData);
      });
    } else{
      this.eventsService.getNewEventID().then(snapshot =>{
        this.newEventID = snapshot.key;
      });
    }
  }

  autofillData(){
    this.eventPayments = this.eventData.payment;
    this.eventGuests = this.eventData.guests;
    this.eventDates = this.eventData.dates;
    this.categorySelectElement.nativeElement.value = this.eventData.category;
    this.typeSelectElement.nativeElement.value = this.eventData.type;
    this.map.setCenter(this.eventData.location);
  }

  getLocationAddress(latlng){
    this.geocoder.geocode({'location': latlng}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.eventData.place = results[0].formatted_address;
        }
      }else{
        this.eventData.place = "No se ha podido cargar la dirección de esta ubicación.";
      }
    });
  }
  
  initMap() {
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      center: {lat: 14.6407200, lng: -90.5132700},
      zoom: 13,
      mapTypeId: 'roadmap',
      zoomControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: false,
    });

    var input = this.inputElement.nativeElement;
    var searchBox = new google.maps.places.SearchBox(input);

    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });

    this.map.addListener('center_changed', ()=>{
      this.eventData.location = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
    });

    this.map.addListener('dragend', ()=> {
      this.getLocationAddress(this.map.getCenter());
    });
   

    var markers = [];
    searchBox.addListener('places_changed', ()=> {
      var places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      markers.forEach((marker)=> {
        marker.setMap(null);
      });
      markers = [];

      var bounds = new google.maps.LatLngBounds();
      places.forEach((place)=> {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };

        markers.push(new google.maps.Marker({
          map: this.map,
          icon: icon,
          title: place.name,
          position: place.geometry.location
        }));

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
  }

  onPhotoFileChange(evt: any) {
    this.eventData.photo_url = null;
    const target: DataTransfer = <DataTransfer>(evt.target);

    this.eventPhotoFile = target.files[0];

    // let currentEventID = this.eventID ? this.eventID : this.newEventID;
    // this.storageService.uploadEventPhoto(currentEventID, "photo", target.files[0], url => {
    //   this.eventData.photo_url = url;
    // });

    this.error.photo = null;
  }

  onPhotoPlanFileChange(evt: any){
    this.eventData.photoPlan_url = null;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.eventPhotoPlanFile = target.files[0];

    // let currentEventID = this.eventID ? this.eventID : this.newEventID;
    // this.storageService.uploadEventPhoto(currentEventID, "photoPlan", target.files[0], url => {
    //   this.eventData.photoPlan_url = url;
    // });
  }

  onCategoryChange(value) {
    this.eventData.category = value;
    console.log(value);
  }

  onTypeChange(value) {
    this.eventData.type = value;
    console.log(value);
  }

  showNewDates(){
    this.isAddingDates = true;
    this.clearDateFields();
  }
  
  hideNewDates(){
    this.isAddingDates = false;
  }

  showNewPrices(){
    this.isAddingPrices = true;
    this.clearPaymentFields();
  }
  
  hideNewPrices(){
    this.isAddingPrices = false;
  }

  saveDate(){
    let filter_time =  Math.round(new Date(this.start_date).getTime());
    let start_time = Math.round(new Date(this.start_date + " " + this.start_hour).getTime());
    let finish_time = Math.round(new Date(this.finish_date + " " + this.finish_hour).getTime());
    
    if(!isNaN(start_time)){
      if(!isNaN(finish_time)){
        if(start_time < finish_time){
          if(start_time > Date.now()){
            this.eventFilterDates.push(filter_time);
      
            this.eventDates.push({
              start_time: start_time,
              finish_time: finish_time,
            });
        
            this.isAddingDates = false;
            this.eventData.dates = this.eventDates;
            this.error.noDates = null;

          } else {this.error.dates = "La fecha de inicio debe ser mayor a la actual. "};
        } else {this.error.dates = "La fecha de finalización no puede ser menor a la de inicio. "};
      } else {this.error.dates = "No ha ingresado una fecha de finalización válida. "};
    } else {this.error.dates = "No ha ingresado una fecha de inicio válida. "};
  }


  removeDate(index){
    this.eventDates.splice(index, 1);
    this.eventFilterDates.splice(index, 1);
    this.eventData.dates = this.eventDates;
  }
  
  savePayment(){
    if(this.paymentName){
      if(this.paymentDescription){
        if(!isNaN(this.paymentPrice)){
          if(!isNaN(this.paymentQuantity)){
 
            this.paymentPrice = Math.round(this.paymentPrice * 100) / 100;

            let paymentJson: any;
            
            if(this.paymentPrice > 4){
              paymentJson = {
                active: true,
                name: this.paymentName,
                description: this.paymentDescription,
                quantity: this.paymentQuantity,
                reservations: 0,
                price_q:{
                  1: this.paymentPrice,
                  2: this.paymentPrice - 1,
                  3: this.paymentPrice - 2,
                  4: this.paymentPrice - 3,
                  5: this.paymentPrice - 4,
                }
              };
            } else {
              paymentJson = {
                active: true,
                name: this.paymentName,
                description: this.paymentDescription,
                quantity: this.paymentQuantity,
                reservations: 0,
                price_q:{ 1: this.paymentPrice }
              };
            };

            if(this.eventID){
              this.newEventPayments.push(paymentJson);
            }else{
              this.eventPayments.push(paymentJson);
              this.eventData.payment = this.eventPayments;
            };

            this.isAddingPrices = false;
            this.error.noPayments = null;

          } else {this.error.payments = "No ha ingresado una cantidad de entradas válida."}
        } else {this.error.payments = "No ha ingresado un precio válido."};
      } else {this.error.payments = "No ha ingresado una descripción para el paquete."};
    } else {this.error.payments = "No ha ingresado un nombre para el paquete."};
  }

  removePayment(index){
    this.eventPayments.splice(index, 1);
    this.eventData.payment = this.eventPayments;
  }

  removeNewPayment(index){
    this.newEventPayments.splice(index, 1);
  }

  deactivatePayment(index){
    this.eventPayments[index].active = false;
    this.eventData.payment = this.eventPayments;
    console.log(this.eventData.payment);
  }

  activatePayment(index){
    this.eventPayments[index].active = true;
    this.eventData.payment = this.eventPayments;
    console.log(this.eventData.payment);
  }

  saveGuest(){
    if(this.guest){
      this.eventGuests.push(this.guest);
      this.eventData.guests = this.eventGuests;
      this.guest = null;
      this.error.guests = null;
    } else {this.error.guests = "No ha ingresado un nombre válido. "};
  }

  removeGuest(index){
    this.eventGuests.splice(index, 1);
    this.eventData.guests = this.eventGuests;
  }

  verifyEventChanges(){
    this.changeMessages = [];
    if(this.previousData.category !== this.eventData.category){
      this.changeMessages.push("Categoría del evento");
    }

    if(JSON.stringify(this.previousData.guests) !== JSON.stringify(this.eventData.guests)){
      this.changeMessages.push("Invitados o artistas");
    }

    if(JSON.stringify(this.previousData.dates) !== JSON.stringify(this.eventData.dates)){
      this.changeMessages.push("Fechas");
    }

    if(JSON.stringify(this.previousData.payment) !== JSON.stringify(this.eventData.payment) || this.newEventPayments.length > 0){
      this.changeMessages.push("Paquetes");
    }

    if(JSON.stringify(this.previousData.location) !== JSON.stringify(this.eventData.location)){
      this.changeMessages.push("Ubicación");
    }
    
    if(JSON.stringify(this.previousData.type) !== JSON.stringify(this.eventData.type)){
      this.changeMessages.push("Tipo de evento");
    }

  }

  validateFormFields(){
    let isValid = true;
    this.error = {
      name: null,
      description: null,
      photo: null,
      photoPlan: null,
      website: null,
      location: null,
      noDates: null,
      noPayments: null,
      dates: null,
      payments: null,
      guests: null,
    }

    if(this.eventData.title === ""){
      this.error.name = "No ha ingresado un nombre para el evento.";
      isValid = false;
    } 

    if(this.eventData.description === ""){
      this.error.description = "No ha agregado una descripción para el evento.";
      isValid = false;
    }
  
    if(!this.eventPhotoFile && !this.eventData.photo_url){
      this.error.photo = "No ha agregado una foto para el evento.";
      isValid = false;
    }

    if(this.eventData.location === null){
      this.error.location = "No ha ingresado una ubicación válida.";
      isValid = false;
    }

    if(this.eventData.dates === null){
      this.error.noDates = "No ha ingresado ninguna fecha.";
      isValid = false;
    }
   
    return isValid; 
  }

  async publishEvent() {
    if(!this.publishing){
      this.showMainError = false;
      this.eventData.place = this.inputElement.nativeElement.value;
      if(this.validateFormFields()){
        this.publishing = true;
           
        try{  
          let currentEventID = this.eventID ? this.eventID : this.newEventID;
  
          console.log('currentEventID', currentEventID);
          console.log(this.eventPhotoFile);
  
          this.eventData.photo_url = await this.storageService.uploadEventPhoto(currentEventID, "photo", this.eventPhotoFile);
          if(this.eventPhotoPlanFile){
            this.eventData.photoPlan_url = await this.storageService.uploadEventPhoto(currentEventID, "photoPlan", this.eventPhotoPlanFile);
          }
          
          console.log('Photo url', this.eventData.photo_url);
          this.setMinMaxEventDates();
  
  
          this.eventsService.uploadEventData(this.eventData, currentEventID, () =>{  
            this.eventsService.addPartnerEvents(this.lastSelectedPartner, currentEventID).then(()=>{                            
              this.eventFilterDates.forEach(filter => {
                this.eventsService.updateEventFilterDates(filter, currentEventID);
              });
  
              this.publishing = false;
              this.openSuccesfulMessageDialog("El evento ha sido publicado.");
              this.initializeData();
            });
          });
          
        } catch (error){
          console.error(error);
          this.publishing = false;
          this.openSuccesfulMessageDialog("Ha ocurrido un error al publicar tu evento.");
        }
      } else {
        this.showMainError = true;
      }
    }
  }


  setMinMaxEventDates(){
    for (let i = 0; i < this.eventDates.length; i++) {

      let initialDate = this.eventDates[i].start_time;
      let finalDate = this.eventDates[i].finish_time;

      if(this.eventData.firstStartDate === null){
        this.eventData.firstStartDate = initialDate;
      } else if(this.eventData.firstStartDate > initialDate){
        this.eventData.firstStartDate = initialDate;
      }


      if(this.eventData.lastFinishDate == null){
        this.eventData.lastFinishDate = finalDate;
      } else if(this.eventData.lastFinishDate < finalDate){
        this.eventData.lastFinishDate = finalDate;
      }
    }
  }

  openSuccesfulMessageDialog(message){
    let dialogRef = this.dialog.open(EventSuccessComponent, {data: {  
      message: message 
    }});

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      window.history.back();
    });
  }

  openEventChangesModal(){
    this.eventData.place = this.inputElement.nativeElement.value;
    this.verifyEventChanges();
    if(this.validateFormFields()){
      let dialogRef = this.dialog.open(EventChangesModalComponent, {data: {  
        eventID: this.eventID,
        eventData: this.eventData,
        newPayments: this.newEventPayments,
        eventFilterDates: this.eventFilterDates,
        changes: this.changeMessages,
      }});
  
      dialogRef.afterClosed().subscribe(async result => {
        console.log('The dialog 2 was closed');
        console.log(result);
        if(result){
          try{
            await this.publishEvent();
            window.history.back();
          } catch(e){
            console.error(e);
          }
        }
      });

    } else {
      this.showMainError = true;
    }
  }

  initializeData(){
    this.eventData = {
      owner: this.lastSelectedPartner,
      title: "",
      category: "social",
      type: "public",
      description: "",
      photo_url: null,
      photoPlan_url: null,
      website: "",
      location: null,
      place: "",
      guests: null,
      dates: null,
      lastFinishDate: null,
      firstStartDate: null,
      payment: null,
    }
  
    this.error = {
      name: null,
      description: null,
      photo: null,
      photoPlan: null,
      website: null,
      location: null,
      noDates: null,
      noPayments: null,
      dates: null,
      payments: null,
      guests: null,
    }
  
    this.showMainError = false;
    this.isAddingDates = false;
    this.isAddingPrices = false;
  
    this.start_date = null;
    this.start_hour = null;
    this.finish_date = null;
    this.finish_hour = null;
    
    this.paymentName = null;
    this.paymentDescription = null;
    this.paymentPrice = null;
    this.guest = null;
  
    this.eventGuests = [];
    this.eventDates = [];
    this.eventFilterDates = [];
    this.eventPayments = [];

    this.categorySelectElement.nativeElement.selectedIndex = "0";
    this.typeSelectElement.nativeElement.selectedIndex = "0";
    this.photoInputElement.nativeElement.value = null;
    this.photoInputElement.nativeElement.files = null;
    this.photoPlaceInputElement.nativeElement.value = null;
    this.photoPlaceInputElement.nativeElement.files = null;
  }

}
