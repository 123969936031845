import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
// import { environment } from 'environments/environment';
import { take, map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(
    public db: AngularFireDatabase,
    public afs: AngularFirestore,
  ) {

  }

  setOfferStatus(offerID, status) {
    return this.afs.collection('offers').doc(offerID).update({
      activated: status,
    })
  }

  deleteOffer(offer) {
    if (offer.key) {
      return this.afs.collection('offers').doc(offer.key).update({
        institutionID: null,
      }).then()
    }
    return null;
  }

  updateOffer(offerID, offerData) {
    return this.afs.collection('offers').doc(offerID).set(offerData).then();
  }

  saveOffer(offerData) {
    return this.afs.collection('offers').add(offerData).then();
  }

  async getOfferData(offerID) {
    let document = await this.afs.collection('offers').doc(offerID).get().toPromise().then(doc => ({ ...doc.data(), key: doc.id }));
    return document;
  }

  async getInstituionOffers(institutionID) {
    let query = this.afs.collection('offers', ref => ref
      .where('institutionID', '==', institutionID)
    );

    const snapshot = await query.get().pipe(take(1)).toPromise().then(data => data);
    return snapshot.docs.map(doc => ({ ...doc.data(), key: doc.id }));
  }

  async getOfferExchange(code) {
    let document = await this.afs.collection('exchangedOffers').doc(code).get().toPromise().then(doc => ({ ...doc.data(), key: doc.id }));
    return document;
  }

  async getUserData(uid) {
    return this.db.object('users/' + uid).valueChanges().pipe((take(1))).toPromise().then(user => ({ ...user, key: uid }));
  }

  async updateOfferExchangeData(code, step, price, status) {
    return this.afs.collection('exchangedOffers').doc(code).update({
      step: step,
      price: price,
      status: status,
    }).then();
  }

  
  async updateOfferExchangeByJSON(code, data) {
    return this.afs.collection('exchangedOffers').doc(code).update(data);
  }

  async clearUserExchangeCode(userID){
    return this.afs.collection('scannedCodes').doc(userID).set({'code': null});
  } 

  async denyOfferExchange(code) {
    return this.afs.collection('exchangedOffers').doc(code).update({
      status: "denied",
    }).then();
  }

  listenForOfferExchangeUserAction(generatedCode, callback) {
    return this.afs.firestore.collection("exchangedOffers").doc(generatedCode).onSnapshot(doc => {
      callback(doc.data());
    });
  }

  listenForOfferScann(userID, callback) {
    return this.afs.firestore.collection("scannedCodes").doc(userID).onSnapshot(doc => {
      callback(doc.data());
    });
  }


  async getUserPreviousExchangedOffers(userID, institutionID) {
    let query = this.afs.collection('exchangedOffers', ref => ref
      .where('userID', '==', userID)
      .where('institutionID', '==', institutionID)
      .orderBy('requestedTime', 'desc')
      .limit(10)
    );

    const snapshot = await query.get().pipe(take(1)).toPromise().then(data => data);
    let docs = snapshot.docs
      .map(doc => ({ ...doc.data(), key: doc.id }))
      .filter((doc: any) => doc.status != 'pending');

    for (let i = 0; i < docs.length; i++) {
      const offerData = await this.getOfferData(docs[i]['offerID']);
      docs[i] = { ...offerData, ...docs[i] };
    }

    return docs;
  }



}
