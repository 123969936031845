export const environment = {
   production: true,
   firebase: {
     apiKey: "AIzaSyDw1Ke7O4VKPTCZmAz-6ccQ2_tI_YDwWdc",
     authDomain: "traeguate-prod.firebaseapp.com",
     databaseURL: "https://traeguate-prod.firebaseio.com",
     projectId: "traeguate-prod",
     storageBucket: "traeguate-prod.appspot.com",
     messagingSenderId: "393278837031"
   },
   cloudBaseUrl: 'https://us-central1-traeguate-prod.cloudfunctions.net'
};

