import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { TripService } from '../../../services/trip.service';
import { NodeService } from '../../../services/node.service';
import { VehicleService } from '../../../services/vehicle.service';
import { MessagesService } from '../../../services/messages.service';
import { AlertService } from '../../../services/alert.service';
import { OpinionService } from '../../../services/opinion.service';
import * as moment from 'moment';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  customers;
  trips;
  male;
  female;
  nodes;
  distance;
  alerts;
  messages;
  vehicles;
  opinions;
  constructor(
    private customerService: CustomerService,
    private tripService: TripService,
    private nodeService: NodeService,
    private vehicleService: VehicleService,
    private alertsService: AlertService,
    private messagesServices: MessagesService,
    private opinionService: OpinionService,
  ) {
    customerService.getUsersList((callback) => {
      this.customers = callback;
      this.male = [];
      this.female = [];
      this.customers.forEach(user => {
        var year = moment(user.birth, "YYYY-MM-DD").fromNow();
        if(year.indexOf("years ago") != -1){
          var value = parseInt(year.replace(" years ago",""));
          if(user.genre == "male"){
            this.male.push(value);
          }else if(user.genre == "female"){
            this.female.push(value);
          }
        }
      });
    });



    customerService.getList((users) => {
      tripService.getArrayList((callback) => {
        this.trips = callback;
        var reservations = 0;
        var tripsCounted = [];
        this.distance = [];
        this.trips.forEach(element => {
          try {
            var startNode = element.nodes[0];
            var endNode = element.nodes[element.nodes.length - 1];
            if(element.prices[startNode][endNode]){
              this.distance.push(element.prices[startNode][endNode].distance);
            }            
          } catch (error) {
            console.log(error);
          }

          if(element['reservations']){
            if(users[element.owner]){
              console.log("Conductor: "+users[element.owner]['names'] + " " + users[element.owner]['last_name']);
            }
            Object.keys(element['reservations']).forEach(item => {
              if(element['reservations'][item].status == "accepted"){
                console.log(users[item]['names'] + " " + users[item]['last_name']);
                if(tripsCounted.indexOf(element) == -1){
                  tripsCounted.push(element);
                  reservations++;
                }
              }
            }); 
          }
        });
        console.log("reservations: ", reservations);
      });
    });

  
    opinionService.getList((callback) => {
      this.opinions = callback;
    });
    nodeService.getList((callback) => {
      this.nodes = callback;
    });
    vehicleService.getList((callback) => {
      this.vehicles = callback;
    });
    alertsService.getList((callback) => {
      this.alerts = callback;
    });
    messagesServices.getList((callback) => {
      this.messages = callback;
    });

    nodeService.getList((callback) => {
      this.nodes = callback;
    });
  }

  generateTripsReport(){
    console.log(this.trips);
  }

  ngOnInit() {
  }

}
