import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnerAccount'
})
export class PartnerAccountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let output = [];
    if (value && args) {
      value.forEach(element => {
        args.forEach(element2 => {
          if (element2.partners && element2.partners[element.company] && element2.partners[element.company].account) {
            output.push({ key: element.company, account: element2.partners[element.company].account });
          }
        });
      });
    }
    return output;
  }

}
