import { Component, OnInit } from '@angular/core';
import { OffersService } from 'app/services/offers.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-exchange-offer',
  templateUrl: './exchange-offer.component.html',
  styleUrls: ['./exchange-offer.component.css']
})
export class ExchangeOfferComponent implements OnInit {

  userID: any;
  code: string;
  exchangedOffer: any;
  previousExchanges: any[];
  offer: any;
  user: any;
  form;

  loaded: boolean = false;
  loadedPrevious: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private offersService: OffersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
  ) { 
    this.form = this.formBuilder.group({
      'price': new FormControl('',[
        Validators.min(1),    
        Validators.required,    
      ]),

    });
  }

  async ngOnInit() {
    this.code = this.route.snapshot.paramMap.get('code');

    try{
      this.userID = (await this.authService.getAuth()).uid;
      this.exchangedOffer =  await this.offersService.getOfferExchange(this.code.trim());
      this.loadPrevious(); 
      this.offer = await this.offersService.getOfferData(this.exchangedOffer.offerID);
      this.user = await this.offersService.getUserData(this.exchangedOffer.userID);

      if(this.offer.type === 'free'){
        this.form.get('price').disable();
      }

      if(this.exchangedOffer.price != null){
        this.form.get('price').setValue(this.exchangedOffer.price);
      }

      if (this.exchangedOffer.step === 2){
        this.listenForUserResponse();
      }

      this.loaded = true;

    } catch(e){
      console.error(e);
      this.loaded = true;
    }
  }

  //Loads a list of previos exchanges by the user 
  async loadPrevious(){
    this.loadedPrevious = false;
    this.previousExchanges = await this.offersService.getUserPreviousExchangedOffers(
      this.exchangedOffer.userID,
      this.exchangedOffer.institutionID,
    );
    console.log('o', this.previousExchanges);
    this.loadedPrevious = true;
  }

  async nextStep(){
    if(this.offer.type === 'free'){
      await this.offersService.updateOfferExchangeData(this.code, 3, null, 'accepted');
      await this.offersService.clearUserExchangeCode(this.userID);
      this.exchangedOffer.status = 'accepted';
      this.exchangedOffer.step = 3;

    } else if (this.offer.type === 'promotion' || this.offer.type === 'discount'){
      await this.offersService.updateOfferExchangeData(this.code, 2, this.form.get('price').value, 'pending');
      this.exchangedOffer.step = 2;
      this.listenForUserResponse();
    }
  }

  async deny(){
    await this.offersService.denyOfferExchange(this.code);
    this.exchangedOffer.status = 'denied';
    this.exchangedOffer.step = 3;
  }

  listenForUserResponse(){
    console.log('listening...');
    let unsubscribe = this.offersService.listenForOfferExchangeUserAction(this.code, response =>{
      console.log('response', response);

      if(response.status === 'accepted' || response.status === 'denied'){
        this.exchangedOffer.status = response.status;
        this.exchangedOffer.step = 3;
        unsubscribe();
      }
    });
  }

  continue(){
    window.history.back();
  }


}
