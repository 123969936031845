import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metricType'
})
export class MetricTypePipe implements PipeTransform {

  transform(value: any): any {
    switch(value){
      case 'biography': return 'Biografía'
      case 'dpi': return 'DPI'
      case 'driver_license': return 'Licencia'
      case 'institution': return 'Institucional'
      case 'police_background': return 'Antecedentes'
      case 'vehicle_insurance': return 'Seguro'
      case 'vehicle_papers': return 'Tarjeta'
    }

    return 'No definida';
  }

}
