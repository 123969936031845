import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    public db: AngularFireDatabase, 
    public fns: AngularFireFunctions,
  ) { }

  getNewEventID(){
    return firebase.database().ref('events/data/').push(null);
  }

  uploadEventData(eventData, eventID,callback){
    firebase.database().ref('events/data/' + eventID).update(eventData).then(() => {
      callback();
    });
  }

  updateEvent(eventID, eventData){
    return firebase.database().ref('events/data/'+ eventID).update(eventData);
  }

  updatePhotoUrl(eventID, urlObject){
    return firebase.database().ref('events/data/'+ eventID).update(urlObject);
  }

  addPartnerEvents(partnerID, eventID){
    return firebase.database().ref('benefits/business/' + partnerID + '/events').update({
      [eventID]: true,
    });
  }

  getEventData(eventID){
    return firebase.database().ref('events/data/'+ eventID).once('value');
  }

  
  async getEventsListData(partnerID){
    let eventsList = [];

    //TODO: Replace this reference to direction 'benefits/businessInfo/' + partnerID + '/events'    
    let eventKeys: any = await this.db.object('benefits/business/' + partnerID + '/events').valueChanges().pipe((take(1))).toPromise().then(data => data);
    
    for (var key in eventKeys){
      let event = await this.db.object('events/data/'+ key).valueChanges().pipe((take(1))).toPromise().then(data => data);
      if(event) eventsList.push({...(event as Object), key});
    }
    
    return eventsList;
  }

  async getEventReservationsList(partnerID){
    let reservations = [];
    let snapshot: any = await this.db.object('events/reservations/' + partnerID).valueChanges().pipe((take(1))).toPromise().then(data => data);
    for (var key in snapshot){
      reservations.push({...snapshot[key], key});
    }
    
    return reservations;
  }

  async getEventRefondRequests(partnerID){
    let refoundRequests = [];
    let snapshot: any = await this.db.object('events/refoundRequests/' + partnerID).valueChanges().pipe((take(1))).toPromise().then(data => data);
    for (var key in snapshot){
      if(snapshot[key].status === 'pendiente'){
        refoundRequests.push({...snapshot[key], key});
      }
    }
    
    return refoundRequests;
  }
  
  setRefoundRequestStatus(eventID, userID, status){
    return this.db.object('events/refoundRequests/' + eventID + '/' + userID).update({
      status: status,
    })
  }

  updateEventFilterDates(filter, eventID){
    firebase.database().ref('events/dates/'+ filter).update({
      [eventID]: true,
    });
  }


  getInstitutionName(institutionID){
    return this.db.object('/benefits/business/'+ institutionID +'/name').valueChanges().pipe(take(1)).toPromise().then(name => name);
  }

  getEventDataByID(eventID){
    return this.db.object('/events/data/' + eventID).valueChanges().pipe(take(1)).toPromise().then(data => data);
  }

  getUserEventReservation(eventID, userID){
    return this.db.object('/events/reservations/'+ eventID +'/' + userID).valueChanges().pipe(take(1)).toPromise().then(reservation => reservation);
  }

  getUserVinculatedInstitutions(userID){
    return this.db.object('/users/' + userID + '/confiability/aproved/6/').snapshotChanges().pipe(take(1)).toPromise();
  }


  getNextEvents(userID, time, length){
    let nextEvents = [];
    let query = this.db.list('/events/data/', ref => ref.orderByChild('lastFinishDate').startAt(time).limitToFirst(length));

    return this.getUserVinculatedInstitutions(userID).then(institutions => 
      query.snapshotChanges().pipe(take(1)).toPromise().then( (events:any) =>{
        events.forEach(element => {
          if(element.payload.val().type === "private"){
            if(institutions && institutions.payload.val() && institutions.payload.val()[element.payload.val().owner]){
              nextEvents.push({...element.payload.val(), key: element.key});
            }
          } else {
            nextEvents.push({...element.payload.val(), key: element.key});
          }
        });

        return nextEvents;
      })
    );
  }


  async getUserFavoriteEvents(userID, time, length){
    let userFavoriteEvents = { data: [], keys: [],};
    let query = this.db.list('/userInfo/' + userID +'/events/favorites', ref => ref.orderByValue().startAt(time).limitToFirst(length));

    await query.snapshotChanges().pipe(take(1)).toPromise().then(async events => {
      for (let i = 0; i < events.length; i++) {
        const eventID = events[i].key;
        const eventData = await this.getEventDataByID(eventID);
        userFavoriteEvents.data.push({ ...(eventData as Object), key: eventID});
        userFavoriteEvents.keys.push(eventID);
      }   
    });
    
    return userFavoriteEvents;
  }

  async getUserNextReservations(userID, time, length){
    let userNextReservations = [];
    let query = this.db.list('/userInfo/' + userID +'/events/reservations', ref => ref.orderByValue().startAt(time).limitToFirst(length));

    await query.snapshotChanges().pipe(take(1)).toPromise().then(async events =>{
      for (let i = 0; i < events.length; i++) {
        const eventID = events[i].key;
        const eventData = await this.getEventDataByID(eventID);
        userNextReservations.push({ ...(eventData as Object), key: eventID});
      }      
    });

    return userNextReservations;   
  }

  
  async getUserAllReservations(userID){
    let userAllReservations = [];
    let query = this.db.list('/userInfo/' + userID +'/events/reservations', ref => ref.orderByValue().startAt(0));

    await query.snapshotChanges().pipe(take(1)).toPromise().then(async events =>{
      for (let i = 0; i < events.length; i++) {
        const eventID = events[i].key;
        const eventData = await this.getEventDataByID(eventID);
        userAllReservations.push({ ...(eventData as Object), key: eventID});
      }      
    });

    return userAllReservations;   
  }

  async getUserPastReservations(userID, time, length){
    let userPastReservations = [];
    let query = this.db.list('/userInfo/' + userID +'/events/reservations', ref => ref.orderByValue().endAt(time).limitToLast(length));

    await query.snapshotChanges().pipe(take(1)).toPromise().then(async events =>{
      for (let i = 0; i < events.length; i++) {
        const eventID = events[i].key;
        const eventData = await this.getEventDataByID(eventID);
        userPastReservations.push({ ...(eventData as Object), key: eventID});
      }      
    });

    return userPastReservations;   
  }

  addEventToUserFavorites(userID, eventID, lastFinishDate){
    return firebase.database().ref('/userInfo/' + userID +'/events/favorites/').update({
      [eventID]: lastFinishDate,
    });
  }

  addEventToUserReservationsList(userID, eventID, lastFinishTime) {
    return firebase.database().ref('/userInfo/' + userID + '/events/reservations/').update({
      [eventID]: lastFinishTime,
    });
  }

  removeEventToUserReservationsList(userID, eventID) {
    return firebase.database().ref('/userInfo/' + userID + '/events/reservations/'+ eventID).remove();
  }

  addEventToUserFavoritesList(userID, eventID, lastFinishTime) {
    return firebase.database().ref('/usersInfo/' + userID + '/events/favorites').update({
      [eventID]: lastFinishTime,
    });
  }

  removeFromUserFavorites(userID, eventID){
    return firebase.database().ref('/userInfo/' + userID +'/events/favorites/' + eventID).remove();
  }

  saveRefoundRequest(eventID, userID, formInfo, documentURL, reservation){
    return firebase.database().ref('/events/refoundRequests/' + eventID + '/' + userID).update({
      time: firebase.database.ServerValue.TIMESTAMP,
      reason: formInfo.get('reason').value,
      description: formInfo.get('description').value,
      documentURL: documentURL,
      status: 'pendiente',
      reservation: {...reservation}
    });
  }

  removeRefoundRequest(eventID, userID){
    return firebase.database().ref('/events/refoundRequests/' + eventID + '/' + userID).remove();
  }

  getRefoundRequest(eventID, userID){
    return firebase.database().ref('/events/refoundRequests/' + eventID + '/' + userID).once('value')
      .then(refoundRequst => refoundRequst.val());
  }

  addEventReservation(eventID, userID, userData, code, type, paymentID, price_q_ID, eventData, total){
    this.updateEventReservationsCounter(eventID, paymentID, 1, eventData.payment[paymentID].quantity);

    return firebase.database().ref('/events/reservations/' + eventID + '/' + userID).update({
      name: userData.names,
      img_url: userData.img_url,
      time: firebase.database.ServerValue.TIMESTAMP,
      code: code,
      total: total, 
      type: type,
      paymentID: paymentID,
      price_q_ID: price_q_ID
    });
  }

  async removeEventReservation(eventID, userID, paymentID, eventData){
    await this.updateEventReservationsCounter(eventID, paymentID, -1, eventData.payment[paymentID].quantity);
    return firebase.database().ref('/events/reservations/' + eventID + '/' + userID).remove();
  }

  updateEventReservationsCounter(eventID, paymentID, reservationsToAdd, quantity) {
    return new Promise(Resolve => {
      this.db.object('/events/data/' + eventID + '/payment/' + paymentID + '/reservations').query.ref.transaction((currentReservations) => {
        if ((currentReservations >= quantity) && (quantity != 0)) {
          return;
        }
        return currentReservations + reservationsToAdd;
      }, (error, committed, snapshot) => {
        if (error) {
          console.log('The transaction fail', error);
          return Resolve(false);
        } else if (!committed) {
          console.log('The transaction is not committed');
          return Resolve(false);
        } else {
          console.log('Event reservations counter updated successfully');
          return Resolve(true);
        }
        console.log("New Tokens value: ", snapshot.val());
      });
    })
  }

  async doEventRefound(functionName, request, businessID, eventID){
    const cloudFn = this.fns.httpsCallable(functionName);
    let response = await cloudFn({
      request: ({...request.reservation, key: request.key}),
      businessID: businessID,
      eventID: eventID,
    }).toPromise();

    return response;
  }

  
  getList() {
    return this.db.list('/events/');
  }

  getEvent(id) {
    return this.db.object('/events/'+id);
  }

  incrementCode(id) {
    return firebase.database().ref('/events/data/'+id+'/generated_code');
  }
 
}
