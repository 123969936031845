import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable()
export class StorageService {

  constructor() { }

  async uploadFile(url, file) {
    file = file.replace(/^data:image\/[a-z]+;base64,/, "");
    console.log(file);
    await firebase.storage().ref(url).putString(file, 'base64').then(data => {
      console.log(data);
    });
    return await firebase.storage().ref(url).getDownloadURL().then(url => url);
    /* 
    this.uploadProgress = this.task.snapshotChanges()
      .pipe(map(s => (s.bytesTransferred / s.totalBytes) * 100)); */
  }

  async uploadEventPhoto(eventID, fileName, file: File) {

    let fileRef = await firebase.storage().ref('events/' + eventID + '/' + fileName).put(file).then(response => response.ref);
    let url = await fileRef.getDownloadURL();

    return url;
  }

  downloadEventPhoto(url){
    return firebase.storage().refFromURL(url).getDownloadURL();
  }
}
