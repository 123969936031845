import { Component, OnInit } from '@angular/core';
import { BenefitService } from '../../../services/benefit.service';
import { CustomerService } from '../../../services/customer.service';
import { log } from 'util';
import { HistoryService } from '../../../services/history.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  listhistory;
  users;
  partnerOffers;
  loggedData;
  constructor(
    public benefitService: BenefitService,
    public customerService: CustomerService,
    public historyService: HistoryService,
    public authService: AuthService
  ) { }

  ngOnInit() {

    this.loggedData = JSON.parse(localStorage.getItem('logued_partner_data'));
    this.benefitService.getListByPartner(localStorage.getItem('bussiness_active'), (list) => {
      this.partnerOffers = list;
    });

    this.historyService.historyByCompany(localStorage.getItem('bussiness_active'), history => {
      this.listhistory = history;
    }, err => {
      console.log(err);
    });

    this.customerService.getList((customers) => {
      this.users = customers;
    });
  }

}
