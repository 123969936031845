import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../../services/payments.service';
import { ProductService } from '../../../services/product.service';
import { log } from 'util';

@Component({
  selector: 'app-business-payments',
  templateUrl: './business-payments.component.html',
  styleUrls: ['./business-payments.component.css']
})
export class BusinessPaymentsComponent implements OnInit {

  history;
  banks;
  bankList;
  bank = "bi";
  partners;
  selectedIndex = 0;
  okArray = [];
  errorArray = [];
  cargando = false;

  constructor(
    public paymentsService: PaymentsService,
    public productService: ProductService,
  ) { }

  ngOnInit() {
    this.productService.getPartners(data => {
      this.partners = data;
    });

    this.paymentsService.getPayments(list => {
      this.history = list;
    });

    this.paymentsService.bankAsObject(banks => {
      this.banks = banks;
    });

    this.paymentsService.getBanks(list => {
      this.bankList = list;
    }, err => {
      console.log(err);
    });
  }

  saveBank(bank) {
    this.bank = bank.key;
    this.selectTab(1);
  }

  accountsFile2(payments) {
    var data = [];
    payments.forEach(histo => {
      if (this.partners[histo.company].account) {
        data.push([histo.company, this.partners[histo.company].account.name, this.partners[histo.company].account.account]);
      }
    });
    this.download(data, 'business_accounts_' + this.bank + '.csv');
    this.selectTab(2);
  }

  accountsFile(payments) {
    this.paymentsService.createPartnerPayment(payments, ok => {
      if (ok.status == 200) {
        var data = [];
        payments.forEach(histo => {
          if (this.partners[histo.company].account) {
            data.push([histo.company, this.partners[histo.company].account.name, this.partners[histo.company].account.account]);
            this.paymentsService.updateHistory(histo.key, { status: 'check' });
          }
        });
        this.download(data, 'business_accounts_' + this.bank + '.csv');
        this.selectTab(2);
      }
      else {
        alert("ERROR AL PROCESAR");
      }
    });
  }

  selectTab(index: number): void {
    this.selectedIndex = index;
  }

  changeTab(e) {
    this.selectedIndex = e.index;
  }

  download(data, name) {
    var csv = '';
    data.forEach((row) => {
      csv += row.join(',');
      csv += "\n";
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = name;
    hiddenElement.click();
  }

  paymentFile(list) {
    var data = [];
    list.forEach(histo => {
      if (this.partners[histo.company].account && histo.status == 'pending') {
        data.push([histo.company, this.partners[histo.company].account.name,
        this.partners[histo.company].account.account, (histo.payment).toFixed(2)
        ]);
        this.paymentsService.updateHistory(histo.key, { status: 'processing' });
      }
    });
    this.download(data, 'business_payments_' + this.bank + '.csv');
    this.selectTab(3);
  }

  confirm(histo) {
    this.okArray.push(histo.key)
  }

  confirmError(histo) {
    this.errorArray.push(histo.key)
  }

  confirmAll(histo) {
    this.cargando = true;
    histo.forEach(element => {
      if (this.okArray.indexOf(element.key) == -1) {
        if (this.errorArray.indexOf(element.key) == -1) {
          this.okArray.push(element.key)
        }
      }
    });

    this.paymentsService.updateBusinessDeposits(this.okArray, this.errorArray, ok => {
      if (ok.status == 200) {
        this.okArray = [];
        this.errorArray = [];
        this.cargando = false;
      }
      else {
        alert("ERROR AL PROCESAR");
      }
    });
    this.selectTab(0);
  }

}
