import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class ArticleComponent implements OnInit {

  article: any;
  id;
  location: Location;
  
  constructor(location: Location,
              private activatedRoute: ActivatedRoute,
              db: AngularFireDatabase) 
  { 
    this.location = location;
    this.activatedRoute.params.subscribe((params: Params) => {
    this.id = params['number'];
    });
      
    this.article = db.object('/article/'+this.id);
  }
  
  ngOnInit() {
  
  }
}
