import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ArticleService } from '../../../services/article.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  article: any;
  c: number = 0;
  arrayArt = [];
  catChoice: string = 'all';
  increment = 15;
  sum = 10;
  
  constructor(db: AngularFireDatabase,
              public serviceArticle:ArticleService) 
  {
    this.article = serviceArticle.getArticles();
    this.c = 0;
    this.addItems(0, this.sum);
  }

  addItems(startIndex, endIndex) {
    for (let i = 0; i < this.sum; ++i) {
      this.arrayArt.push(this.article);
    }
  }
  
  changeCategory(param) {
    this.catChoice = param;
  }
  
  ngOnInit() {
    this.catChoice = 'all';
  }

  onScrollDown () {
    console.log('scro');
    
    const start = this.sum;
    this.sum += 10;
    this.addItems(start, this.sum);
  }

}
