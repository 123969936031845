import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, map } from 'rxjs/operators';

@Injectable()
export class ProductService {

  ref: any;
  actions: any;
  constructor(public db: AngularFireDatabase) { }

  add(data, partner, callback) {
    this.db.list('benefits/business/' + partner + '/offers/').push(data).then(snapshot => {
      callback(snapshot);
    });
  }

  getListByPartner(partner, callback) {
    this.db.object('benefits/business/' + partner + '/offers/').snapshotChanges().pipe(take(1)).pipe(
      map(c =>
        ({ key: c.payload.key, ...c.payload.val() }))
    ).subscribe(snapshot => {
      if (snapshot) {
        callback(snapshot);
      } else {
        callback({});
      }
    });
  }

  getOfferRef(partnerId, offerId) {
    return this.db.object('benefits/business/' + partnerId + '/offers/' + offerId);
  }

  getPartnerData(partner, success) {
    this.db.object('benefits/business/' + partner).snapshotChanges().pipe(take(1)).pipe(
      map(c =>
        ({ key: c.payload.key, ...c.payload.val() }))
    ).subscribe(snapshot => {
      success(snapshot);
    });
  }

  addAccount(item, partner, resolve) {
    this.db.object('benefits/business/' + partner + '/account').set(item).then(ok => {
      resolve(ok);
    });
  }

  getPartners(callback) {
    this.db.object('benefits/business/').snapshotChanges().pipe(take(1)).pipe(
      map(c =>
        ({ key: c.payload.key, ...c.payload.val() }))
    ).subscribe(snapshot => {
      callback(snapshot);
    });
  }
}
