import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable()
export class ConfiabilityService {

  actions: any;
  constructor(
    public db: AngularFireDatabase,
    private afs: AngularFirestore,
  ) { }

  getList() {
    let query = this.afs.collection('/users/minified/items', ref => ref
      .where('pending_requests_counter', '>', 0)
      .orderBy('pending_requests_counter')
      .orderBy('last_request_time', 'desc')
    );
    return query;
  }

  getConfiability(userID: string) {
    return this.afs.collection('/users/confiability/items').doc(userID);
  }

  async updateMetricStatus(userID: string, metricID: string, status: string, number?: string) {

    await this.afs.collection('/users/confiability/items').doc(userID).update({
      [`requests.${metricID}.status`]: status
    });

    if (number) {
      await this.afs.collection('/users/confiability/items').doc(userID).update({
        [`requests.${metricID}.number`]: number,
      })
    }
  }

  async updateInstitutionalStatus(userID: string, institutionRequestID: string, status: string, text: string) {

    var institutionRef = this.afs.firestore.collection('institutionRequests').doc(institutionRequestID);
    var confiabilityItemsRef = this.afs.firestore.collection('/users/confiability/items').doc(userID);
    var masterUserRef = this.afs.firestore.collection('/users/master/items').doc(userID);

    return this.afs.firestore.runTransaction(async (transaction) => {

      var requestsCounter = (await transaction.get(confiabilityItemsRef).then(data => data.data()))['requests']['institution'];
      requestsCounter.pending_invitations = requestsCounter.pending_invitations - 1;
      requestsCounter[status + '_invitations'] = requestsCounter[status + '_invitations'] + 1;

      var newStatus;
      if (requestsCounter.pending_invitations > 0) {
        newStatus = "pending";
      } else if (requestsCounter.accepted_invitations > 0) {
        newStatus = "accepted";
      } else {
        newStatus = "denied";
      }

      if (status == "accepted") {
        transaction.update(masterUserRef,
          {
            institutions: firebase.firestore.FieldValue.arrayUnion(institutionRequestID)
          });
      }

      transaction.update(confiabilityItemsRef,
        {
          ['requests.institution.pending_invitations']: requestsCounter.pending_invitations,
          ['requests.institution.' + status + '_invitations']: requestsCounter[status + '_invitations'],
          ["requests.institution.status"]: newStatus,
        });

      transaction.update(institutionRef, { "status": status, message: (text || null), modifiedAt: firebase.firestore.FieldValue.serverTimestamp() });

      return { success: true };
    }).then(success => {
      console.log(success);

      return true;
    })
      .catch(error => {
        console.log(error);
        return false;
      });
  }

  async getVehiclesByUserID(userID: string) {
    return this.afs.firestore.collection('vehicles').where('owner', '==', userID).get()
  }
}
