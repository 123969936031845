import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EventsService } from 'app/services/events/events.service';

@Component({
  selector: 'app-event-changes-modal',
  templateUrl: './event-changes-modal.component.html',
  styleUrls: ['./event-changes-modal.component.css']
})
export class EventChangesModalComponent implements OnInit {

  info: any;
  
  constructor(
    public dialogRef: MatDialogRef<EventChangesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    public eventsService: EventsService,
  ) {
    this.info = JSON.parse(JSON.stringify(data));
    
    if(this.info.eventData.payment){
      this.info.eventData.payment = this.info.eventData.payment.concat(this.info.newPayments);
    } else{
      this.info.eventData.payment = this.info.newPayments;
    }

    console.log("INFO", this.info);
  }

  ngOnInit() {
  }


  async updateEvent(){

    this.eventsService.updateEvent(this.info.eventID, this.info.eventData).then(()=>{
      if(this.info.changes.includes('Fechas')){
        
        console.log("hubo fecha");
      }

      this.dialogRef.close(true);
    });
  }
  
  close(){
    this.dialogRef.close();
  }
}
