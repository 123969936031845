import { Component, OnInit } from '@angular/core';
import { PaymentsService } from 'app/services/payments.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BusinessAccountModel } from 'app/models/business-account.model';


@Component({
  selector: 'app-balance-overview',
  templateUrl: './balance-overview.component.html',
  styleUrls: ['./balance-overview.component.css']
})
export class BalanceOverviewComponent implements OnInit {

  businesSubscription;
  balanceSubscription; 
  businessID;
  balance: any;

  @Select(state => state.businessAccount)
  businessAccount$: Observable<BusinessAccountModel>;

  constructor(
    private paymentsService: PaymentsService,
  ) { }

  ngOnInit() {
    this.loadBalance();
  }

  //Remember to close all types of subscriptions;
  ngOnDestroy() {
    if(this.balanceSubscription){
      this.balanceSubscription.unsubscribe();
    }

    if(this.businesSubscription){
      this.businesSubscription.unsubscribe();
    }
  }

  loadBalance(){
    
    
    this.businesSubscription = this.businessAccount$.subscribe((document) => {
      if (document.lastPartnerSelected) {
        this.businessID = (document.lastPartnerSelected);

        if(this.balanceSubscription){
          this.balanceSubscription.unsubscribe();
        }

        this.balanceSubscription = this.paymentsService
          .getBusinessBalanceDocRef(this.businessID)
          .valueChanges()
          .subscribe((balance)=>{
            this.balance = balance;
            this.balance.tickets
          }
        );
      }
    });
  }

}
