import { Component, OnInit, Inject } from '@angular/core';
import { LegalService } from 'app/services/legal.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-legal-subsection',
  templateUrl: './legal-subsection.component.html',
  styleUrls: ['./legal-subsection.component.css']
})
export class LegalSubsectionComponent implements OnInit {

  form: FormGroup;
  submitted:boolean = false;
  sectionID;
  subsectionID: any;
  index;
  subindex;
  type;
  content;

  constructor(
    public legalService: LegalService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LegalSubsectionComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
  ) { 
    this.sectionID = params.sectionID;
    this.subsectionID = params.subsectionID;
    this.index = params.index;
    this.subindex = params.subindex;
    this.type = params.type;
    this.content = params.content;

    this.form = this.formBuilder.group({
      'content': new FormControl('', [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {

    if(this.subsectionID){
      this.form.get('content').setValue(this.content);
    }

  }

  close(){
    this.dialogRef.close();
  }

  async saveSubsection(){

    this.submitted = true;

    if(this.form.valid){
      if(this.subsectionID){
        this.legalService.updateSubsection(
          this.sectionID,
          this.subsectionID,
          this.type,
          this.form.get('content').value,
          this.subindex
        );      
      }else{
        this.legalService.addSubsection(
          this.sectionID, 
          this.type, 
          this.form.get('content').value,
          this.subindex
        );
      }

      this.close();
    }

  }

}
