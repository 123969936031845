    
export const dictionary: any = {
    es: {
        "auth/wrong-password": "La contraseña ingresada es incorrecta",
        "auth/user-not-found": "No hay ninguna cuenta asociada a este correo",
        "auth/network-request-failed": "Verifique su conexión a internet",
        'invalid-auth': "Credenciales incorrectas"

/*         PARAMS: {
            EVENT: 'studying',
            LOCATION: 'library',
            TIME:'saturday'
        } */
    },
/*     zh: {
        PHRASE: '我{{ time }}在{{ location }}{{ event }}',
        PARAMS: {
            EVENT: '学习',
            LOCATION: '图书馆',
            TIME: '礼拜六'
        }
    } */
};