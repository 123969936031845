import { Component, OnInit, } from '@angular/core';
import { AssistService } from '../../../services/assist.service';
import { CustomerService } from '../../../services/customer.service';
import { TripService } from '../../../services/trip.service';
import { DetailAssistComponent } from './detail-assist/detail-assist.component';
import { environment } from '../../../../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material';

/* import "rxjs/Rx";
import { Observable }        from 'rxjs/Rx';
import { Subject }           from 'rxjs/Subject';

import 'rxjs/add/operator/request'; */

@Component({
  selector: 'app-assist',
  templateUrl: './assist.component.html',
  styleUrls: ['./assist.component.css']
})
export class AssistComponent implements OnInit {

  assistList: any;
  customerList: any;
  tripList: any;
  

  constructor(
    public assistService:AssistService,
    public tripService:TripService,
    public customerService: CustomerService,
    public dialog: MatDialog
  ) {

     assistService.getList((list) => {
      this.assistList = list;      
     });

     tripService.getList((list) => {
      this.tripList = list;      
     });

     customerService.getList((list) => {
      this.customerList = list;
     });
    
  }

  ngOnInit() {

  }

  getTrip(id: string) {
    return this.tripService.getById(id);
  }    
  
  openDetail(id: string, assistItem: any) {
    let dialogRef = this.dialog.open(DetailAssistComponent, {
      width: '90%',      
      height: '90%',      
      data: {
        user: this.customerList[id],
        trip: this.tripList[assistItem.trip],
        assistItem: assistItem,
        assistType:this.assistList['types'][assistItem.type],
        accept: (() => {
          
        })
      }
    });
/* 
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      
    }); */
  }  
  
  getUser(id: string) {
    if(this.customerList){
      return this.customerList[id];
    }
  }
}
