
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { log } from 'util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { take, map } from 'rxjs/operators';

@Injectable()
export class BenefitService {

  ref: any;
  actions: any;
  constructor(
    public db: AngularFireDatabase,
    public http: HttpClient
  ) { }

  addPartner(data, success, err) {
    this.http.post(environment.cloudBaseUrl + '/addPartner', data, { observe: 'response' }).subscribe(res => {
      console.log(res);
      if (res.status === 200) {
        success("success");
      }
      else {
        err(res);
      }
    }, error => {
      console.log(error);
      err(error.error);
    });
  }

  getClientTokens(uid, success, error) {
    this.db.object('users/' + uid).snapshotChanges().pipe(take(1)).pipe(
      map(c =>
        ({ key: c.payload.key, ...c.payload.val() }))
    ).subscribe(data => {
      if (data['tickets']) {
        success(data['tickets']);
      }
      else {
        success(0);
      }
    }, err => {
      error(err);
    });
  }

  updateClientTokens(uid, tokens) {
    this.db.object('users/' + uid).update({ tickets: tokens });
  }

  updatePartner(data, success, err) {
    this.http.post(environment.cloudBaseUrl + '/updatePartner', data, { observe: 'response' }).subscribe(res => {
      success(res);
    }, error => {
      err(error);
    });
  }

  getListByPartner(partner, callback) {
    this.db.object('benefits/business/' + partner).snapshotChanges().pipe(take(1)).pipe(
      map(c =>
        ({ key: c.payload.key, ...c.payload.val() }))
    ).subscribe(snapshot => {
      this.ref = snapshot;
      callback(snapshot);
    });
  }

  addBusiness(data, callback, error) {
    this.db.list('benefits/business/').push(data).then(snapshot => {
      callback(snapshot);
    }, error);
  }

  addCategory(data, callback) {
    this.db.list('benefits/categories/').push(data).then(snapshot => {
      callback(snapshot);
    });
  }

  updateCategory(idBenefit, data, callback) {
    this.db.object('benefits/categories/' + idBenefit).update(data).then(snapshot => {
      callback(snapshot);
    });
  }

  updateBusiness(business, data, callback) {
    this.db.object('benefits/business/' + business).update(data).then(snapshot => {
      callback(snapshot);
    });
  }

  getCategories(callback) {
    this.db.list('benefits/categories').snapshotChanges().pipe(take(1)).pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(data => {
      callback(data);
    });
  }

  getBusiness(callback) {
    this.db.list('benefits/business').snapshotChanges().pipe(take(1)).pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(data => {
      callback(data);
    });
  }

  getAccounts(callback) {
    this.db.list('benefits/accounts').snapshotChanges().pipe(take(1)).pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(data => {
      callback(data);
    });
  }

  removePromo(partner, code, success, err) {
    this.db.object('/benefits/business/' + partner + '/apply/' + code).remove().then((data) => {
      success(data);
    }, (error) => {
      err(error);
    });
  }
}
