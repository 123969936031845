import { Component, OnInit } from '@angular/core';
import { BusinessService } from 'app/services/business/business.service';
import { AuthService } from 'app/services/auth.service';
import { MatDialog } from '@angular/material';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { ChangeSelectedBusiness } from 'app/shared/business-account.actions';

@Component({
  selector: 'app-selected-business',
  templateUrl: './selected-business.component.html',
  styleUrls: ['./selected-business.component.css']
})
export class SelectedBusinessComponent implements OnInit {

  loading;
  businessList;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private businessService: BusinessService,
    public router: Router,
    public location: Location,
    private store: Store
    ) { }

  async loadData() {
    this.loading = true;
    const email = (await this.authService.getAuth()).email;
    this.businessList = await this.businessService.getBusinessByUser(email).then();
    console.log(this.businessList);
    this.loading = false;

  }

  select(business){
    this.loading = true;
    console.log(business);
    this.store.dispatch(new ChangeSelectedBusiness(business.key));
/*     this.authService.setSelectedPartner(business.key);
 */

 /*     location.reload();
 */  
    this.dialog.closeAll();
  }

  logout() {
    this.dialog.closeAll();
    this.dialog.open(DialogOkCancelComponent, {
      data: {
        title: "Cerrar Sesión",
        body: "Esta seguro que desea cerrar sesión?",
        ok: "Aceptar",
        action: (() => {
          this.authService.logout(() => {
            this.router.navigateByUrl("/login");
          }, (err) => {
            console.log(err);
          });
        }),
        cancel: "Cancelar",
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }

}
