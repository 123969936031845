import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GeneralService } from './general.service';
import * as moment from 'moment';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {

  constructor(
    public afs: AngularFirestore,
    public generalService: GeneralService
  ) { }

  async saveRol(businessID, rolID, rolData) {
    const timestamp = rolID || moment(await this.generalService.serverTimestamp()).format('x').toString();
    return this.afs.collection('business').doc(businessID).set(
      {
        'rol': {
          [timestamp]: rolData
        }
      }
      , { merge: true }).then();
  }

  async removeRol(businessID, rolID) {
    console.log(rolID);

    this.afs.collection("business")
      .doc(businessID)
      .update({ ["rol." + rolID]: firebase.firestore.FieldValue.delete() });

    /*     const timestamp = moment(await this.generalService.serverTimestamp()).format('x').toString();
        return this.afs.collection('business').doc(businessID).set(
          {
            'rol': {
              [timestamp]: rolData
            }
          }
          , { merge: true }).then(); */
  }
}
