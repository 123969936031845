import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import * as Rx from "rxjs/Rx";

@Injectable()
export class ArticleService {

  articleRef: any;

  constructor(public db: AngularFireDatabase) {
  }

  getArticle(cat){
    this.articleRef = this.db.list('/article',
      ref => ref.orderByChild('category').equalTo(cat));
    console.log(this.articleRef);
    return this.articleRef
  }

  getArticles(){

    this.articleRef = this.db.list('/article');
    return this.articleRef
  }

}
