import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

/* import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader'; */

import {
  MatToolbarModule, MatCardModule, MatListModule, MatChipsModule, MatIconModule, MatRadioModule, MatSlideToggleModule,
  MatButtonToggleModule, MatButtonModule, MatInputModule, MatSidenavModule, MatTableModule, MatSliderModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatMenuModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule, MatSelectModule, MatFormFieldModule, MatCheckboxModule, MatSortModule, MatPaginatorModule
} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar'

import { DragDropModule } from '@angular/cdk/drag-drop';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule, AngularFirestore, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { ArticleService } from './services/article.service';
import { CustomerService } from './services/customer.service';
import { AssistService } from './services/assist.service';
import { TripService } from './services/trip.service';
import { ConfiabilityService } from './services/confiability.service';
import { AuthService } from './services/auth.service';
import { NotificationsService } from './services/notifications.service';
import { PaymentsService } from './services/payments.service';

import { AuthGuard } from './guards/auth/auth.guard';
import { routes } from './app.route';

import { AppComponent } from './app.component';
import { AboutUsComponent } from './components/public/about-us/about-us.component';
import { ArticleComponent } from './components/public/article/article.component';
import { BlogComponent } from './components/public/blog/blog.component';
import { CarouselComponent } from './components/public/carousel/carousel.component';
import { CarpoolingComponent } from './components/public/information/carpooling.component';
import { ContactUsComponent } from './components/public/contact-us/contact-us.component';
import { CookiesComponent } from './components/public/information/cookies.component';
import { CGUComponent } from './components/public/information/cgu.component';
import { FAQComponent } from './components/public/faq/faq.component';
import { FooterComponent } from './components/public/footer/footer.component';
import { HeaderComponent } from './components/public/header/header.component';
import { HomePageComponent } from './components/public/home-page/home-page.component';
import { PrivacyComponent } from './components/public/information/privacy.component';
import { GeneralComponent } from './components/public/general/general.component';

import { OrderModule } from 'ngx-order-pipe';
import { ListByPipe } from './pipes/list-by.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { NgxCarouselModule } from 'ngx-carousel';
import 'hammerjs';

import { FlexLayoutModule } from "@angular/flex-layout";
import { MenuComponent } from './components/dashboard/menu/menu.component';
import { LoginComponent } from './components/dashboard/login/login.component';
import { ToolbarComponent } from './components/dashboard/toolbar/toolbar.component';
import { CustomerComponent } from './components/dashboard/customer/customer.component';
import { PrincipalComponent } from './components/dashboard/principal/principal.component';
import { AssistComponent } from './components/dashboard/assist/assist.component';
import { ArticlesComponent } from './components/dashboard/articles/articles.component';
import { ConfiabilityComponent } from './components/dashboard/confiability/confiability.component';
import { DetailAssistComponent } from './components/dashboard/assist/detail-assist/detail-assist.component';
import { DetailConfiabilityComponent } from './components/dashboard/confiability/detail-confiability/detail-confiability.component';
import { NotFoundComponent } from './components/public/not-found/not-found.component';
import { DialogOkCancelComponent } from './general/dialog-ok-cancel/dialog-ok-cancel.component';
import { RecoverPasswordComponent } from './components/public/recover-password/recover-password.component';
import { KeyPipe } from './pipes/key.pipe';
import { EmploymentComponent } from './components/public/employment/employment.component';
import { PaymentsComponent } from './components/dashboard/payments/payments.component';
import { FilterByPipe, PluckPipe, SumPipe, RoundPipe } from 'ngx-pipes';
import { BenefitComponent } from './components/partner/benefit/benefit.component';
import { PartnerComponent } from './components/partner/partner/partner.component';
import { BenefitService } from 'app/services/benefit.service';
import { EventsService } from 'app/services/events/events.service';
import { HistoryService } from 'app/services/history.service';
import { ProductService } from 'app/services/product.service';
import { HistoryComponent } from './components/partner/history/history.component';
import { ProductComponent } from './components/partner/product/product.component';
import { EventsComponent } from './components/partner/events/events.component';
import { EventsOverviewComponent } from './components/partner/events/events-overview/events-overview.component';
import { EventDetailComponent } from './components/partner/events/event-detail/event-detail.component';
import { CreateComponent } from './components/partner/product/create/create.component';
import { PartnersComponent } from './components/dashboard/partners/partners.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthPartnerComponent } from './components/partner/auth-partner/auth-partner.component';
import { StorageService } from './services/storage.service';
import { NewBussinessComponent } from './components/dashboard/partners/new-bussiness/new-bussiness.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { DialogComponent } from './dialog/dialog.component';
import { DescountOfferPipe } from './pipes/descount-offer.pipe';
import { FilterBankPipe } from './pipes/filter-bank.pipe';
import { MultiplePipe } from './pipes/multiple.pipe';
import { BusinessPaymentsComponent } from './components/dashboard/business-payments/business-payments.component';
import { PartnerAccountPipe } from './pipes/partner-account.pipe';
import { StatisticsComponent } from './components/dashboard/statistics/statistics.component';
import { VehicleService } from './services/vehicle.service';
import { AlertService } from './services/alert.service';
import { MessagesService } from './services/messages.service';
import { OpinionService } from './services/opinion.service';
import { BillsComponent } from './components/dashboard/bills/bills.component';
import { LegalComponent } from './components/dashboard/legal/legal.component';

import { MomentModule } from 'ngx-moment';
import { GeneralService } from './services/general.service';
import { NodeService } from './services/node.service';
import { BillService } from './services/bill.service';
import { CreateBillComponent } from './components/dashboard/bills/create-bill/create-bill.component';
import { BillDetailComponent} from './components/dashboard/bills/bill-detail/bill-detail.component';
import { TransactionsComponent } from './components/dashboard/transactions/transactions.component';
import { RegisterComponent } from './components/partner/register/register.component';
import { NewOfferComponent } from './components/partner/offers/new-offer/new-offer.component';
import { OffersOverviewComponent } from './components/partner/offers/offers-overview/offers-overview.component';
import { OfferDetailComponent } from './components/partner/offers/offer-detail/offer-detail.component';
import { ExchangeOfferComponent } from './components/partner/offers/exchange-offer/exchange-offer.component';
import { SafePipe } from './pipes/safe.pipe';
import { Ng2ImgToolsModule } from 'ng2-img-tools';
import { BusinessDetailComponent } from './components/dashboard/partners/business-detail/business-detail.component';
import { InviteUsersComponent } from './components/general/invite-users/invite-users.component';
import { SelectedBusinessComponent } from './components/partner/partner/selected-business/selected-business.component';
import { CompleteUserDataComponent } from './components/general/complete-user-data/complete-user-data.component';
import { UserTypesComponent } from './components/general/invite-users/user-types/user-types.component';
import { NewTypeComponent } from './components/general/invite-users/user-types/new-type/new-type.component';
import { DetailTypeComponent } from './components/general/invite-users/user-types/detail-type/detail-type.component';


import { EventSuccessComponent } from './components/partner/events/successful-message/successful-message.component';
import { NewEventComponent } from './components/partner/events/new-event/new-event.component';
import { EventChangesModalComponent } from './components/partner/events/event-changes-modal/event-changes-modal.component';
import { ImageModalComponent } from './general/image-modal/image-modal.component';
import { AccountTypePipe } from './pipes/account-type.pipe';
import { InfoDialogComponent } from './general/info-dialog/info-dialog.component';
import { BatchDetailComponent } from './components/dashboard/payments/batch-detail/batch-detail.component';
import { AngularFireFunctions, AngularFireFunctionsModule } from '@angular/fire/functions';


import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { BusinessAccountState } from './shared/business-account.state';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { MetricTypePipe } from './pipes/metric-type.pipe';
import { DetailMetricComponent } from './components/dashboard/confiability/detail-metric/detail-metric.component';
import { GenrePipe } from './pipes/genre.pipe';
import { UsersComponent } from './components/dashboard/users/users.component';
import { EditUserComponent } from './components/dashboard/users/edit-user/edit-user.component';
import { BalanceComponent } from './components/partner/balance/balance.component';
import { BalancePaymentComponent } from './components/partner/balance/balance-payment/balance-payment.component';
import { BalanceOverviewComponent } from './components/partner/balance/balance-overview/balance-overview.component';
import { ClientTypePipe } from './pipes/client-type.pipe';
import { NewLegalVersionComponent } from './components/dashboard/legal/new-legal-version/new-legal-version.component';
import { NewTermsSectionComponent } from './components/dashboard/legal/new-legal-version/new-terms-section/new-terms-section.component';
import { SectionDetailComponent } from './components/dashboard/legal/new-legal-version/section-detail/section-detail.component';
import { EditSectionComponent } from './components/dashboard/legal/new-legal-version/section-detail/edit-section-content/edit-section-content.component';
import { LegalSubsectionComponent } from './components/dashboard/legal/new-legal-version/section-detail/legal-subsection/legal-subsection.component';
import { PublishNewLegalVersionComponent } from './components/dashboard/legal/new-legal-version/publish-new-legal-version/publish-new-legal-version.component';
import { LegalVersionDetailComponent } from './components/dashboard/legal/legal-version-detail/legal-version-detail.component';
import { PublicLegalComponent } from './components/public/public-legal/public-legal.component';

if(environment.production == true){
  enableProdMode();
  console.log = function () {
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    ArticleComponent,
    BlogComponent,
    CarouselComponent,
    CarpoolingComponent,
    ContactUsComponent,
    CookiesComponent,
    CGUComponent,
    FAQComponent,
    FooterComponent,
    HeaderComponent,
    HomePageComponent,
    PrivacyComponent,
    ListByPipe,
    GeneralComponent,
    MenuComponent,
    LoginComponent,
    PaymentsComponent,
    ToolbarComponent,
    CustomerComponent,
    PrincipalComponent,
    AssistComponent, ArticlesComponent, ConfiabilityComponent,
    DetailAssistComponent,
    DetailConfiabilityComponent,
    DetailMetricComponent,
    NotFoundComponent,
    DialogOkCancelComponent,
    ImageModalComponent,
    RecoverPasswordComponent,
    KeyPipe,
    EmploymentComponent,
    PaymentsComponent,
    BenefitComponent,
    PartnerComponent,
    HistoryComponent,
    ProductComponent,
    CreateComponent,
    PartnersComponent,
    AuthPartnerComponent,
    NewBussinessComponent,
    SearchFilterPipe,
    DialogComponent,
    DescountOfferPipe,
    FilterBankPipe,
    MultiplePipe,
    BusinessPaymentsComponent,
    PartnerAccountPipe,
    StatisticsComponent,
    BillsComponent,
    LegalComponent,
    PublicLegalComponent,
    CreateBillComponent,
    BillDetailComponent,
    TransactionsComponent,
    RegisterComponent,
    NewOfferComponent,
    OffersOverviewComponent,
    OfferDetailComponent,
    EventsComponent,
    EventsOverviewComponent,
    EventDetailComponent,
    EventSuccessComponent,
    NewEventComponent,
    EventChangesModalComponent,
    ExchangeOfferComponent,
    SafePipe,
    BusinessDetailComponent,
    InviteUsersComponent,
    SelectedBusinessComponent,
    CompleteUserDataComponent,
    ImageModalComponent,
    UserTypesComponent,
    NewTypeComponent,
    DetailTypeComponent,
    AccountTypePipe,
    InfoDialogComponent,
    BatchDetailComponent,
    ObjectToArrayPipe,
    MetricTypePipe,
    GenrePipe,
    UsersComponent,
    EditUserComponent,
    BalanceComponent,
    BalancePaymentComponent,
    BalanceOverviewComponent,
    ClientTypePipe,
    NewLegalVersionComponent,
    NewTermsSectionComponent,
    SectionDetailComponent,
    EditSectionComponent,
    LegalSubsectionComponent,
    PublishNewLegalVersionComponent,
    LegalVersionDetailComponent,
  ],
  imports: [
    BrowserModule,
    NgxCarouselModule,
    NgxImageZoomModule.forRoot(),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDr4GRqGYyCPdrMlFBdpfeaf06Dk9Kkzwc'
    // }),
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      /*       loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            } */
    }),
    BrowserAnimationsModule,
    Ng2ImgToolsModule,
    InfiniteScrollModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // imports firebase/database, only needed for database features
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    DragDropModule,
    MatToolbarModule,
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSidenavModule,
    OrderModule,
    NgPipesModule,
    MatListModule,
    CommonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatCheckboxModule,
    MatCardModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTableModule, MatSelectModule, MatFormFieldModule, MatRadioModule,
    MatChipsModule,
    HttpModule,
    HttpClientModule, MatAutocompleteModule,
    MomentModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    NgxsModule.forRoot([
      BusinessAccountState
    ], {developmentMode: !(environment.production)}),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
  ],
  providers: [
    ArticleService,
    CustomerService,
    AssistService,
    VehicleService,
    AlertService,
    GeneralService,
    BillService,
    MessagesService,
    OpinionService,
    TripService,
    AngularFirestore,
    AngularFireFunctions,
    ConfiabilityService,
    AuthService,
    AuthGuard,
    NotificationsService,
    NodeService,
    PaymentsService,
    BenefitService, EventsService, HistoryService, ProductService, StorageService,
    FilterByPipe, PluckPipe, SumPipe, RoundPipe,
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  entryComponents: [
    DetailAssistComponent,
    CreateBillComponent,
    EditSectionComponent,
    NewTermsSectionComponent,
    LegalSubsectionComponent,
    BillDetailComponent,
    DetailConfiabilityComponent,
    DetailMetricComponent,
    DialogOkCancelComponent,
    EditUserComponent,
    ImageModalComponent,
    CreateComponent,
    NewBussinessComponent,
    SelectedBusinessComponent,
    EventSuccessComponent,
    NewEventComponent,
    EventChangesModalComponent,
    InfoDialogComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
