import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { dictionary } from './general/dictionary';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(private router: Router, private translate: TranslateService) {
        this.translate.setDefaultLang('es');
        translate.use('es');

        translate.setTranslation('es', dictionary.es);
    }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }
}
