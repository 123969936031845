import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BusinessService } from 'app/services/business/business.service';
import { DialogOkCancelComponent } from 'app/general/dialog-ok-cancel/dialog-ok-cancel.component';
import { MatDialog } from '@angular/material';
import { PartnerService } from 'app/services/partner/partner.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerGuard implements CanActivate {

  constructor(private router: Router,
    private authService: AuthService,
    private partnerService: PartnerService,
    public dialog: MatDialog
  ) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise(async Resolve => {
      const auth = (await this.authService.getAuth());
      if (auth && auth.uid) {
        const partnerData: any = await this.partnerService.getPartnerData(auth.email);
        if (!partnerData.displayName || !partnerData.phone) {
          this.router.navigate(['/complete']);
          return Resolve(false);
        } else if (partnerData.businessList && partnerData.businessList.length > 0) {
          console.log(route.data.permission);
          
          return Resolve(true);
        }else{
          this.dialog.open(DialogOkCancelComponent, {
            data: {
              title: "Atención",
              body: "Para ingresar a este portal debes estar asignado a una empresa.",
              ok: "Entendido",
              action: (() => {
              })
            }
          });    
        }
      }
      
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return Resolve(false);
    });
  }

}
