import { Component, OnInit } from '@angular/core';
import { LegalService } from 'app/services/legal.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-public-legal',
  templateUrl: './public-legal.component.html',
  styleUrls: ['./public-legal.component.css']
})
export class PublicLegalComponent implements OnInit {

  type: string;
  loading: boolean = true;
  version: any = {};
  sections = [];
  title: string;

  constructor(
    private legalService: LegalService,
    private route: ActivatedRoute,
  ) { 
    this.type = this.route.snapshot.paramMap.get('type');
  }

  ngOnInit() {
    this.loadVersion();
  }

  async loadVersion(){
    this.loading = true;
    this.version = await this.legalService.getlastVersion();
    
    if(this.type === 'terms-conditions'){
      this.title = "Términos y Condiciones de Uso";
      this.sections = this.version.terms;
    } else {
      this.title = "Políticas de Privacidad";
      this.sections = this.version.policies;
    }

    this.loading = false;
  }

}
