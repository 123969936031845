import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent implements OnInit {

  data: any;
  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>,

  @Inject(MAT_DIALOG_DATA) public params: any
  
  ) {
    this.data = params;
  }
  
  ngOnInit() {}

  close(){
    this.dialogRef.close();
  }

}
